import axios from "axios";
import * as headers from "../auth-header";

const apiUrl = process.env.REACT_APP_API_URL;

export function createInstituteAdmin(data) {
  return axios.post(apiUrl + "auth/register", data, {
    headers: headers.authHeader(),
  });
}

// READ
export function getAllInstitutesAdmin(page, limit) {
  return axios.get(apiUrl + `auth/institute-users/${page}/${limit}`, {
    headers: headers.authHeader(),
  });
}

export function filterInstitutesAdmin(page, limit, key) {
  return axios.get(
    apiUrl + `auth/institute-users/search/${key}/${page}/${limit}`
  );
}

export function getInstituteAdminById(id) {
  return axios.get(apiUrl + `auth/user-details/${id}`, {
    headers: headers.authHeader(),
  });
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findInstitutesAdmin(queryParams) {
  const result = axios.post(`auth/institute-users/find`, { queryParams });
  console.log("Result", result);
  return result;
}

// UPDATE => PUT: update the InstituteAdmin on the server
export function updateInstituteAdmin(data) {
  return axios.put(apiUrl + `auth/update-info`, data, {
    headers: headers.authHeader(),
  });
}

// UPDATE Status
export function updateStatusForInstitutesAdmin(ids, status) {
  return axios.post(`auth/institute-users/updateStatusForInstituteAdmins`, {
    ids,
    status,
  });
}

// DELETE => delete the InstituteAdmin from the server
export function deleteInstituteAdmin(id) {
  return axios.delete(apiUrl + `auth/user/${id}`, {
    headers: headers.authHeader(),
  });
}
