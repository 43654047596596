import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setError } from "./errorSlice";
import AuthService from "../services/auth.services";

const user = JSON.parse(localStorage.getItem("user"));

export const login = createAsyncThunk("auth/login", async (body, thunkAPI) => {
  try {
    const response = await AuthService.login(body);
    // console.log("My Response: ", response.data);
    if (response.data.token) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return thunkAPI.fulfillWithValue({
      success: true,
      status: response.status,
      data: response.data,
    });
  } catch (error) {
    if (error.response.status === 403) {
      thunkAPI.dispatch(
        setError({
          message: "Email or password is incorrect, please try again.",
        })
      );
    } else
      thunkAPI.dispatch(setError({ message: error.response.data.message }));
    return thunkAPI.rejectWithValue({
      success: false,
      status: error.response.status,
      message: error.response.data.message,
    });
  }
});

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.data;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

const { reducer } = authSlice;
export default reducer;
