import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../slices/authSlice";
import { clearError } from "../../slices/errorSlice";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import ButtonLoader from "../../components/Loaders/ButtonLoader";

export default function Login() {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { error } = useSelector((state) => state.error);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    setLoading(true);
    dispatch(login({ ...values, isQR: false }))
      .then(() => {
        setLoading(false);
        navigate("/");
      })
      .catch((error) => {
        setLoading(false);
        console.log("🚀 ~ file: Login.js:39 ~ handleSubmit ~ error", error);
      });
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string().email("emailNotValid").required("emailRequired"),
    password: Yup.string()
      .min(3, t("passwordMin"))
      .required("passwordRequired"),
  });

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  const handleCloseMessage = () => {
    dispatch(clearError());
  };

  return (
    <div className="h-screen bg-gradient-to-tr from-primary to-gray-shade">
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-28 w-auto" src={logo} alt="Whos champion" />
          <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-white drop-shadow-md">
            {t("signinToAccount")}
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white/25 py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={loginSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ touched, errors }) => (
                <Form className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("email")}
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 ltr:left-0 rtl:right-0 flex items-center ltr:pl-3 rtl:pr-3">
                        <i className="fi fi-rr-envelope h-5 w-5 text-gray-400"></i>
                      </div>
                      <Field
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className={`block w-full rounded-md border border-transparent placeholder-gray-300 ltr:pl-10 rtl:pr-10 shadow-sm sm:text-sm ${
                          touched.email && errors.email
                            ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                            : "border border-transparent focus:border-secondary focus:ring-secondary"
                        }`}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("password")}
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 ltr:left-0 rtl:right-0 flex items-center ltr:pl-3 rtl:pr-3">
                        <i className="fi fi-rr-lock h-5 w-5 text-gray-400"></i>
                      </div>
                      <Field
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className={`block w-full rounded-md border border-transparent placeholder-gray-300 ltr:pl-10 rtl:pr-10 shadow-sm sm:text-sm ${
                          touched.email && errors.email
                            ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                            : "border border-transparent focus:border-secondary focus:ring-secondary"
                        }`}
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <Link
                        to={"/request-reset-password"}
                        className="font-medium hover:text-secondary text-primary"
                      >
                        {t("forgotPassword")}
                      </Link>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-bold text-white shadow-sm hover:bg-secondary"
                    >
                      {loading ? (
                        <>
                          <ButtonLoader /> {t("signin")}
                        </>
                      ) : (
                        t("signin")
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            {error && (
              <div className="mt-6">
                <ErrorAlert
                  message={error.message}
                  close={handleCloseMessage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
