import axios from "axios";
import * as headers from "../auth-header";

const apiUrl = process.env.REACT_APP_API_URL;

export function createOffer(offer) {
  return axios.post(apiUrl + "subscription/offer", offer, {
    headers: headers.authHeader(),
  });
}

export function getAllOffers(page, limit) {
  return axios.get(apiUrl + `subscription/offers/${page}/${limit}`, {
    headers: headers.authHeader(),
  });
}

export function getOfferById(offerId) {
  return axios.get(apiUrl + "subscription/offer/" + offerId, {
    headers: headers.authHeader(),
  });
}

export function updateOffer(id, offer) {
  return axios.put(apiUrl + "subscription/offer/" + id, offer, {
    headers: headers.authHeader(),
  });
}

export function deleteOffer(offerId) {
  return axios.delete(apiUrl + "subscription/offer/" + offerId, {
    headers: headers.authHeader(),
  });
}
