import axios from "axios";
import * as headers from "./auth-header";

const apiUrl = process.env.REACT_APP_API_URL;

export function getAllAds() {
  return axios.get(apiUrl + "ads/all/1/10", {
    headers: headers.authHeader(),
  });
}

export function updateAd(ad) {
  console.log("createDirectAd: ", ad);
  let formData = new FormData();
  formData.append("arabicTitle", ad.arabicTitle);
  formData.append("englishTitle", ad.englishTitle);
  formData.append("arabicDescription", ad.arabicDescription);
  formData.append("englishDescription", ad.englishDescription);
  formData.append("url", ad.url);
  formData.append("period", ad.period);
  formData.append("image", ad.image);
  return axios.put(apiUrl + "ads/" + ad.id, formData, {
    headers: headers.authHeaderWithMultipart(),
  });
}
