import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import PageLoader from "../Loaders/PageLoader";

const Institute = ({ institute }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-4">
        {institute ? (
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="-py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {t("englishName")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {institute?.englishName}
                </dd>
              </div>
              <div className="-py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {t("arabicName")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {institute?.arabicName}
                </dd>
              </div>
              <div className="-py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">{t("id")}</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {institute?.id}
                </dd>
              </div>
              <div className="-py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {t("phoneNumber")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {institute?.phoneNumber}
                </dd>
              </div>
              <div className="-py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {t("email")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {institute?.email}
                </dd>
              </div>
              <div className="-py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {t("address")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {institute?.address}
                </dd>
              </div>

              <div className="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {t("createdAt")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {format(new Date(institute?.createdAt), "MMMM d, y")}
                </dd>
              </div>
            </dl>
          </div>
        ) : (
          <PageLoader />
        )}
      </div>
    </>
  );
};

export default Institute;
