import axios from "axios";
import * as headers from "./auth-header";

const apiUrl = process.env.REACT_APP_API_URL;

export function getSubscriptionsYearly(year) {
  return axios.get(apiUrl + "reports/subscriptions/yearly/" + year, {
    headers: headers.authHeader(),
  });
}

export function getSubscriptionsMonthly(year, month) {
  return axios.get(
    apiUrl + "reports/subscriptions/monthly/" + year + "/" + month,
    {
      headers: headers.authHeader(),
    }
  );
}

export function getSubscriptionsListByDay(year, month, day, page, limit) {
  return axios.get(
    apiUrl +
      "reports/subscriptions/list/" +
      page +
      "/" +
      limit +
      "/daily/" +
      year +
      "/" +
      month +
      "/" +
      day,
    {
      headers: headers.authHeader(),
    }
  );
}

export function getSubscriptionsListByMonth(year, month, page, limit) {
  return axios.get(
    apiUrl +
      "reports/subscriptions/list/" +
      page +
      "/" +
      limit +
      "/monthly/" +
      year +
      "/" +
      month,
    {
      headers: headers.authHeader(),
    }
  );
}

export function getFamiliesYearly(year) {
  return axios.get(apiUrl + "reports/users/families/yearly/" + year, {
    headers: headers.authHeader(),
  });
}

export function getFamiliesMonthly(year, month) {
  return axios.get(
    apiUrl + "reports/users/families/monthly/" + year + "/" + month,
    {
      headers: headers.authHeader(),
    }
  );
}

export function getFamiliesListByDay(year, month, day, page, limit) {
  return axios.get(
    apiUrl +
      "reports/users/families/list/" +
      page +
      "/" +
      limit +
      "/daily/" +
      year +
      "/" +
      month +
      "/" +
      day,
    {
      headers: headers.authHeader(),
    }
  );
}

export function getAllFamiliesByDay(year, month, day) {
  return axios.get(
    apiUrl +
      "reports/users/families/list/1/1000/daily/" +
      year +
      "/" +
      month +
      "/" +
      day,
    {
      headers: headers.authHeader(),
    }
  );
}

export function getFamiliesListByMonth(year, month, page, limit) {
  return axios.get(
    apiUrl +
      "reports/users/families/list/" +
      page +
      "/" +
      limit +
      "/monthly/" +
      year +
      "/" +
      month,
    {
      headers: headers.authHeader(),
    }
  );
}

export function getAllFamiliesByMonth(year, month) {
  return axios.get(
    apiUrl + "reports/users/families/list/1/1000/monthly/" + year + "/" + month,
    {
      headers: headers.authHeader(),
    }
  );
}

export function getInstitutesYearly(year) {
  return axios.get(apiUrl + "reports/users/institutes/yearly/" + year, {
    headers: headers.authHeader(),
  });
}

export function getInstitutesMonthly(year, month) {
  return axios.get(
    apiUrl + "reports/users/institutes/monthly/" + year + "/" + month,
    {
      headers: headers.authHeader(),
    }
  );
}

export function getInstitutesListByDay(year, month, day, page, limit) {
  return axios.get(
    apiUrl +
      "reports/users/institutes/list/" +
      page +
      "/" +
      limit +
      "/daily/" +
      year +
      "/" +
      month +
      "/" +
      day,
    {
      headers: headers.authHeader(),
    }
  );
}

export function getInstitutesListByMonth(year, month, page, limit) {
  return axios.get(
    apiUrl +
      "reports/users/institutes/list/" +
      page +
      "/" +
      limit +
      "/monthly/" +
      year +
      "/" +
      month,
    {
      headers: headers.authHeader(),
    }
  );
}

export function getFamiliesPricings() {
  return axios.get(apiUrl + "reports/price/subscriptions/families", {
    headers: headers.authHeader(),
  });
}

export function getInstitutesPricings() {
  return axios.get(apiUrl + "reports/price/subscriptions/institutes", {
    headers: headers.authHeader(),
  });
}

export function getActiveUsersYearly(year) {
  return axios.get(apiUrl + "reports/logs/yearly/" + year, {
    headers: headers.authHeader(),
  });
}

export function getActiveUsersMonthly(year, month) {
  return axios.get(apiUrl + "reports/logs/monthly/" + year + "/" + month, {
    headers: headers.authHeader(),
  });
}

export function getActiveUsersListByDay(year, month, day, page, limit) {
  return axios.get(
    apiUrl +
      "reports/logs/list/" +
      page +
      "/" +
      limit +
      "/daily/" +
      year +
      "/" +
      month +
      "/" +
      day,
    {
      headers: headers.authHeader(),
    }
  );
}

export function getActiveUsersListByMonth(year, month, page, limit) {
  return axios.get(
    apiUrl +
      "reports/logs/list/" +
      page +
      "/" +
      limit +
      "/monthly/" +
      year +
      "/" +
      month,
    {
      headers: headers.authHeader(),
    }
  );
}
