import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../../components/Layout/Container";
import Layout from "../../components/Layout/Layout";
import { Link } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/24/outline";
// import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Students from "../../components/InstituteDetails/Students";
import HomeWorks from "../../components/InstituteDetails/HomeWorks";

const ClassRoomsDetails = () => {
  const { t } = useTranslation();
  const { instituteId } = useParams();
  const { gradeId } = useParams();

  //   let dispatch = useDispatch();

  return (
    <Layout>
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to={"/champion/institutes"}
                className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500 hover:text-secondary"
              >
                {t("institutes")}
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to={`/champion/institutes/${instituteId}`}
                className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500 hover:text-secondary"
              >
                {t("instituteDetails")}
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm rtl:text-xs rtl:font-medium font-semibold text-gray-500">
                {t("grades")}
              </p>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to={`/champion/institutes/${instituteId}/grades/${gradeId}/classrooms`}
                className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500 hover:text-primary"
              >
                {t("classRooms")}
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("classRoomsDetails")}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <Container>
        <div className="flex">
          <h1 className="flex-1 text-2xl font-bold text-gray-900">
            {t("classRoomsDetails")}
          </h1>
        </div>
        <Students />
        <HomeWorks />
      </Container>
    </Layout>
  );
};

export default ClassRoomsDetails;
