import axios from "axios";
import * as headers from "../auth-header";

const apiUrl = process.env.REACT_APP_API_URL;

export function createGift(gift) {
  let formData = new FormData();
  formData.append("accountType", gift.accountType);
  formData.append("arabicDescription", gift.arabicDescription);
  formData.append("englishDescription", gift.englishDescription);
  formData.append("default", gift.default);
  formData.append("targetPoints", gift.targetPoints);
  formData.append("image", gift.image);
  return axios.post(apiUrl + "config/gift", formData, {
    headers: headers.authHeaderWithMultipart(),
  });
}

export function getAllGifts() {
  return axios.get(apiUrl + `config/gifts`, {
    headers: headers.authHeader(),
  });
}

export function getGiftById(giftId) {
  return axios.get(apiUrl + `config/gift/${giftId}`, {
    headers: headers.authHeader(),
  });
}

export function updateGift(gift) {
  let formData = new FormData();
  formData.append("id", gift.id);
  formData.append("accountType", gift.accountType);
  formData.append("arabicDescription", gift.arabicDescription);
  formData.append("englishDescription", gift.englishDescription);
  formData.append("default", gift.default);
  formData.append("targetPoints", gift.targetPoints);
  formData.append("image", gift.image);
  return axios.put(apiUrl + "config/gift", formData, {
    headers: headers.authHeaderWithMultipart(),
  });
}

export function deleteGift(giftId) {
  return axios.delete(apiUrl + `config/gift/${giftId}`, {
    headers: headers.authHeader(),
  });
}
