import { useLocation, Link } from "react-router-dom";
import {
  ReceiptPercentIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function UsersTabs() {
  const { t } = useTranslation();
  let { pathname } = useLocation();

  const tabs = [
    {
      name: t("subscriptions"),
      href: "/champion/subscriptions",
      icon: ReceiptPercentIcon,
    },
    {
      name: t("pricing"),
      href: "/champion/pricing",
      icon: CurrencyDollarIcon,
    },
    {
      name: t("offers"),
      href: "/champion/offers",
      icon: ReceiptPercentIcon,
    },
  ];

  return (
    <div className="border-b border-gray-200">
      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
        {tabs.map((tab) => (
          <Link
            key={tab.name}
            to={tab.href}
            className={classNames(
              pathname === tab.href
                ? "border-primary text-secondary"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
              "group inline-flex items-center py-4 px-12 border-b-2 font-medium text-sm"
            )}
            aria-current={pathname === tab.href ? "page" : undefined}
          >
            <tab.icon
              className={classNames(
                pathname === tab.href
                  ? "text-primary"
                  : "text-gray-400 group-hover:text-gray-500",
                "ltr:-ml-0.5 rtl:-mr-0.5 ltr:mr-2 rtl:ml-2 h-6 w-6"
              )}
              aria-hidden="true"
            />
            <span>{tab.name}</span>
          </Link>
        ))}
      </nav>
    </div>
  );
}
