import { useEffect, useState, useRef } from "react";
import * as PricingServices from "../../../services/subscriptions/pricing.services";
import { logout } from "../../../slices/authSlice";
import { useDispatch } from "react-redux";
import SubscriptionsTab from "../../../components/Tabs/SubscriptionsTab";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import PageLoader from "../../../components/Loaders/PageLoader";
import Layout from "../../../components/Layout/Layout";
import Container from "../../../components/Layout/Container";
import { Link } from "react-router-dom";
import {
  HomeIcon,
  PencilSquareIcon,
  TrashIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import DeleteModal from "../../../components/modals/DeleteModal";
import ErrorAlert from "../../../components/Alerts/ErrorAlert";
import SuccessAlert from "../../../components/Alerts/SuccessAlert";

const PricingList = () => {
  const [pricing, setPricing] = useState(null);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentIdValue, setCurrentIdValue] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();

  let dispatch = useDispatch();

  let cancelButtonRef = useRef(null);

  const columns = [
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 capitalize ltr:ml-4 rtl:mr-4">
          {t("title")}
        </div>
      ),
      selector: (row) =>
        window.localStorage.getItem("i18nextLng") === "en" ? (
          <div className="ltr:ml-4 rtl:mr-4">{row.englishTitle}</div>
        ) : (
          <div className="ltr:ml-4 rtl:mr-4">{row.arabicTitle}</div>
        ),
      cellExport: (row) => (row.title ? row.title : "not found"),
      sortable: true,
      sortField: "title",
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 capitalize">
          {t("description")}
        </div>
      ),
      selector: (row) =>
        row.englishDescription || row.arabicDescription ? (
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            {window.localStorage.getItem("i18nextLng") === "en"
              ? row.englishDescription
              : row.arabicDescription}
          </dd>
        ) : (
          "null"
        ),
      cellExport: (row) =>
        row.englishDescription || row.arabicDescription ? (
          <>
            {window.localStorage.getItem("i18nextLng") === "en"
              ? row.englishDescription
              : row.arabicDescription}
          </>
        ) : (
          "not found"
        ),
      sortable: true,
      sortField: "description",
      width: "200px",
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 capitalize">
          {t("displayType")}
        </div>
      ),
      selector: (row) => (
        <span className="inline-flex items-center text-xs font-medium ">
          {row.summarized === false ? (
            <dd className="px-2.5 py-0.5 rounded-full">
              <span>{t("detailed")}</span>
            </dd>
          ) : (
            <dd className="px-2.5 py-0.5 rounded-full">{t("summarized")}</dd>
          )}
        </span>
      ),
      cellExport: (row) => (row.summarized ? row.summarized : "not found"),
      sortable: true,
      sortField: "summarized",
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 capitalize">
          {t("period")}
        </div>
      ),
      selector: (row) => (row.period ? row.period : "not found"),
      cellExport: (row) => (row.period ? row.period : "not found"),
      sortable: true,
      sortField: "period",
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 capitalize">
          {t("targetUser")}
        </div>
      ),
      selector: (row) => (
        <span className="inline-flex items-center font-medium">
          {row.targetUser === "family" && (
            <dd className="bg-primary text-gray-800 px-3 py-1 rounded-md">
              {t("family")}
            </dd>
          )}
          {row.targetUser === "institute" && (
            <dd className="bg-secondary text-gray-800 px-3 py-1 rounded-md">
              {t("institute")}
            </dd>
          )}
        </span>
      ),
      cellExport: (row) => row.targetUser,
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900">{t("price")}</div>
      ),
      selector: (row) =>
        row.price ? (
          <div>
            {row.price}{" "}
            {window.localStorage.getItem("i18nextLng") === "en" ? "SAR" : "ر.س"}
          </div>
        ) : (
          "not found"
        ),
      cellExport: (row) => (row.price ? row.price : "not found"),
      sortable: true,
      sortField: "price",
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-600">
          {t("actions")}
        </div>
      ),
      selector: (row) => (
        <div className="flex space-x-6">
          <Link
            className="inline-flex items-center"
            to={`/champion/pricing/update/${row.id}`}
          >
            <PencilSquareIcon className="h-6 w-6 text-primary hover:text-secondary rtl:ml-5" />
          </Link>
          <button
            className="inline-flex items-center"
            onClick={() => showDeleteModal(row?.id)}
            ref={cancelButtonRef}
          >
            <TrashIcon className="h-6 w-6 text-red-700 hover:text-red-600" />
          </button>
        </div>
      ),
      cellExport: (row) => row.null,
    },
  ];

  const getPricing = async (page) => {
    setLoading(true);
    try {
      const response = await PricingServices.pricingList(page, limit);
      if (response.status === 200) {
        setPricing(response.data.prices.rows);
        setCount(response.data.prices.count);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const handlePageChange = (page) => {
    getPricing(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await PricingServices.pricingList(page, newPerPage);
      setPricing(response.data.prices.rows);
      setLimit(newPerPage);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  useEffect(() => {
    getPricing(1);
    // eslint-disable-next-line
  }, []);

  const showDeleteModal = (id) => {
    setDeleteModalOpen(true);
    setCurrentIdValue(id);
  };

  const handleDelete = () => {
    setIsSubmit(true);
    PricingServices.deletePrice(currentIdValue)
      .then((response) => {
        setIsSubmit(false);
        console.log(
          "🚀 ~ file: PricingList.js:235 ~ .then ~ response:",
          response
        );
        if (response.status === 200) {
          let newPricing = pricing.filter((price) => {
            return price.id !== currentIdValue;
          });
          setPricing(newPricing);
          setDeleteModalOpen(false);
          setSuccess(true);
        }
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: PricingList.js:246 ~ handleDelete ~ error:",
          error
        );
        if (error.response.status === 401) {
          dispatch(logout());
        } else {
          setError(true);
          setDeleteModalOpen(false);
        }
      });
  };

  const handleCloseMessage = () => {
    if (error) setError(false);
    if (success) setSuccess(false);
  };

  return (
    <Layout>
      {/* Breadcrumb */}
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 md:px-8 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500">
                {t("subscriptions")}
              </p>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("pricingList")}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <SubscriptionsTab />
      <Container>
        {/* Delete modal */}
        <DeleteModal
          deleteModalOpen={deleteModalOpen}
          setDeleteModalOpen={setDeleteModalOpen}
          cancelButtonRef={cancelButtonRef}
          handleDelete={handleDelete}
          setCurrentIdValue={setCurrentIdValue}
          isSubmit={isSubmit}
          name={t("deletePricing")}
        />

        {error && (
          <ErrorAlert
            message={t("deletePricingError")}
            close={handleCloseMessage}
          />
        )}
        {success && (
          <SuccessAlert
            message={t("deletePricingSuccess")}
            close={handleCloseMessage}
          />
        )}
        <div className="flex justify-between items-center">
          <h1 className="flex-1 text-2xl font-bold text-gray-900">
            {t("pricingList")}
          </h1>
          <Link
            to={"/champion/pricing/new"}
            className="flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary"
          >
            <PlusIcon className="h-5 w-5 text-white" />
            {t("newPricing")}
          </Link>
        </div>
        <div className="mt-6 shadow-lg">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="min-w-full py-2 align-middle md:px-6 lg:px-8">
              {pricing ? (
                <DataTable
                  columns={columns}
                  data={pricing}
                  progressPending={loading}
                  progressComponent={<PageLoader />}
                  pagination
                  paginationServer
                  paginationTotalRows={count}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  fixedHeader
                  direction={
                    window.localStorage.getItem("i18nextLng") === "en"
                      ? "ltr"
                      : "rtl"
                  }
                />
              ) : (
                <PageLoader />
              )}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default PricingList;
