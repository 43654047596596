import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import ProtectedRoute from "./utils/ProtectedRoute";
import { useTranslation } from "react-i18next";

import Login from "./pages/Auth/Login";
import RequestResetPassword from "./pages/Auth/RequestResetPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import Home from "./pages/Home";
import Institutes from "./pages/Users/Institutes/Institutes";
import Families from "./pages/Users/Families/Families";
import AddUser from "./pages/Users/Institutes/AddUser";
import EditUser from "./pages/Users/Institutes/EditUser";
import EditFamiliesUser from "./pages/Users/Families/EditFamiliesUser";
import UserDetails from "./pages/Users/Institutes/UserDetails";
import Notification from "./pages/Users/Notification";
import FamiliesUserDetails from "./pages/Users/Families/FamiliesUserDetails";
import InstitutesList from "./pages/Institutes/InstitutesList";
import InstituteDetails from "./pages/Institutes/InstituteDetails";
import ClassRooms from "./pages/Institutes/ClassRooms";
import ClassRoomsDetails from "./pages/Institutes/ClassRoomsDetails";
import StudentNotesDetails from "./pages/Institutes/StudentNotesDetails";
import FamiliesList from "./pages/Families/FamiliesList";
import FamiliesDetails from "./pages/Families/FamiliesDetails";
import GiftsList from "./pages/DefaultSettings/Gifts/GiftsList";
import TasksList from "./pages/DefaultSettings/Tasks/TasksList";
import AddGift from "./pages/DefaultSettings/Gifts/AddGift";
import EditGift from "./pages/DefaultSettings/Gifts/EditGift";
import AddTask from "./pages/DefaultSettings/Tasks/AddTask";
import EditTask from "./pages/DefaultSettings/Tasks/EditTask";
import Ads from "./pages/Ads/Ads";
import NotifyAllUsers from "./pages/Notifications/NotifyAllUsers";
import NotifyMultiUsers from "./pages/Notifications/NotifyMultiUsers";
import SubscriptionsList from "./pages/Subscriptions/Subscriptions/SubscriptionsList";
import SubscriptionDetails from "./pages/Subscriptions/Subscriptions/SubscriptionDetails";
import PricingList from "./pages/Subscriptions/Pricing/PricingList";
import AddPrice from "./pages/Subscriptions/Pricing/AddPrice";
import EditPricing from "./pages/Subscriptions/Pricing/EditPricing";
import OffersList from "./pages/Subscriptions/Offers/OffersList";
import AddOffers from "./pages/Subscriptions/Offers/AddOffers";
import EditOffers from "./pages/Subscriptions/Offers/EditOffers";
import Contacts from "./pages/Contact/Contacts";
import CopyRights from "./pages/CopyRights/CopyRights";
import Subscriptions from "./pages/Reports/Subscriptions";
import Pricing from "./pages/Reports/Pricing";
import Family from "./pages/Reports/Families";
import Institute from "./pages/Reports/Institutes";
import ActiveUsers from "./pages/Reports/ActiveUsers";

export default function App() {
  const { i18n } = useTranslation();

  //* To set the direction based on the selected language
  useEffect(() => {
    document.dir = i18n.dir();
  }, [i18n, i18n.language]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Auth Route */}
          <Route path="/login" element={<Login />} />
          <Route
            path="/request-reset-password"
            element={<RequestResetPassword />}
          />
          <Route
            path="/reset-password/:email/:token"
            element={<ResetPassword />}
          />

          {/* Dasboard */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />

          {/* User - Institutes */}
          <Route
            path="/champion/users/institutes"
            element={
              <ProtectedRoute>
                <Institutes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/users/institutes/new"
            element={
              <ProtectedRoute>
                <AddUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/users/institutes/update/:id"
            element={
              <ProtectedRoute>
                <EditUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/users/institutes/:id"
            element={
              <ProtectedRoute>
                <UserDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/users/:id/notify"
            element={
              <ProtectedRoute>
                <Notification />
              </ProtectedRoute>
            }
          />

          {/* User - Families */}
          <Route
            path="/champion/users/families"
            element={
              <ProtectedRoute>
                <Families />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/users/families/update/:id"
            element={
              <ProtectedRoute>
                <EditFamiliesUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/users/families/:id"
            element={
              <ProtectedRoute>
                <FamiliesUserDetails />
              </ProtectedRoute>
            }
          />

          {/* Institutes */}
          <Route
            path="/champion/institutes"
            element={
              <ProtectedRoute>
                <InstitutesList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/institutes/:instituteId"
            element={
              <ProtectedRoute>
                <InstituteDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/institutes/:instituteId/grades/:gradeId/classrooms"
            element={
              <ProtectedRoute>
                <ClassRooms />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/institutes/:instituteId/grades/:gradeId/classrooms/:classRoomId"
            element={
              <ProtectedRoute>
                <ClassRoomsDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/institutes/:instituteId/grades/:gradeId/classrooms/:classRoomId/students/:studentId"
            element={
              <ProtectedRoute>
                <StudentNotesDetails />
              </ProtectedRoute>
            }
          />
          {/* Families */}
          <Route
            path="/champion/families"
            element={
              <ProtectedRoute>
                <FamiliesList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/families/:familyId"
            element={
              <ProtectedRoute>
                <FamiliesDetails />
              </ProtectedRoute>
            }
          />
          {/* Default settigs - gifts */}
          <Route
            path="/champion/defaultsettings/gifts"
            element={
              <ProtectedRoute>
                <GiftsList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/defaultsettings/gifts/new"
            element={
              <ProtectedRoute>
                <AddGift />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/defaultsettings/gifts/update/:giftId"
            element={
              <ProtectedRoute>
                <EditGift />
              </ProtectedRoute>
            }
          />

          {/* Default settigs - Tasks */}
          <Route
            path="/champion/defaultsettings/tasks"
            element={
              <ProtectedRoute>
                <TasksList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/defaultsettings/tasks/new"
            element={
              <ProtectedRoute>
                <AddTask />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/defaultsettings/tasks/update/:taskId"
            element={
              <ProtectedRoute>
                <EditTask />
              </ProtectedRoute>
            }
          />

          {/* Ads */}
          <Route
            path="/champion/ads"
            element={
              <ProtectedRoute>
                <Ads />
              </ProtectedRoute>
            }
          />
          {/* Notifictions */}
          <Route
            path="/champion/notifications/all"
            element={
              <ProtectedRoute>
                <NotifyAllUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/notifications/multiple"
            element={
              <ProtectedRoute>
                <NotifyMultiUsers />
              </ProtectedRoute>
            }
          />
          {/* Subscriptions - Subscriptions */}
          <Route
            path="/champion/subscriptions"
            element={
              <ProtectedRoute>
                <SubscriptionsList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/subscriptions/familymembers/:familyId"
            element={
              <ProtectedRoute>
                <SubscriptionDetails />
              </ProtectedRoute>
            }
          />
          {/* Subscriptions - Pricing */}
          <Route
            path="/champion/pricing"
            element={
              <ProtectedRoute>
                <PricingList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/pricing/new"
            element={
              <ProtectedRoute>
                <AddPrice />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/pricing/update/:pricingId"
            element={
              <ProtectedRoute>
                <EditPricing />
              </ProtectedRoute>
            }
          />
          {/* Subscriptions - Offers */}
          <Route
            path="/champion/offers"
            element={
              <ProtectedRoute>
                <OffersList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/offers/new"
            element={
              <ProtectedRoute>
                <AddOffers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/champion/offers/update/:id"
            element={
              <ProtectedRoute>
                <EditOffers />
              </ProtectedRoute>
            }
          />
          {/* Contacts */}
          <Route
            path="/champion/contacts"
            element={
              <ProtectedRoute>
                <Contacts />
              </ProtectedRoute>
            }
          />
          {/* Copy Rights */}
          <Route
            path="/champion/copyrights"
            element={
              <ProtectedRoute>
                <CopyRights />
              </ProtectedRoute>
            }
          />
          {/* Reports - Subscriptions */}
          <Route
            path="/champion/reports/subscriptions"
            element={
              <ProtectedRoute>
                <Subscriptions />
              </ProtectedRoute>
            }
          />
          {/* Reports - Pricing */}
          <Route
            path="/champion/reports/pricing"
            element={
              <ProtectedRoute>
                <Pricing />
              </ProtectedRoute>
            }
          />
          {/* Reports - Families */}
          <Route
            path="/champion/reports/families"
            element={
              <ProtectedRoute>
                <Family />
              </ProtectedRoute>
            }
          />
          {/* Reports - Institutes */}
          <Route
            path="/champion/reports/institutes"
            element={
              <ProtectedRoute>
                <Institute />
              </ProtectedRoute>
            }
          />
          {/* Reports - Active users */}
          <Route
            path="/champion/reports/activeusers"
            element={
              <ProtectedRoute>
                <ActiveUsers />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}
