export const authHeader = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    return {
      Authorization: "Bearer " + user.token,
    };
  }
};

export const resetPasswordHeader = (token) => {
  if (token) {
    return {
      "Content-Type": "application/json",
      token: token,
    };
  } else {
    return {
      "Content-Type": "application/json",
    };
  }
};

export const authHeaderWithMultipart = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.token) {
    return {
      Authorization: "Bearer " + user.token,
      "Content-Type": "multipart/form-data",
    };
  } else {
    return {
      "Content-Type": "multipart/form-data",
    };
  }
};
