import axios from "axios";
import * as headers from "./auth-header";

const apiUrl = process.env.REACT_APP_API_URL;

export const getSubscriptionStats = () => {
  return axios.get(apiUrl + "stats/subscriptions", {
    headers: headers.authHeader(),
  });
};

export const getInstituteStats = () => {
  return axios.get(apiUrl + "stats/institute-users", {
    headers: headers.authHeader(),
  });
};

export const getFamilyStats = () => {
  return axios.get(apiUrl + "stats/family-users", {
    headers: headers.authHeader(),
  });
};

export const getTeacherStats = () => {
  return axios.get(apiUrl + "stats/institute-teachers", {
    headers: headers.authHeader(),
  });
};

// statistics count
export const getInstitutesCount = () => {
  return axios.get(apiUrl + "stats/count-institutes", {
    headers: headers.authHeader(),
  });
};

export const getFamilyCount = () => {
  return axios.get(apiUrl + "stats/count-families", {
    headers: headers.authHeader(),
  });
};

export const getUsersCount = () => {
  return axios.get(apiUrl + "stats/count-users", {
    headers: headers.authHeader(),
  });
};

export const getSubscriptionsCount = () => {
  return axios.get(apiUrl + "stats/count-subscriptions", {
    headers: headers.authHeader(),
  });
};
