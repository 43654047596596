import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Container from "../../components/Layout/Container";
import Layout from "../../components/Layout/Layout";
import * as InstitutesServices from "../../services/institutes.services";
import { logout } from "../../slices/authSlice";
import { Link } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/24/outline";
import PageLoader from "../../components/Loaders/PageLoader";
import { format } from "date-fns";
import DataTable from "react-data-table-component";

const StudentNotesDetails = () => {
  const [studentNote, setStudentNote] = useState(null);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);

  const { instituteId } = useParams();
  const { gradeId } = useParams();
  const { classRoomId } = useParams();
  const { studentId } = useParams();
  const { t } = useTranslation();

  let dispatch = useDispatch();

  const columns = [
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ltr:ml-4 rtl:mr-4">
          {t("teacher")}
        </div>
      ),
      selector: (row) => (
        <div className="ltr:ml-4 rtl:mr-4">{row.teacherId}</div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-sm font-semibold  text-gray-900">
          {t("student")}
        </div>
      ),
      selector: (row) => row.studentId,
    },
    {
      name: (
        <div className="text-sm font-semibold  text-gray-900">
          {t("description")}
        </div>
      ),
      selector: (row) => row.description,
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ">
          {t("createdAt")}
        </div>
      ),
      selector: (row) => (
        <>
          {row.createdAt
            ? format(new Date(row.createdAt), "MMM d, y, HH:mm aa")
            : "not found"}
        </>
      ),
      sortable: true,
    },
  ];

  const getInstituteTeachers = async (page) => {
    await InstitutesServices.getStudentNotes(studentId, page, limit)
      .then((response) => {
        setStudentNote(response.data.notes.rows);
        setCount(response.data.notes.count);
      })
      .catch((error) => {
        console.log("🚀 ~ file: EditUser.js:50 ~ useEffect ~ error", error);
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  useEffect(() => {
    getInstituteTeachers(1);
    // eslint-disable-next-line
  }, [studentId, dispatch]);

  const handlePageChange = (page) => {
    getInstituteTeachers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await InstitutesServices.getStudentNotes(
        studentId,
        page,
        newPerPage
      );
      setStudentNote(response.data.notes.rows);
      setLimit(newPerPage);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  // TODO add user, and teacher objects in api

  return (
    <Layout>
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to={"/champion/institutes"}
                className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500 hover:text-secondary"
              >
                {t("institutes")}
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to={`/champion/institutes/${instituteId}`}
                className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500 hover:text-secondary"
              >
                {t("instituteDetails")}
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm rtl:text-xs rtl:font-medium font-semibold text-gray-500">
                {t("grades")}
              </p>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to={`/champion/institutes/${instituteId}/grades/${gradeId}/classrooms`}
                className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500 hover:text-primary"
              >
                {t("classRooms")}
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to={`/champion/institutes/${instituteId}/grades/${gradeId}/classrooms/${classRoomId}`}
                className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500 hover:text-primary"
              >
                {t("classRoomsDetails")}
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("studentNoteDetails")}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <Container>
        <div className="flex">
          <h1 className="flex-1 text-2xl font-bold text-gray-900">
            {t("studentNoteDetails")}
          </h1>
        </div>
        <div className="mt-6 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              {studentNote ? (
                <DataTable
                  columns={columns}
                  data={studentNote}
                  progressPending={loading}
                  progressComponent={<PageLoader />}
                  pagination
                  paginationServer
                  paginationTotalRows={count}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  fixedHeader
                  direction={
                    window.localStorage.getItem("i18nextLng") === "en"
                      ? "ltr"
                      : "rtl"
                  }
                />
              ) : (
                <PageLoader />
              )}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default StudentNotesDetails;
