import { useEffect, useState } from "react";
import Container from "../../../components/Layout/Container";
import Layout from "../../../components/Layout/Layout";
import SubscriptionsTab from "../../../components/Tabs/SubscriptionsTab";
import * as subscriptionsServices from "../../../services/subscriptions/subscriptions.services";
import { logout } from "../../../slices/authSlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  HomeIcon,
  UserGroupIcon,
  BuildingOffice2Icon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import PageLoader from "../../../components/Loaders/PageLoader";
import DataTable from "react-data-table-component";
import { format } from "date-fns";

const SubscriptionsList = () => {
  const [subscriptions, setSubscriptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);

  const { t } = useTranslation();
  let dispatch = useDispatch();

  const columns = [
    {
      name: (
        <div className="text-sm font-semibold text-gray-900">
          {t("accountType")}
        </div>
      ),
      selector: (row) => (
        <>
          {row.user?.institute && (
            <div className="px-3 py-1 rounded-md flex flex-row">
              <BuildingOffice2Icon className="h-6 w-6 text-secondary" />
              <span className="mt-1 mx-1">{t("institute")}</span>
            </div>
          )}
          {row.user?.family && (
            <div className="px-3 py-1 rounded-md flex flex-row">
              <UserGroupIcon className="h-6 w-6 text-secondary" />
              <span className="mt-1 mx-1">{t("families")}</span>
            </div>
          )}
        </>
      ),
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900">{t("status")}</div>
      ),
      selector: (row) => {
        let currentDate = new Date();
        let fromDate = new Date(row.from);
        let toDate = new Date(row.to);
        if (currentDate >= fromDate && currentDate <= toDate) {
          return (
            <div className="bg-primary text-white px-3 py-1 rounded-md">
              {t("subscribed")}
            </div>
          );
        } else {
          return (
            <div className="bg-gray-200 text-gray-500 px-3 py-1 rounded-md">
              {t("expired")}
            </div>
          );
        }
      },
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ">{t("from")}</div>
      ),
      selector: (row) => (
        <>
          {row.from
            ? format(new Date(row.from), "MMM d, y, HH:mm aa")
            : "not found"}
        </>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ">{t("to")}</div>
      ),
      selector: (row) => (
        <>
          {row.to
            ? format(new Date(row.to), "MMM d, y, HH:mm aa")
            : "not found"}
        </>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900">
          {t("actions")}
        </div>
      ),
      selector: (row) => (
        <div className="flex space-x-6">
          <Link
            className="inline-flex items-center"
            to={`/champion/subscriptions/familymembers/${row.user?.family?.id}`}
          >
            <DocumentMagnifyingGlassIcon className="h-6 w-6 text-primary hover:text-secondary" />
          </Link>
        </div>
      ),
      width: "100px",
    },
  ];

  const getSubscriptions = async (page) => {
    try {
      const response = await subscriptionsServices.getAllSubscriptions(
        page,
        limit
      );
      if (response.status === 200) {
        setSubscriptions(response.data.subscriptions.rows);
        setCount(response.data.subscriptions.count);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const handlePageChange = (page) => {
    getSubscriptions(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await subscriptionsServices.getAllSubscriptions(
        page,
        newPerPage
      );
      setSubscriptions(response.data.subscriptions.rows);
      setLimit(newPerPage);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  useEffect(() => {
    getSubscriptions(1);
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      {/* Breadcrumb */}
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 md:px-8 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500">
                {t("subscriptions")}
              </p>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("subscriptionsList")}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <SubscriptionsTab />
      <Container>
        <div className="flex">
          <h1 className="flex-1 text-2xl font-bold text-gray-900">
            {t("subscriptionsList")}
          </h1>
        </div>
        <div className="mt-6 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="min-w-full py-2 align-middle md:px-6 lg:px-8">
              {subscriptions ? (
                <DataTable
                  columns={columns}
                  data={subscriptions}
                  progressPending={loading}
                  progressComponent={<PageLoader />}
                  pagination
                  paginationServer
                  paginationTotalRows={count}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  fixedHeader
                  direction={
                    window.localStorage.getItem("i18nextLng") === "en"
                      ? "ltr"
                      : "rtl"
                  }
                />
              ) : (
                <PageLoader />
              )}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default SubscriptionsList;
