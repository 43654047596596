import { useState, useEffect } from "react";
import Container from "../../components/Layout/Container";
import Layout from "../../components/Layout/Layout";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import * as familiesServices from "../../services/families.services";
import { HomeIcon } from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { logout } from "../../slices/authSlice";
import { format } from "date-fns";
import PageLoader from "../../components/Loaders/PageLoader";
import DataTable from "react-data-table-component";

const FamiliesDetails = () => {
  const [family, setFamily] = useState(null);
  const [familyName, setFamilyName] = useState(null);

  const { t } = useTranslation();
  const { familyId } = useParams();

  let dispatch = useDispatch();

  const apiUrl = process.env.REACT_APP_API_URL;

  const columns = [
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ltr:ml-4 rtl:mr-4">
          {t("profile")}
        </div>
      ),
      selector: (row) => (
        <div className="ltr:ml-4 rtl:mr-4">
          {row?.user?.avatar === null ? (
            <div>
              <div className="flex justify-center items-center rounded-md bg-gray-300 h-8 w-8 border border-none">
                <span className="text-base font-semibold text-gray-700">
                  {row.user?.firstName.charAt(0).toUpperCase()}
                </span>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex justify-center items-center rounded-md bg-gray-300 h-8 w-8 border border-none">
                <img src={apiUrl + "user-image/" + row.user?.avatar} alt="" />
              </div>
            </div>
          )}
        </div>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900">{t("name")}</div>
      ),
      selector: (row) => (
        <div className="flex flex-col">
          <div className="text-sm font-semibold text-gray-900 pt-2">
            {row.user?.firstName}
          </div>
          <div className="text-sm text-gray-500 pb-2">{row.user?.midName}</div>
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900">
          {t("relevance")}
        </div>
      ),
      selector: (row) => (
        <>
          {row?.relevance === "" || row?.relevance === null ? (
            <div className="bg-gray-200 text-gray-800 px-3 py-1 rounded-md">
              {t("noRelevance")}
            </div>
          ) : (
            <div>{row?.relevance}</div>
          )}
        </>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ">
          {t("createdAt")}
        </div>
      ),
      selector: (row) => (
        <>
          {row.createdAt
            ? format(new Date(row.createdAt), "MMM d, y, HH:mm aa")
            : "not found"}
        </>
      ),
      sortable: true,
    },
  ];

  const getFamilies = async () => {
    await familiesServices
      .getFamilyById(familyId)
      .then((response) => {
        setFamily(response.data.families.familyMembers);
        setFamilyName(response.data.families);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  useEffect(() => {
    getFamilies();
    // eslint-disable-next-line
  }, [familyId, dispatch]);

  return (
    <Layout>
      {/* Breadcrumb */}
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 md:px-8 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to={"/champion/families"}
                className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500 hover:text-primary"
              >
                {t("families")}
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("familyMembers")}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <Container>
        <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-4">
          {familyName ? (
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="-py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-xl font-medium text-gray-500">
                    {t("familyName")}{" "}
                    <span className="text-black mx-4">
                      {familyName.name.charAt(0).toUpperCase() +
                        familyName.name.slice(1)}
                    </span>
                  </dt>
                </div>
              </dl>
            </div>
          ) : (
            <PageLoader />
          )}
        </div>
        <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-8">
          {family ? (
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg font-bold leading-6 text-gray-900">
                  {t("familyMembers")}
                </h3>
              </div>
              <div className="flex flex-col">
                <div className="mb-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    {
                      <DataTable
                        columns={columns}
                        data={family}
                        fixedHeader
                        direction={
                          window.localStorage.getItem("i18nextLng") === "en"
                            ? "ltr"
                            : "rtl"
                        }
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <PageLoader />
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default FamiliesDetails;
