import { useState } from "react";

export default function PreviewImage({ file }) {
  const [preview, setPreview] = useState(null);
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    setPreview(reader.result);
  };
  return (
    <div className="text-center">
      <img
        src={preview}
        alt="Preview"
        className="w-60 h-60 mb-4 rounded-full"
      />
    </div>
  );
}
