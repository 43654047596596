import { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Layout from "../../../components/Layout/Layout";
import {
  DocumentMagnifyingGlassIcon,
  PencilSquareIcon,
  TrashIcon,
  HomeIcon,
} from "@heroicons/react/24/outline";
import * as FamiliesServices from "../../../services/users/families.services";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { logout } from "../../../slices/authSlice";
import PageLoader from "../../../components/Loaders/PageLoader";
import UsersTabs from "../../../components/Tabs/UsersTab";
import Container from "../../../components/Layout/Container";
import DeleteModal from "../../../components/modals/DeleteModal";
import ErrorAlert from "../../../components/Alerts/ErrorAlert";
import SuccessAlert from "../../../components/Alerts/SuccessAlert";

const Families = () => {
  const [families, setFamilies] = useState(null);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentIdValue, setCurrentIdValue] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [key, setKey] = useState("");

  const { t } = useTranslation();
  let cancelButtonRef = useRef(null);
  let dispatch = useDispatch();

  const columns = [
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ltr:ml-4 rtl:mr-4">
          {t("user")}
        </div>
      ),
      selector: (row) => (
        <div className="bg-gray-800 text-gray-50 px-3 py-1 rounded-md ltr:ml-4 rtl:mr-4">
          {row.user?.code}
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900">
          {t("firstName")}
        </div>
      ),
      selector: (row) => row.user?.firstName,
      sortable: true,
      width: "150px",
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900">
          {t("midName")}
        </div>
      ),
      selector: (row) => row.user?.midName,
      width: "150px",
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900">
          {t("userEmail")}
        </div>
      ),
      selector: (row) => row.user?.email,
      width: "200px",
    },

    {
      name: (
        <div className="text-sm font-semibold text-gray-900">
          {t("phoneNumber")}
        </div>
      ),
      selector: (row) => (
        <>
          {row.user?.phoneNumber === "" || row.user?.phoneNumber === null ? (
            <div className="bg-gray-200 text-gray-800 px-3 py-1 rounded-md">
              {t("noPhoneNumber")}
            </div>
          ) : (
            <div>{row.user?.phoneNumber}</div>
          )}
        </>
      ),
      width: "200px",
    },

    {
      name: (
        <div className="text-sm font-semibold text-gray-900">
          {t("createdAt")}
        </div>
      ),
      selector: (row) => (
        <>
          {row.createdAt
            ? format(new Date(row.createdAt), "MMM d, y, HH:mm aa")
            : "not found"}
        </>
      ),
    },

    {
      name: (
        <div className="text-sm font-semibold text-gray-900">
          {t("actions")}
        </div>
      ),
      selector: (row) => (
        <div className="flex space-x-6">
          <Link
            className="inline-flex items-center"
            to={`/champion/users/families/${row.user?.id}`}
          >
            <DocumentMagnifyingGlassIcon className="h-6 w-6 text-primary hover:text-secondary" />
          </Link>
          <Link
            className="inline-flex items-center"
            to={`/champion/users/families/update/${row.user?.id}`}
          >
            <PencilSquareIcon className="h-6 w-6 text-primary hover:text-secondary rtl:mr-5" />
          </Link>
          <button
            className="inline-flex items-center"
            onClick={() => showDeleteModal(row.user?.id)}
            ref={cancelButtonRef}
          >
            <TrashIcon className="h-6 w-6 text-red-700 hover:text-red-600" />
          </button>
        </div>
      ),
      width: "180px",
    },
  ];

  const getFamiliesUsers = async (page) => {
    setLoading(true);
    try {
      const response = await FamiliesServices.getAllFamiliesAdmin(page, limit);
      if (response.status === 200) {
        setFamilies(response.data.users.rows);
        setCount(response.data.users.count);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const handlePageChange = (page) => {
    getFamiliesUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await FamiliesServices.getAllFamiliesAdmin(
        page,
        newPerPage
      );
      setFamilies(response.data.users.rows);
      setLimit(newPerPage);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  useEffect(() => {
    getFamiliesUsers(1);
    // eslint-disable-next-line
  }, []);

  const showDeleteModal = (id) => {
    setDeleteModalOpen(true);
    setCurrentIdValue(id);
  };

  const handleDelete = () => {
    setIsSubmit(true);
    FamiliesServices.deleteFamiliesUser(currentIdValue)
      .then((response) => {
        setIsSubmit(false);
        if (response.status === 200) {
          let newFamilies = families.filter((family) => {
            return family.user?.id !== currentIdValue;
          });
          setFamilies(newFamilies);
          setDeleteModalOpen(false);
          setSuccess(true);
        }
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: Institutes.js:209 ~ handleDelete ~ error",
          error
        );
        if (error.response.status === 401) {
          dispatch(logout());
        } else {
          setError(true);
          setDeleteModalOpen(false);
        }
      });
  };

  const handleCloseMessage = () => {
    if (error) setError(false);
    if (success) setSuccess(false);
  };

  return (
    <Layout>
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 md:px-8 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500">
                {t("users")}
              </p>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("families")}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <UsersTabs />
      {/* Main content */}
      <Container>
        {/* Delete modal */}
        <DeleteModal
          deleteModalOpen={deleteModalOpen}
          setDeleteModalOpen={setDeleteModalOpen}
          cancelButtonRef={cancelButtonRef}
          handleDelete={handleDelete}
          setCurrentIdValue={setCurrentIdValue}
          isSubmit={isSubmit}
          name={t("deleteUser")}
        />

        {error && (
          <ErrorAlert
            message={t("deleteUserError")}
            close={handleCloseMessage}
          />
        )}
        {success && (
          <SuccessAlert
            message={t("deleteUserSuccess")}
            close={handleCloseMessage}
          />
        )}

        <div className="flex">
          <h1 className="flex-1 text-2xl font-bold text-gray-900">
            {t("familiesUsersList")}
          </h1>
        </div>

        <div className="mt-6 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="min-w-full py-2 align-middle md:px-6 lg:px-8">
              {families ? (
                <DataTable
                  columns={columns}
                  data={families}
                  progressPending={loading}
                  progressComponent={<PageLoader />}
                  pagination
                  paginationServer
                  paginationTotalRows={count}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  fixedHeader
                  direction={
                    window.localStorage.getItem("i18nextLng") === "en"
                      ? "ltr"
                      : "rtl"
                  }
                />
              ) : (
                <PageLoader />
              )}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Families;
