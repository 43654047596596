import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../slices/authSlice";
import errorReducer from "../slices/errorSlice";

const reducer = {
  auth: authReducer,
  error: errorReducer,
};
const store = configureStore({
  reducer: reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default store;
