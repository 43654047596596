import { useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import authServices from "../../services/auth.services";
import { clearError } from "../../slices/errorSlice";
import { setError } from "../../slices/errorSlice";
import logo from "../../assets/logo.svg";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import { useTranslation } from "react-i18next";
import ButtonLoader from "../../components/Loaders/ButtonLoader";

export default function ResetPassword() {
  const [loading, setLoading] = useState(false);

  let { email } = useParams();
  let { token } = useParams();
  const { t } = useTranslation();
  const { error } = useSelector((state) => state.error);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleSubmit = (code, newPassword) => {
    setLoading(true);
    authServices
      .resetPassword(token, {
        email: email,
        code: code,
        newPassword: newPassword,
      })
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          navigate("/");
        } else {
          dispatch(setError({ message: response.data.message }));
        }
      })
      .catch((error) => {
        setLoading(false);
        dispatch(setError({ message: error.response.data.message }));
      });
  };

  const ValidationSchema = Yup.object().shape({
    code: Yup.string().required(t("otpRequired")),
    newPassword: Yup.string()
      .min(3, "passwordMin")
      .required(t("passwordRequired")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], t("passwordMatch"))
      .required(t("confirmPasswordRequired")),
  });

  const handleCloseMessage = () => {
    dispatch(clearError());
  };

  return (
    <div className="h-screen bg-gradient-to-tr from-primary to-gray-shade">
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-28 w-auto" src={logo} alt="Whos champion" />
          <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-white drop-shadow-md">
            {t("updatePassword")}
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white/25 py-8 px-4 sm:px-10 shadow-lg rounded-lg">
            <Formik
              initialValues={{ code: "", newPassword: "", confirmPassword: "" }}
              validationSchema={ValidationSchema}
              onSubmit={(values) =>
                handleSubmit(values.code, values.newPassword)
              }
            >
              {({ touched, errors, isSubmitting }) => (
                <Form className="space-y-6">
                  <div className="mt-1">
                    <label
                      htmlFor="code"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("code")}
                    </label>
                    <Field
                      name="code"
                      id="code"
                      type="text"
                      autoComplete="code"
                      className={`block w-full rounded-md border border-transparent shadow-sm sm:text-sm mt-1 ${
                        touched.confirmPassword && errors.confirmPassword
                          ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                          : "border border-transparent focus:border-secondary focus:ring-secondary"
                      }`}
                    />
                    <ErrorMessage
                      name="code"
                      component="p"
                      className="text-red-500 text-xs"
                    />
                  </div>
                  <div className="mt-1">
                    <label
                      htmlFor="newPassword"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("newPass")}
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 ltr:left-0 rtl:right-0 flex items-center ltr:pl-3 rtl:pr-3">
                        <i className="fi fi-rr-lock h-5 w-5 text-gray-400"></i>
                      </div>
                      <Field
                        name="newPassword"
                        id="newPassword"
                        type="password"
                        autoComplete="newPassword"
                        className={`block w-full rounded-md border border-transparent ltr:pl-10 rtl:pr-10 shadow-sm sm:text-sm ${
                          touched.confirmPassword && errors.confirmPassword
                            ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                            : "border border-transparent focus:border-secondary focus:ring-secondary"
                        }`}
                      />
                    </div>
                    <ErrorMessage
                      name="newPassword"
                      component="p"
                      className="text-red-500 text-xs"
                    />
                  </div>
                  <div className="mt-1">
                    <label
                      htmlFor="confirmPassword"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("confirmPass")}
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 ltr:left-0 rtl:right-0 flex items-center ltr:pl-3 rtl:pr-3">
                        <i className="fi fi-rr-lock h-5 w-5 text-gray-400"></i>
                      </div>
                      <Field
                        name="confirmPassword"
                        id="confirmPassword"
                        type="password"
                        autoComplete="confirmPassword"
                        className={`block w-full rounded-md border border-transparent ltr:pl-10 rtl:pr-10 shadow-sm sm:text-sm ${
                          touched.confirmPassword && errors.confirmPassword
                            ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                            : "border border-transparent focus:border-secondary focus:ring-secondary"
                        }`}
                      />
                    </div>
                    <ErrorMessage
                      name="confirmPassword"
                      component="p"
                      className="text-red-500 text-xs"
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary"
                    >
                      {loading ? (
                        <>
                          <ButtonLoader /> {t("reset")}
                        </>
                      ) : (
                        t("reset")
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            {error && (
              <div className="mt-6">
                <ErrorAlert
                  message={error.message}
                  close={handleCloseMessage}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
