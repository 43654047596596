import axios from "axios";
import * as headers from "../auth-header";

const apiUrl = process.env.REACT_APP_API_URL;

export function getAllSubscriptions(page, limit) {
  return axios.get(apiUrl + `subscription/all/${page}/${limit}`, {
    headers: headers.authHeader(),
  });
}
