import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import PageLoader from "./Loaders/PageLoader";

const UserDetailsComponent = ({ user }) => {
  const { t } = useTranslation();
  return (
    <>
      {user ? (
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {t("userInformation")}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {t("personalDetails")}
            </p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {t("b6Code")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {user.code}
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {t("fullName")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {user.firstName + " " + user.midName}
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {t("phoneNumber")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {user?.phoneNumber === "" || user?.phoneNumber === null ? (
                    <div className="bg-gray-200 text-gray-800 px-3 py-1 rounded-md w-60 flex justify-center">
                      {t("noPhoneNumber")}
                    </div>
                  ) : (
                    <div>{user?.phoneNumber}</div>
                  )}
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {t("email")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {user.email}
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {t("birthDate")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {user?.birthDate === "" || user?.birthDate === null ? (
                    <div className="bg-gray-200 text-gray-800 px-3 py-1 rounded-md w-60 flex justify-center">
                      {t("noBirthDate")}
                    </div>
                  ) : (
                    <div>{format(new Date(user.birthDate), "MMMM d, y")}</div>
                  )}
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {t("gender")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {user.gender}
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  {t("createdAt")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {format(new Date(user.createdAt), "MMM d, y, HH:mm aa")}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      ) : (
        <PageLoader />
      )}
    </>
  );
};

export default UserDetailsComponent;
