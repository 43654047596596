import { createSlice } from "@reduxjs/toolkit";

const initialState = {};
const ErrorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, action) => {
      return { error: action.payload };
    },
    clearError: () => {
      return { error: null };
    },
  },
});

const { reducer, actions } = ErrorSlice;

export const { setError, clearError } = actions;
export default reducer;
