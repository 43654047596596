import React, { useState, useEffect } from "react";
import Layout from "../../../components/Layout/Layout";
import Container from "../../../components/Layout/Container";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import * as familiesServices from "../../../services/users/families.services";
import UserDetailsComponent from "../../../components/UserDetailsComponent";
import { PencilSquareIcon, HomeIcon } from "@heroicons/react/24/outline";

const FamiliesUserDetails = () => {
  const [user, setUser] = useState(null);

  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    const getById = async () => {
      await familiesServices
        .getFamilyAdminById(id)
        .then((response) => {
          setUser(response.data.user);
        })
        .catch((error) => {
          console.log("🚀 ~ file: EditUser.js:50 ~ useEffect ~ error", error);
        });
    };
    getById();
  }, [id]);

  return (
    <Layout>
      {/* Breadcrumb */}
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500">
                {t("users")}
              </p>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to={"/champion/users/families"}
                className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500 hover:text-secondary"
              >
                {t("families")}
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("userDetails")}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <Container>
        <div className="flex justify-end items-center mb-3">
          <Link
            to={`/champion/users/families/update/${user?.id}`}
            className="flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary ltr:mr-2 rtl:ml-2"
          >
            <PencilSquareIcon className="h-5 w-5 text-white ltr:mr-2 rtl:ml-2" />
            {t("editUser")}
          </Link>
          <Link
            to={`/champion/users/${user?.id}/notify`}
            className="flex justify-center rounded-md border border-secondary bg-white py-2 px-4 text-sm font-medium text-secondary hover:text-white shadow-sm hover:bg-secondary"
          >
            {t("sendNotification")}
          </Link>
        </div>
        <UserDetailsComponent user={user} />
      </Container>
    </Layout>
  );
};

export default FamiliesUserDetails;
