import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { format } from "date-fns";
import PageLoader from "../Loaders/PageLoader";
import { useDispatch } from "react-redux";
import { logout } from "../../slices/authSlice";
import * as InstitutesServices from "../../services/institutes.services";

const Grades = () => {
  const [grades, setGrades] = useState(null);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);

  const { t } = useTranslation();
  const { instituteId } = useParams();

  const dispatch = useDispatch();

  const columns = [
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ltr:ml-4 rtl:mr-4">
          {t("gradename")}
        </div>
      ),
      selector: (row) => (
        <div className="flex flex-col ltr:ml-4 rtl:mr-4">
          <div className="text-sm font-semibold text-gray-900 pt-2">
            {row?.englishName}
          </div>
          <div className="text-sm text-gray-500 pb-2">{row?.arabicName}</div>
        </div>
      ),
      sortable: true,
      width: "300px",
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ">
          {t("createdAt")}
        </div>
      ),
      selector: (row) => (
        <>
          {row.createdAt
            ? format(new Date(row.createdAt), "MMM d, y, HH:mm aa")
            : "not found"}
        </>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ">
          {t("actions")}
        </div>
      ),
      selector: (row) => (
        <div className="flex space-x-6">
          <Link
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-secondary text-base font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
            to={`/champion/institutes/${row.instituteId}/grades/${row?.id}/classrooms`}
          >
            {t("details")}
          </Link>
        </div>
      ),
      width: "150px",
    },
  ];

  const getGrades = async (page) => {
    setLoading(true);
    await InstitutesServices.getInstituteGrades(instituteId, page, limit)
      .then((response) => {
        setGrades(response.data.grades.rows);
        setCount(response.data.grades.count);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  useEffect(() => {
    getGrades(1);
    // eslint-disable-next-line
  }, [instituteId, dispatch]);

  const handlePageChange = (page) => {
    getGrades(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await InstitutesServices.getAllInstitutes(
        page,
        newPerPage
      );
      setGrades(response.data.grades.rows);
      setLimit(newPerPage);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-8">
      {grades ? (
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-bold leading-6 text-gray-900">
              {t("grades")}
            </h3>
          </div>
          <div className="flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                {
                  <DataTable
                    columns={columns}
                    data={grades}
                    progressPending={loading}
                    progressComponent={<PageLoader />}
                    pagination
                    paginationServer
                    paginationTotalRows={count}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    fixedHeader
                    direction={
                      window.localStorage.getItem("i18nextLng") === "en"
                        ? "ltr"
                        : "rtl"
                    }
                  />
                }
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

export default Grades;
