import * as adsServices from "../../services/ads.services";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/24/outline";
import Container from "../../components/Layout/Container";
import Layout from "../../components/Layout/Layout";
import { useDispatch } from "react-redux";
import { logout } from "../../slices/authSlice";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonLoader from "../../components/Loaders/ButtonLoader";
import PageLoader from "../../components/Loaders/PageLoader";
import PreviewImage from "../../components/PreviewImage";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import ErrorAlert from "../../components/Alerts/ErrorAlert";

const Ads = () => {
  const [ads, setAds] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();
  let dispatch = useDispatch();

  const apiUrl = process.env.REACT_APP_API_URL;

  const adsSchema = Yup.object().shape({
    image: Yup.string(),
    arabicTitle: Yup.string()
      .min(3, t("characterNumber"))
      .required(t("titleRequired")),
    englishTitle: Yup.string()
      .min(3, t("characterNumber"))
      .required(t("titleRequired")),
    arabicDescription: Yup.string().required(t("arabicDescription")),
    englishDescription: Yup.string().required(t("englishDescription")),
    url: Yup.string(),
  });

  const getAds = async () => {
    try {
      const response = await adsServices.getAllAds();
      if (response.status === 200) {
        setAds(response.data.ads.rows[0]);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  useEffect(() => {
    getAds();
    // eslint-disable-next-line
  }, [dispatch]);

  const handleSubmit = (values) => {
    setIsSubmit(true);
    adsServices
      .updateAd({ ...values, id: ads.id })
      .then((response) => {
        setIsSubmit(false);
        setSuccess(true);
      })
      .catch((error) => {
        setIsSubmit(false);
        if (error.response.status === 401) {
          dispatch(logout());
        } else {
          setError(true);
        }
      });
  };

  const handleCloseMessage = () => {
    if (error) setError(false);
    if (success) setSuccess(false);
  };

  return (
    <Layout>
      {/* Breadcrumb */}
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 md:px-8 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("advertisements")}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <Container>
        <h1 className="flex-1 text-2xl font-bold text-gray-900">{t("ads")}</h1>
        <div className="mt-4 flex flex-col w-full">
          {error && (
            <div className="my-4">
              <ErrorAlert
                message={t("updateAdsError")}
                close={handleCloseMessage}
              />
            </div>
          )}
          {success && (
            <SuccessAlert
              message={t("updateAdsSuccess")}
              close={handleCloseMessage}
            />
          )}
          {ads ? (
            <div className="py-8 px-4 bg-white shadow-lg sm:rounded-lg sm:px-10">
              <Formik
                initialValues={{
                  image: "",
                  arabicTitle: ads.arabicTitle ?? "",
                  englishTitle: ads.englishTitle ?? "",
                  arabicDescription: ads.arabicDescription ?? "",
                  englishDescription: ads.englishDescription ?? "",
                  url: ads.url ?? "",
                }}
                validationSchema={adsSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {({ touched, errors, values, setFieldValue, setTouched }) => (
                  <Form>
                    <div className="flex justify-center items-center">
                      <div
                        className={`mt-3 px-6 py-6 border-2 border-dashed rounded-md ${
                          touched.image && errors.image
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                      >
                        <div className="space-y-1 text-center">
                          {values.image ? (
                            <div className="w-60 h-60">
                              <PreviewImage file={values.image} />
                            </div>
                          ) : (
                            <img
                              src={apiUrl + "ads-image/" + ads?.image}
                              className="w-60 h-60 mb-4 rounded-md"
                              alt=""
                            />
                          )}
                          <div className="flex justify-between item-center">
                            <div className="flex text-sm text-gray-600">
                              <label
                                htmlFor="image"
                                className="relative cursor-pointer rounded-md font-medium text-gray-500 hover:text-secondary focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-secondary"
                              >
                                <span>{t("uploadImage")}</span>
                                <input
                                  id="image"
                                  name="image"
                                  type="file"
                                  className="sr-only"
                                  onChange={(event) => {
                                    setTouched({
                                      ...touched,
                                      thumbnail: true,
                                    });
                                    setFieldValue(
                                      "image",
                                      event.target.files[0]
                                    );
                                  }}
                                />
                              </label>
                            </div>
                            <div className="text-xs text-gray-500">
                              {values.image ? (
                                <button
                                  className="relative cursor-pointer rounded-md font-medium text-gray-500 hover:text-secondary focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-secondary"
                                  onClick={() => {
                                    setFieldValue("image", null);
                                  }}
                                >
                                  {t("remove")}
                                </button>
                              ) : (
                                "PNG, JPG, GIF - up to 2MB"
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-6 gap-6 mt-4">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="arabicTitle"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t("arabicTitle")}
                        </label>
                        <Field
                          type="text"
                          name="arabicTitle"
                          id="arabicTitle"
                          className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                            touched.arabicTitle && errors.arabicTitle
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                        />
                        <ErrorMessage
                          name="arabicTitle"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="englishTitle"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t("englishTitle")}
                        </label>
                        <Field
                          type="text"
                          name="englishTitle"
                          id="englishTitle"
                          className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                            touched.englishTitle && errors.englishTitle
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                        />
                        <ErrorMessage
                          name="englishTitle"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="arabicDescription"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t("arabicDescription")}
                        </label>
                        <Field
                          type="text"
                          as="textarea"
                          name="arabicDescription"
                          id="arabicDescription"
                          className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                            touched.arabicDescription &&
                            errors.arabicDescription
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                        />
                        <ErrorMessage
                          name="arabicDescription"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="englishDescription"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t("englishDescription")}
                        </label>
                        <Field
                          type="text"
                          as="textarea"
                          name="englishDescription"
                          id="englishDescription"
                          className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                            touched.englishDescription &&
                            errors.englishDescription
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                        />
                        <ErrorMessage
                          name="englishDescription"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="url"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t("url")}
                        </label>
                        <Field
                          type="text"
                          name="url"
                          id="url"
                          className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                            touched.url && errors.url
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                        />
                        <ErrorMessage
                          name="url"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                    </div>
                    <div className="px-4 pt-8 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-primary text-base font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                      >
                        {isSubmit ? (
                          <>
                            <ButtonLoader />
                            {t("save")}
                          </>
                        ) : (
                          t("save")
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            <PageLoader />
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default Ads;
