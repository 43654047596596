import { useState } from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../components/Layout/Container";
import Layout from "../../../components/Layout/Layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/24/outline";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonLoader from "../../../components/Loaders/ButtonLoader";
import * as PricingServices from "../../../services/subscriptions/pricing.services";
import { useDispatch } from "react-redux";
import { logout } from "../../../slices/authSlice";
import ErrorAlert from "../../../components/Alerts/ErrorAlert";
import { useEffect } from "react";
import PageLoader from "../../../components/Loaders/PageLoader";

const EditPricing = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [pricing, setPricing] = useState(null);

  const { t } = useTranslation();
  const { pricingId } = useParams();

  let navigate = useNavigate();
  let dispatch = useDispatch();

  const newPricingSchema = Yup.object().shape({
    arabicTitle: Yup.string()
      .min(3, t("characterNumber"))
      .required(t("titleRequired")),
    englishTitle: Yup.string()
      .min(3, t("characterNumber"))
      .required(t("titleRequired")),
    appleProductId: Yup.string().required(t("appleProductIdRequired")),
    units: Yup.string(),
    price: Yup.string().required(t("priceRequired")),
    period: Yup.string().required(t("periodRequired")),
    arabicDescription: Yup.string().required(t("arabicDescriptionRequired")),
    englishDescription: Yup.string().required(t("englishDescriptionRequired")),
    interval: Yup.string().required(t("intervalRequired")),
    targetUser: Yup.string().required(t("targetUserRequired")),
    summarized: Yup.string().required(t("summarizedRequired")),
    special: Yup.string().required(t("specialRequired")),
  });

  const handleSubmit = (values) => {
    setIsSubmit(true);
    PricingServices.updatePrice(pricingId, values)
      .then((response) => {
        setIsSubmit(false);
        navigate("/champion/pricing");
      })
      .catch((error) => {
        setIsSubmit(false);
        if (error.response.status === 401) {
          dispatch(logout());
        } else {
          setError(true);
        }
      });
  };

  const handleCloseMessage = () => {
    setError(false);
  };

  useEffect(() => {
    const getById = () => {
      PricingServices.pricingDetails(pricingId)
        .then((response) => {
          setPricing(response.data.price);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(logout());
          } else {
            setError(true);
          }
        });
    };
    getById();
  }, [pricingId, dispatch]);

  return (
    <Layout>
      {/* Breadcrumb */}
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 md:px-8 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500">
                {t("subscriptions")}
              </p>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to={"/champion/pricing"}
                className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500 hover:text-primary"
              >
                {t("pricingList")}
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("updatePricing")}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <Container>
        {error && (
          <ErrorAlert
            message={t("updatePricingError")}
            close={handleCloseMessage}
          />
        )}
        <h1 className="flex-1 text-2xl font-bold text-gray-900">
          {t("updatePricing")}
        </h1>
        {pricing ? (
          <div className="py-8 px-4 bg-white shadow-lg sm:rounded-lg sm:px-10">
            <Formik
              initialValues={{
                arabicTitle: pricing.arabicTitle ?? "",
                englishTitle: pricing.englishTitle ?? "",
                appleProductId: pricing.appleProductId ?? "",
                units: pricing.units ?? "",
                price: pricing.price ?? "",
                period: pricing.period ?? "",
                arabicDescription: pricing.arabicDescription ?? "",
                englishDescription: pricing.englishDescription ?? "",
                interval: pricing.interval ?? "",
                targetUser: pricing.targetUser ?? "",
                summarized: pricing.summarized ?? "",
                special: pricing.special ?? "",
              }}
              validationSchema={newPricingSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({ touched, errors }) => (
                <Form>
                  <div className="grid md:grid-cols-3 gap-3 sm:grid-cols-1 mt-5">
                    <div>
                      <label
                        htmlFor="arabicTitle"
                        className="text-sm font-medium text-gray-700"
                      >
                        {t("arabicTitle")}
                      </label>
                      <Field
                        type="text"
                        name="arabicTitle"
                        id="arabicTitle"
                        className={`shadow-sm w-full sm:text-sm rounded-md ${
                          touched.arabicTitle && errors.arabicTitle
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      />
                      <ErrorMessage
                        name="arabicTitle"
                        component="span"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="englishTitle"
                        className=" text-sm font-medium text-gray-700"
                      >
                        {t("englishTitle")}
                      </label>
                      <Field
                        type="text"
                        name="englishTitle"
                        id="englishTitle"
                        className={`shadow-sm  w-full sm:text-sm rounded-md ${
                          touched.englishTitle && errors.englishTitle
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      />
                      <ErrorMessage
                        name="englishTitle"
                        component="span"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="appleProductId"
                        className=" text-sm font-medium text-gray-700"
                      >
                        {t("appleProductId")}
                      </label>
                      <Field
                        type="text"
                        name="appleProductId"
                        id="appleProductId"
                        className={`shadow-sm  w-full sm:text-sm rounded-md ${
                          touched.appleProductId && errors.appleProductId
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      />
                      <ErrorMessage
                        name="appleProductId"
                        component="span"
                        className="text-red-800 text-xs"
                      />
                    </div>
                  </div>
                  <div className="grid md:grid-cols-3 gap-3 sm:grid-cols-1 mt-5">
                    <div>
                      <label
                        htmlFor="units"
                        className=" text-sm font-medium text-gray-700"
                      >
                        {t("units")}
                      </label>
                      <Field
                        type="text"
                        name="units"
                        id="units"
                        className={`shadow-sm  w-full sm:text-sm rounded-md ${
                          touched.units && errors.units
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      />
                      <ErrorMessage
                        name="units"
                        component="span"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="price"
                        className=" text-sm font-medium text-gray-700"
                      >
                        {t("price")}
                      </label>
                      <Field
                        type="text"
                        name="price"
                        id="price"
                        className={`shadow-sm  w-full sm:text-sm rounded-md ${
                          touched.price && errors.price
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      />
                      <ErrorMessage
                        name="price"
                        component="span"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="targetUser"
                        className=" text-sm font-medium text-gray-700"
                      >
                        {t("targetUser")}
                      </label>
                      <Field
                        as="select"
                        name="targetUser"
                        id="targetUser"
                        className={`shadow-sm  w-full sm:text-sm rounded-md ${
                          touched.targetUser && errors.targetUser
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      >
                        <option
                          value=""
                          className="text-gray-400 hover:text-gray-500"
                        >
                          {t("select")}
                        </option>
                        <option value="family">{t("family")}</option>
                        <option value="institute">{t("institute")}</option>
                      </Field>
                      <ErrorMessage
                        name="targetUser"
                        component="span"
                        className="text-red-800 text-xs"
                      />
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 gap-3 sm:grid-cols-1 mt-5">
                    <div>
                      <label
                        htmlFor="period"
                        className="text-sm font-medium text-gray-700"
                      >
                        {t("period")}
                      </label>
                      <Field
                        type="text"
                        name="period"
                        id="period"
                        className={`shadow-sm w-full sm:text-sm rounded-md ${
                          touched.period && errors.period
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      />
                      <ErrorMessage
                        name="period"
                        component="span"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="interval"
                        className="text-sm font-medium text-gray-700"
                      >
                        {t("interval")}
                      </label>
                      <Field
                        as="select"
                        name="interval"
                        id="interval"
                        className={`shadow-sm w-full sm:text-sm rounded-md ${
                          touched.interval && errors.interval
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      >
                        <option
                          value=""
                          className="text-gray-400 hover:text-gray-500"
                        >
                          {t("select")}
                        </option>
                        <option value="month">{t("month")}</option>
                        <option value="year">{t("year")}</option>
                      </Field>
                      <ErrorMessage
                        name="interval"
                        component="span"
                        className="text-red-800 text-xs"
                      />
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 gap-3 sm:grid-cols-1 mt-5">
                    <div>
                      <label
                        htmlFor="arabicDescription"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("arabicDescription")}
                      </label>
                      <Field
                        type="text"
                        as="textarea"
                        name="arabicDescription"
                        id="arabicDescription"
                        className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                          touched.arabicDescription && errors.arabicDescription
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      />
                      <ErrorMessage
                        name="arabicDescription"
                        component="span"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="englishDescription"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("englishDescription")}
                      </label>
                      <Field
                        type="text"
                        as="textarea"
                        name="englishDescription"
                        id="englishDescription"
                        className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                          touched.englishDescription &&
                          errors.englishDescription
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      />
                      <ErrorMessage
                        name="englishDescription"
                        component="span"
                        className="text-red-800 text-xs"
                      />
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 gap-3 sm:grid-cols-1 mt-5">
                    <div>
                      <label
                        htmlFor="summarized"
                        className=" text-sm font-medium text-gray-700"
                      >
                        {t("displayType")}
                      </label>
                      <Field
                        as="select"
                        name="summarized"
                        id="summarized"
                        className={`shadow-sm  w-full sm:text-sm rounded-md ${
                          touched.summarized && errors.summarized
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      >
                        <option
                          value=""
                          className="text-gray-400 hover:text-gray-500"
                        >
                          {t("select")}
                        </option>
                        <option value="true">{t("summarized")}</option>
                        <option value="false">{t("detailed")}</option>
                      </Field>
                      <ErrorMessage
                        name="summarized"
                        component="span"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="special"
                        className=" text-sm font-medium text-gray-700"
                      >
                        {t("designType")}
                      </label>
                      <Field
                        as="select"
                        name="special"
                        id="special"
                        className={`shadow-sm  w-full sm:text-sm rounded-md ${
                          touched.special && errors.special
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      >
                        <option
                          value=""
                          className="text-gray-400 hover:text-gray-500"
                        >
                          {t("select")}
                        </option>
                        <option value="true">{t("specialDesign")}</option>
                        <option value="false">{t("normalDesign")}</option>
                      </Field>
                      <ErrorMessage
                        name="special"
                        component="span"
                        className="text-red-800 text-xs"
                      />
                    </div>
                  </div>
                  <div className="px-4 pt-8 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-primary text-base font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                    >
                      {isSubmit ? (
                        <>
                          <ButtonLoader />
                          {t("save")}
                        </>
                      ) : (
                        t("save")
                      )}
                    </button>
                    <Link
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-8 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                      to={"/champion/pricing"}
                    >
                      {t("cancel")}
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <PageLoader />
        )}
      </Container>
    </Layout>
  );
};

export default EditPricing;
