import axios from "axios";
import * as headers from "./auth-header";

const apiUrl = process.env.REACT_APP_API_URL;

export function getAllInstitutes(page, limit) {
  return axios.get(apiUrl + `institute/all/${page}/${limit}`, {
    headers: headers.authHeader(),
  });
}

export function getInstituteById(id) {
  return axios.get(apiUrl + `institute/${id}`, {
    headers: headers.authHeader(),
  });
}

export function getInstituteGrades(id, page, limit) {
  return axios.get(apiUrl + `institute/grades/${id}/${page}/${limit}`, {
    headers: headers.authHeader(),
  });
}

export function getInstituteTeachers(id, page, limit) {
  return axios.get(apiUrl + `institute/teachers/${id}/${page}/${limit}`, {
    headers: headers.authHeader(),
  });
}

// Class rooms services

export function getAllClassrooms(gradeId, page, limit) {
  return axios.get(
    apiUrl + `institute/class-rooms/${gradeId}/${page}/${limit}`,
    {
      headers: headers.authHeader(),
    }
  );
}

// Student services

export function getAllStudents(classRoomId, page, limit) {
  return axios.get(
    apiUrl + `institute/students/${classRoomId}/${page}/${limit}`,
    {
      headers: headers.authHeader(),
    }
  );
}

export function getStudentById(studentId) {
  return axios.get(apiUrl + `institute/student/${studentId}`, {
    headers: headers.authHeader(),
  });
}

export function getStudentNotes(studentId, page, limit) {
  return axios.get(
    apiUrl + `institute/student-notes/${studentId}/${page}/${limit}`,
    {
      headers: headers.authHeader(),
    }
  );
}

// Homeworks services
export function getAllHomeworks(classRoomId, page, limit) {
  return axios.get(
    apiUrl + `institute/home-works/${classRoomId}/${page}/${limit}`,
    {
      headers: headers.authHeader(),
    }
  );
}
