const PageLoader = () => {
  return (
    <div>
      <div className="flex items-center justify-center lg:max-w-6xl lg:mx-auto px-4 sm:px-6 lg:px-8">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          className="animate-spin h-10 w-10"
          width="200px"
          height="200px"
          preserveAspectRatio="xMidYMid"
        >
          <circle
            cx="50"
            cy="50"
            fill="none"
            stroke="#ffcc33"
            strokeWidth="7"
            r="32"
            strokeDasharray="150.79644737231007 52.26548245743669"
          ></circle>
        </svg>
      </div>
    </div>
  );
};

export default PageLoader;
