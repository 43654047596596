import { useState } from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../components/Layout/Container";
import Layout from "../../../components/Layout/Layout";
import { HomeIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as giftsServices from "../../../services/defaultSettings/gifts.services";
import PreviewImage from "../../../components/PreviewImage";
import ButtonLoader from "../../../components/Loaders/ButtonLoader";
import { useDispatch } from "react-redux";
import { logout } from "../../../slices/authSlice";
import ErrorAlert from "../../../components/Alerts/ErrorAlert";

const AddGift = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  let navigate = useNavigate();
  let dispatch = useDispatch();

  const newGiftSchema = Yup.object().shape({
    image: Yup.string(),
    arabicDescription: Yup.string().required(t("arabicDescription")),
    englishDescription: Yup.string().required(t("englishDescription")),
    accountType: Yup.string().required(t("accountType")),
    default: Yup.string().required(t("enabled")),
    targetPoints: Yup.string().required(t("targetPoints")),
  });

  const handleSubmit = (values) => {
    setIsSubmit(true);
    giftsServices
      .createGift(values)
      .then((response) => {
        setIsSubmit(false);
        navigate(-1);
      })
      .catch((error) => {
        setIsSubmit(false);
        if (error.response.status === 401) {
          dispatch(logout());
        } else {
          setError(true);
        }
      });
  };

  const handleCloseMessage = () => {
    setError(false);
  };

  return (
    <Layout>
      {/* Breadcrumb */}
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 md:px-8 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500">
                {t("defaultSettings")}
              </p>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to={"/champion/defaultsettings/gifts"}
                className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500 hover:text-primary"
              >
                {t("gifts")}
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("newGift")}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <Container>
        <h1 className="flex-1 text-2xl font-bold text-gray-900">
          {t("newGift")}
        </h1>

        <div className="mt-4 flex flex-col w-full">
          {error && (
            <div className="my-4">
              <ErrorAlert
                message={t("addNewGiftError")}
                close={handleCloseMessage}
              />
            </div>
          )}
          <div className="py-8 px-4 bg-white shadow-lg sm:rounded-lg sm:px-10">
            <Formik
              initialValues={{
                image: "",
                arabicDescription: "",
                englishDescription: "",
                accountType: "",
                default: "",
                targetPoints: "",
              }}
              validationSchema={newGiftSchema}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values);
                resetForm({ values: "" });
              }}
            >
              {({ touched, errors, values, setFieldValue, setTouched }) => (
                <Form>
                  <div className="flex justify-center items-center">
                    <div
                      className={`mt-3 px-6 py-6 border-2 border-dashed rounded-md ${
                        touched.image && errors.image
                          ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                          : "focus:ring-primary focus:border-primary border-gray-300"
                      }`}
                    >
                      <div className="space-y-1 text-center">
                        {values.image ? (
                          <div className="w-60 h-60">
                            <PreviewImage file={values.image} />
                          </div>
                        ) : (
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                        <div className="flex justify-between item-center">
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="image"
                              className="relative cursor-pointer rounded-md font-medium text-gray-500 hover:text-secondary focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-secondary"
                            >
                              <span>{t("uploadImage")}</span>
                              <input
                                id="image"
                                name="image"
                                type="file"
                                className="sr-only"
                                onChange={(event) => {
                                  setTouched({
                                    ...touched,
                                    thumbnail: true,
                                  });
                                  setFieldValue("image", event.target.files[0]);
                                }}
                              />
                            </label>
                          </div>
                          <div className="text-xs text-gray-500">
                            {values.image ? (
                              <button
                                className="relative cursor-pointer rounded-md font-medium text-gray-500 hover:text-secondary focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-secondary"
                                onClick={() => {
                                  setFieldValue("image", null);
                                }}
                              >
                                {t("remove")}
                              </button>
                            ) : (
                              <div className="mx-4">
                                PNG, JPG, GIF - up to 2MB
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-6 gap-6 mt-4">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="arabicDescription"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("arabicDescription")}
                      </label>
                      <Field
                        type="text"
                        as="textarea"
                        name="arabicDescription"
                        id="arabicDescription"
                        className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                          touched.arabicDescription && errors.arabicDescription
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      />
                      <ErrorMessage
                        name="arabicDescription"
                        component="p"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="englishDescription"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("englishDescription")}
                      </label>
                      <Field
                        type="text"
                        as="textarea"
                        name="englishDescription"
                        id="englishDescription"
                        className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                          touched.englishDescription &&
                          errors.englishDescription
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      />
                      <ErrorMessage
                        name="englishDescription"
                        component="p"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="accountType"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("accountType")}
                      </label>
                      <Field
                        as="select"
                        id="accountType"
                        name="accountType"
                        className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                          touched.accountType && errors.accountType
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      >
                        <option
                          value=""
                          className="text-gray-400 hover:text-gray-500"
                        >
                          {t("select")}
                        </option>
                        <option value="institute">{t("institute")}</option>
                        <option value="family">{t("family")}</option>
                      </Field>
                      <ErrorMessage
                        name="accountType"
                        component="p"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="default"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("enabled")}
                      </label>
                      <Field
                        as="select"
                        id="default"
                        name="default"
                        className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                          touched.default && errors.default
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      >
                        <option
                          value=""
                          className="text-gray-400 hover:text-gray-500"
                        >
                          {t("select")}
                        </option>
                        <option value="true">{t("yes")}</option>
                        <option value="false">{t("no")}</option>
                      </Field>
                      <ErrorMessage
                        name="default"
                        component="p"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="targetPoints"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("targetPoints")}
                      </label>
                      <Field
                        type="text"
                        name="targetPoints"
                        id="targetPoints"
                        className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                          touched.targetPoint && errors.targetPoint
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      />
                      <ErrorMessage
                        name="targetPoints"
                        component="p"
                        className="text-red-800 text-xs"
                      />
                    </div>
                  </div>
                  <div className="px-4 pt-8 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-primary text-base font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                    >
                      {isSubmit ? (
                        <>
                          <ButtonLoader />
                          {t("save")}
                        </>
                      ) : (
                        t("save")
                      )}
                    </button>
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-8 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                      onClick={() => navigate(-1)}
                    >
                      {t("cancel")}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default AddGift;
