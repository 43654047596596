import * as contactsServices from "../../services/contacts.services";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/24/outline";
import Container from "../../components/Layout/Container";
import Layout from "../../components/Layout/Layout";
import { useDispatch } from "react-redux";
import { logout } from "../../slices/authSlice";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonLoader from "../../components/Loaders/ButtonLoader";
import PageLoader from "../../components/Loaders/PageLoader";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import ErrorAlert from "../../components/Alerts/ErrorAlert";

const Contacts = () => {
  const [contact, setContact] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();

  let dispatch = useDispatch();

  const ContactSchema = Yup.object().shape({
    email: Yup.string().email(t("emailNotValid")).required(t("emailRequired")),
    phoneNumber: Yup.string().required(t("phoneRequired")),
    telegram: Yup.string().optional(t("phoneRequired")),
    whatsApp: Yup.string().optional(t("phoneRequired")),
    facebook: Yup.string(),
    twitter: Yup.string(),
    instagram: Yup.string(),
    website: Yup.string(),
    instructions: Yup.string(),
  });

  const getContact = async () => {
    try {
      const response = await contactsServices.getAllContacts();
      if (response.status === 200) {
        setContact(response.data.contact);
      }
    } catch (error) {
      console.log("🚀 ~ file: Contacts.js:29 ~ getContact ~ error:", error);
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  useEffect(() => {
    getContact();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (values) => {
    setIsSubmit(true);
    contactsServices
      .updateContact(contact.id, values)
      .then((response) => {
        setIsSubmit(false);
        setSuccess(true);
      })
      .catch((error) => {
        setIsSubmit(false);
        if (error.response.status === 401) {
          dispatch(logout());
        } else {
          setError(true);
        }
      });
  };

  const handleCloseMessage = () => {
    if (error) setError(false);
    if (success) setSuccess(false);
  };

  return (
    <Layout>
      {/* Breadcrumb */}
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 md:px-8 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("contacts")}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <Container>
        <h1 className="flex-1 text-2xl font-bold text-gray-900">
          {t("contacts")}
        </h1>
        {error && (
          <div className="my-4">
            <ErrorAlert
              message={t("updateContactError")}
              close={handleCloseMessage}
            />
          </div>
        )}
        {success && (
          <SuccessAlert
            message={t("updateContactSuccess")}
            close={handleCloseMessage}
          />
        )}
        <div className="mt-4 flex flex-col w-full">
          <div className="py-8 px-4 bg-white shadow-lg sm:rounded-lg sm:px-10">
            {contact ? (
              <Formik
                enableReinitialize={true}
                initialValues={{
                  email: contact.email ?? "",
                  phoneNumber: contact.phoneNumber ?? "",
                  telegram: contact.telegram ?? "",
                  whatsApp: contact.whatsApp ?? "",
                  facebook: contact.facebook ?? "",
                  twitter: contact.twitter ?? "",
                  instagram: contact.instagram ?? "",
                  website: contact.website ?? "",
                  instructions: contact.instructions ?? "",
                }}
                validationSchema={ContactSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {({ touched, errors }) => (
                  <Form>
                    <div className="grid md:grid-cols-3 gap-3 sm:grid-cols-1 mt-5">
                      <div>
                        <label
                          htmlFor="email"
                          className="text-sm font-medium text-gray-700"
                        >
                          {t("email")}
                        </label>
                        <Field
                          type="text"
                          name="email"
                          id="email"
                          className={`shadow-sm w-full sm:text-sm rounded-md ${
                            touched.email && errors.email
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                          placeholder={t("email")}
                        />
                        <ErrorMessage
                          name="email"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="phoneNumber"
                          className="text-sm font-medium text-gray-700"
                        >
                          {t("phoneNumber")}
                        </label>
                        <Field
                          type="text"
                          name="phoneNumber"
                          id="phoneNumber"
                          className={`shadow-sm w-full sm:text-sm rounded-md ${
                            touched.phoneNumber && errors.phoneNumber
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                          placeholder={t("phoneNumber")}
                        />
                        <ErrorMessage
                          name="phoneNumber"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="telegram"
                          className="text-sm font-medium text-gray-700"
                        >
                          {t("telegram")}
                        </label>
                        <Field
                          type="text"
                          name="telegram"
                          id="telegram"
                          className={`shadow-sm w-full sm:text-sm rounded-md ${
                            touched.telegram && errors.telegram
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                          placeholder={t("telegram")}
                        />
                        <ErrorMessage
                          name="telegram"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                    </div>
                    <div className="grid md:grid-cols-3 gap-3 sm:grid-cols-1 mt-5">
                      <div>
                        <label
                          htmlFor="whatsApp"
                          className="text-sm font-medium text-gray-700"
                        >
                          {t("whatsApp")}
                        </label>
                        <Field
                          type="text"
                          name="whatsApp"
                          id="whatsApp"
                          className={`shadow-sm w-full sm:text-sm rounded-md ${
                            touched.whatsApp && errors.whatsApp
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                          placeholder={t("whatsApp")}
                        />
                        <ErrorMessage
                          name="whatsApp"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="facebook"
                          className="text-sm font-medium text-gray-700"
                        >
                          {t("facebook")}
                        </label>
                        <Field
                          type="text"
                          name="facebook"
                          id="facebook"
                          className={`shadow-sm w-full sm:text-sm rounded-md ${
                            touched.facebook && errors.facebook
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                          placeholder={t("facebook")}
                        />
                        <ErrorMessage
                          name="facebook"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="twitter"
                          className="text-sm font-medium text-gray-700"
                        >
                          {t("twitter")}
                        </label>
                        <Field
                          type="text"
                          name="twitter"
                          id="twitter"
                          className={`shadow-sm w-full sm:text-sm rounded-md ${
                            touched.twitter && errors.twitter
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                          placeholder={t("twitter")}
                        />
                        <ErrorMessage
                          name="twitter"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                    </div>
                    <div className="grid md:grid-cols-3 gap-3 sm:grid-cols-1 mt-5">
                      <div>
                        <label
                          htmlFor="instagram"
                          className="text-sm font-medium text-gray-700"
                        >
                          {t("instagram")}
                        </label>
                        <Field
                          type="text"
                          name="instagram"
                          id="instagram"
                          className={`shadow-sm w-full sm:text-sm rounded-md ${
                            touched.instagram && errors.instagram
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                          placeholder={t("instagram")}
                        />
                        <ErrorMessage
                          name="instagram"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="website"
                          className="text-sm font-medium text-gray-700"
                        >
                          {t("website")}
                        </label>
                        <Field
                          type="text"
                          name="website"
                          id="website"
                          className={`shadow-sm w-full sm:text-sm rounded-md ${
                            touched.website && errors.website
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                          placeholder={t("website")}
                        />
                        <ErrorMessage
                          name="website"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="instructions"
                          className="text-sm font-medium text-gray-700"
                        >
                          {t("instructions")}
                        </label>
                        <Field
                          type="text"
                          name="instructions"
                          id="instructions"
                          className={`shadow-sm w-full sm:text-sm rounded-md ${
                            touched.instructions && errors.instructions
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                          placeholder={t("instructions")}
                        />
                        <ErrorMessage
                          name="instructions"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                    </div>
                    <div className="px-4 pt-8 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-primary text-base font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                      >
                        {isSubmit ? (
                          <>
                            <ButtonLoader />
                            {t("save")}
                          </>
                        ) : (
                          t("save")
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <PageLoader />
            )}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Contacts;
