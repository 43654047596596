import { useState, useEffect } from "react";
import * as InstitutesServices from "../../services/institutes.services";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../slices/authSlice";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import PageLoader from "../Loaders/PageLoader";

const Students = () => {
  const [students, setStudents] = useState(null);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);

  const { t } = useTranslation();
  let { classRoomId } = useParams();
  let dispatch = useDispatch();

  const columns = [
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ltr:ml-4 rtl:mr-4">
          {t("profile")}
        </div>
      ),
      selector: (row) => (
        <div className="ltr:ml-4 rtl:mr-4">
          {row?.user.avatar === null ? (
            <div>
              <div className="flex justify-center items-center rounded-md bg-gray-300 h-8 w-8 border border-none">
                <span className="text-base font-semibold text-gray-700">S</span>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex justify-center items-center rounded-md bg-gray-900 h-8 w-8 border border-none">
                <span className="text-base font-semibold text-gray-100">S</span>
              </div>
            </div>
          )}
        </div>
      ),
      sortable: true,
      width: "100px",
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ltr:ml-4 rtl:mr-4">
          {t("firstName")}
        </div>
      ),
      selector: (row) => (
        <div className="ltr:ml-4 rtl:mr-4">{row?.user.firstName}</div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900">
          {t("midName")}
        </div>
      ),
      selector: (row) => row?.user.midName,
      sortable: true,
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900">{t("email")}</div>
      ),
      selector: (row) => row?.user.email,
      sortable: true,
    },
    {
      name: (
        <div className="text-sm font-semibold  text-gray-900">
          {t("gender")}
        </div>
      ),
      selector: (row) =>
        row.user?.gender === "Female" ? (
          <div>
            {window.localStorage.getItem("i18nextLng") === "en"
              ? "Female"
              : "انثى"}
          </div>
        ) : (
          <div>
            {window.localStorage.getItem("i18nextLng") === "en"
              ? "Male"
              : "ذكر"}
          </div>
        ),
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ">
          {t("birthDate")}
        </div>
      ),
      selector: (row) => (
        <>
          {row.user?.birthDate
            ? format(new Date(row.user?.birthDate), "MMM d, y")
            : "not found"}
        </>
      ),
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ">
          {t("createdAt")}
        </div>
      ),
      selector: (row) => (
        <>
          {row.createdAt
            ? format(new Date(row.createdAt), "MMM d, y, HH:mm aa")
            : "not found"}
        </>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ">
          {t("actions")}
        </div>
      ),
      selector: (row) => (
        <div className="flex space-x-6">
          <Link
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-secondary text-base font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
            to={`/champion/institutes/${row.classRoom.id}/grades/${row?.classRoom.instituteGradeId}/classrooms/${row?.classRoomId}/students/${row?.id}`}
          >
            {t("details")}
          </Link>
        </div>
      ),
      width: "150px",
    },
  ];

  const getStudents = async (page) => {
    setLoading(true);
    await InstitutesServices.getAllStudents(classRoomId, page, limit)
      .then((response) => {
        setStudents(response.data.students.rows);
        setCount(response.data.students.count);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  useEffect(() => {
    getStudents(1);
    // eslint-disable-next-line
  }, [classRoomId, dispatch]);

  const handlePageChange = (page) => {
    getStudents(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await InstitutesServices.getAllInstitutes(
        classRoomId,
        page,
        newPerPage
      );
      setStudents(response.data.students.rows);
      setLimit(newPerPage);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-8">
      {students ? (
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-bold leading-6 text-gray-900">
              {t("students")}
            </h3>
          </div>
          <div className="flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                {
                  <DataTable
                    columns={columns}
                    data={students}
                    progressPending={loading}
                    progressComponent={<PageLoader />}
                    pagination
                    paginationServer
                    paginationTotalRows={count}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    fixedHeader
                    direction={
                      window.localStorage.getItem("i18nextLng") === "en"
                        ? "ltr"
                        : "rtl"
                    }
                  />
                }
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

export default Students;
