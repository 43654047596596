const ButtonLoader = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="200px"
        height="200px"
        viewBox="0 0 100 100"
        className="animate-spin h-5 w-5 mr-3 text-white"
        // viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke="#fff"
          strokeWidth="7"
          r="32"
          strokeDasharray="150.79644737231007 52.26548245743669"
        ></circle>
      </svg>
    </div>
  );
};

export default ButtonLoader;
