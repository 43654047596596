import { useState, useEffect } from "react";
import * as reportServices from "../../../services/reports.services";
import { useDispatch } from "react-redux";
import { logout } from "../../../slices/authSlice";
import { useTranslation } from "react-i18next";
import PageLoader from "../../../components/Loaders/PageLoader";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const Table = ({ year, month, day, monthly }) => {
  const [institutes, setInstitutes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const { t } = useTranslation();
  let dispatch = useDispatch();

  const columns = [
    {
      name: t("user"),
      selector: (row) => (
        <div className="bg-gray-800 text-gray-50 px-3 py-1 rounded-md ">
          {row?.code}
        </div>
      ),
      sortable: true,
      cellExport: (row) => {
        return row?.code;
      },
    },
    {
      name: t("firstName"),
      selector: (row) => row?.firstName,
      sortable: true,
      cellExport: (row) => {
        return row?.firstName;
      },
    },
    {
      name: t("midName"),
      selector: (row) => row?.midName,
      cellExport: (row) => {
        return row?.midName;
      },
      sortable: true,
    },
    {
      name: t("userEmail"),
      selector: (row) => row?.email,
      cellExport: (row) => {
        return row?.email;
      },
    },

    {
      name: t("phoneNumber"),
      selector: (row) => (
        <>
          {row?.phoneNumber === "" || row?.phoneNumber === null ? (
            <div className="bg-gray-200 text-gray-800 px-3 py-1 rounded-md">
              {t("noPhoneNumber")}
            </div>
          ) : (
            <div>{row?.phoneNumber}</div>
          )}
        </>
      ),
      cellExport: (row) => {
        if (row?.phoneNumber === "" || row?.phoneNumber === null) {
          return t("noPhoneNumber");
        } else {
          return row?.phoneNumber;
        }
      },
    },
  ];

  const getInstitutes = async (page) => {
    if (monthly) {
      try {
        const response = await reportServices.getInstitutesListByMonth(
          year,
          month,
          page,
          limit
        );

        if (response.status === 200) {
          setInstitutes(response.data.users.rows);
          setCount(response.data.users.count);
        }
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(logout());
        }
      }
    } else {
      try {
        const response = await reportServices.getInstitutesListByDay(
          year,
          month,
          day,
          page,
          limit
        );

        if (response.status === 200) {
          setInstitutes(response.data.users.rows);
          setCount(response.data.users.count);
        }
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(logout());
        }
      }
    }
  };

  const handlePageChange = (page) => {
    getInstitutes(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    if (monthly) {
      try {
        const response = await reportServices.getInstitutesListByMonth(
          year,
          month,
          page,
          newPerPage
        );
        setInstitutes(response.data.users.rows);
        setLimit(newPerPage);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(logout());
        }
      }
    } else {
      try {
        const response = await reportServices.getInstitutesListByDay(
          year,
          month,
          day,
          page,
          newPerPage
        );
        setInstitutes(response.data.users.rows);
        setLimit(newPerPage);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(logout());
        }
      }
    }
  };

  useEffect(() => {
    getInstitutes(1);
    // eslint-disable-next-line
  }, [year, month, day]);

  const tableData = {
    columns,
    data: institutes,
  };

  return (
    <div className="mt-4">
      {institutes ? (
        <DataTableExtensions
          {...tableData}
          filter={false}
          print={false}
          exportHeaders
        >
          <DataTable
            columns={columns}
            data={institutes}
            progressPending={loading}
            progressComponent={<PageLoader />}
            pagination
            paginationServer
            paginationTotalRows={count}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            fixedHeader
            direction={
              window.localStorage.getItem("i18nextLng") === "en" ? "ltr" : "rtl"
            }
          />
        </DataTableExtensions>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

export default Table;
