import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import { Disclosure } from "@headlessui/react";
import {
  HomeIcon,
  UserIcon,
  BuildingOfficeIcon,
  UserGroupIcon,
  MegaphoneIcon,
  BellAlertIcon,
  Cog6ToothIcon,
  ReceiptPercentIcon,
  // CurrencyDollarIcon,
  // GiftIcon,
  // ListBulletIcon,
  ChatBubbleLeftRightIcon,
  InformationCircleIcon,
  ChartBarIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import logo from "../../assets/logo.svg";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SideBar({ sidebarOpen, setSidebarOpen }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const navigation = [
    { name: t("dashboard"), href: "/", icon: HomeIcon },
    {
      name: t("users"),
      href: "/champion/users/institutes",
      href2: "/champion/users/families",
      icon: UserIcon,
    },
    {
      name: t("institutes"),
      href: "/champion/institutes",
      icon: BuildingOfficeIcon,
    },
    { name: t("families"), href: "/champion/families", icon: UserGroupIcon },
    {
      name: t("settings"),
      href: "/champion/defaultsettings/gifts",
      href2: "/champion/defaultsettings/tasks",
      icon: Cog6ToothIcon,
    },
    { name: t("advertisements"), href: "/champion/ads", icon: MegaphoneIcon },
    {
      name: t("notifications"),
      href: "/champion/notifications/all",
      icon: BellAlertIcon,
    },
    {
      name: t("subscriptions"),
      href: "/champion/subscriptions",
      href2: "/champion/pricing",
      href3: "/champion/offers",
      icon: ReceiptPercentIcon,
    },
    {
      name: t("contacts"),
      href: "/champion/contacts",
      icon: ChatBubbleLeftRightIcon,
    },
    {
      name: t("copyRights"),
      href: "/champion/copyrights",
      icon: InformationCircleIcon,
    },
    {
      name: t("reports"),
      href: "/champion/reports/subscriptions",
      href2: "/champion/reports/pricing",
      href3: "/champion/reports/institutes",
      href4: "/champion/reports/families",
      href5: "/champion/reports/activeusers",
      icon: ChartBarIcon,
    },
  ];

  return (
    <>
      {/* Mobile menu */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative max-w-xs flex flex-col bg-gray-800 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-14 p-1">
                    <button
                      type="button"
                      className="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Close sidebar</span>
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <img className="h-12 w-auto" src={logo} alt="Whos champion" />
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                  <nav className="px-2">
                    <div className="space-y-1">
                      {navigation.map((item) => (
                        <div key={item.name}>
                          <Link
                            to={item.href}
                            className={classNames(
                              item.current
                                ? "bg-gray-900 text-white"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                              "group w-full p-3 rounded-md flex flex-col items-center text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            <item.icon
                              className={classNames(
                                item.current
                                  ? "text-gray-300"
                                  : "text-gray-400 group-hover:text-gray-300",
                                "mr-3 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            <span className="mt-2">{item.name}</span>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:fixed md:inset-y-0 md:flex lg:w-40">
        <div className="flex min-h-0 flex-1 flex-col">
          <div className="flex h-32 justify-center items-center bg-gray-900 px-4">
            <img className="h-16 w-auto" src={logo} alt="Whos champion" />
          </div>
          <div className="flex flex-1 flex-col overflow-y-auto bg-gray-800">
            <nav className="flex-1 px-2 py-4 overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-thumb-primary scrollbar-track-transparent">
              <div className="space-y-1">
                {navigation.map((item) => (
                  <div key={item.name}>
                    <Link
                      to={item.href}
                      className={classNames(
                        pathname === item.href ||
                          pathname === item.href2 ||
                          pathname === item.href3 ||
                          pathname === item.href4 ||
                          pathname === item.href5
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "group w-full p-3 flex flex-col items-center justify-center text-sm font-medium rounded-md"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? "text-gray-300"
                            : "text-gray-400 group-hover:text-gray-300",
                          "h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      <span className="mt-2">{item.name}</span>
                    </Link>
                  </div>
                ))}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
