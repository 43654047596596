import { useState, useEffect } from "react";
import * as reportServices from "../../../services/reports.services";
import { useDispatch } from "react-redux";
import { logout } from "../../../slices/authSlice";
import { Link } from "react-router-dom";
import {
  UserGroupIcon,
  BuildingOffice2Icon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import PageLoader from "../../../components/Loaders/PageLoader";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { format } from "date-fns";
import "react-data-table-component-extensions/dist/index.css";

const Table = ({ year, month, day, monthly }) => {
  const [subscriptions, setSubscriptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const { t } = useTranslation();
  let dispatch = useDispatch();

  const columns = [
    {
      name: t("status"),
      selector: (row) => {
        let currentDate = new Date();
        let fromDate = new Date(row.from);
        let toDate = new Date(row.to);
        if (currentDate >= fromDate && currentDate <= toDate) {
          return (
            <div className="bg-primary text-white px-3 py-1 rounded-md">
              {t("subscribed")}
            </div>
          );
        } else {
          return (
            <div className="bg-gray-200 text-gray-500 px-3 py-1 rounded-md">
              {t("expired")}
            </div>
          );
        }
      },
      cellExport: (row) => {
        let currentDate = new Date();
        let fromDate = new Date(row.from);
        let toDate = new Date(row.to);
        if (currentDate >= fromDate && currentDate <= toDate) {
          return t("subscribed");
        } else {
          return t("expired");
        }
      },
    },
    {
      name: t("accountName"),
      selector: (row) => (
        <>
          {row.user?.family && <div>{row.user?.family?.name}</div>}
          {row.user?.institute && <div>{row.user?.institute?.name}</div>}
        </>
      ),
      sortable: true,
      cellExport: (row) => {
        if (row.user?.family) {
          return row.user?.family?.name;
        } else if (row.user?.institute) {
          return row.user?.institute;
        }
      },
    },
    {
      name: t("price"),
      selector: (row) => (
        <>
          <div>
            {row.pricing?.price}
            {window.localStorage.getItem("i18nextLng") === "en" ? "SAR" : "ر.س"}
          </div>
        </>
      ),
      sortable: true,
      cellExport: (row) => {
        return row.pricing?.price;
      },
    },
    {
      name: t("accountType"),
      selector: (row) => (
        <>
          {row.user?.institute && (
            <div className=" py-1 rounded-md flex flex-row">
              <BuildingOffice2Icon className="h-6 w-6 text-secondary" />
              <span className="mt-1 mx-1">{t("institute")}</span>
            </div>
          )}
          {row.user?.family && (
            <div className=" py-1 rounded-md flex flex-row">
              <UserGroupIcon className="h-6 w-6 text-secondary" />
              <span className="mt-1 mx-1">{t("families")}</span>
            </div>
          )}
        </>
      ),
      cellExport: (row) => {
        if (row.user?.family) {
          return t("families");
        } else if (row.user?.institute) {
          return t("institute");
        }
      },
    },
    {
      name: t("from"),
      selector: (row) => (
        <div>
          {row.from ? format(new Date(row.from), "MMM d, y") : "not found"}
        </div>
      ),
      sortable: true,
      cellExport: (row) => {
        return format(new Date(row.from), "MMM d, y");
      },
    },
    {
      name: t("to"),
      selector: (row) => (
        <div>{row.to ? format(new Date(row.to), "MMM d, y") : "not found"}</div>
      ),
      sortable: true,
      cellExport: (row) => {
        return format(new Date(row.to), "MMM d, y");
      },
    },
    {
      name: t("actions"),
      selector: (row) => (
        <div className="flex space-x-6">
          <Link
            className="inline-flex items-center"
            to={`/champion/subscriptions/familymembers/${row.user?.family?.id}`}
          >
            <DocumentMagnifyingGlassIcon className="h-6 w-6 text-primary hover:text-secondary" />
          </Link>
        </div>
      ),
      width: "100px",
      cellExport: (row) => row.null,
    },
  ];

  const getSubscriptions = async (page) => {
    if (monthly) {
      try {
        const response = await reportServices.getSubscriptionsListByMonth(
          year,
          month,
          page,
          limit
        );
        if (response.status === 200) {
          setSubscriptions(response.data.subscriptions.rows);
          setCount(response.data.subscriptions.count);
        }
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(logout());
        }
      }
    } else {
      try {
        const response = await reportServices.getSubscriptionsListByDay(
          year,
          month,
          day,
          page,
          limit
        );

        if (response.status === 200) {
          setSubscriptions(response.data.subscriptions.rows);
          setCount(response.data.subscriptions.count);
        }
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(logout());
        }
      }
    }
  };

  const handlePageChange = (page) => {
    getSubscriptions(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    if (monthly) {
      try {
        const response = await reportServices.getSubscriptionsListByMonth(
          year,
          month,
          page,
          newPerPage
        );
        setSubscriptions(response.data.subscriptions.rows);
        setLimit(newPerPage);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(logout());
        }
      }
    } else {
      try {
        const response = await reportServices.getSubscriptionsListByDay(
          year,
          month,
          day,
          page,
          newPerPage
        );
        setSubscriptions(response.data.subscriptions.rows);
        setLimit(newPerPage);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(logout());
        }
      }
    }
  };

  useEffect(() => {
    getSubscriptions(1);
    // eslint-disable-next-line
  }, [year, month, day]);

  const tableData = {
    columns,
    data: subscriptions,
  };

  return (
    <div className="mt-4">
      {subscriptions ? (
        <DataTableExtensions
          {...tableData}
          filter={false}
          print={false}
          exportHeaders
        >
          <DataTable
            columns={columns}
            data={subscriptions}
            progressPending={loading}
            progressComponent={<PageLoader />}
            pagination
            paginationServer
            paginationTotalRows={count}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            fixedHeader
            direction={
              window.localStorage.getItem("i18nextLng") === "en" ? "ltr" : "rtl"
            }
          />
        </DataTableExtensions>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

export default Table;
