const English = {
  champion: "Whos champion admin",
  signinToAccount: "Sign in to your account",
  email: "Email address",
  password: "Password",
  forgotPassword: "Forgot your password?",
  signin: "Sign in",
  resetPassword: "Reset Password",
  enterEmail: " Enter your email.",
  confirm: "Confirm",
  updatePassword: "Update Password",
  code: "Code",
  newPass: "New password",
  confirmPass: "Confirm password",
  reset: "Reset Password",
  otpRequired: "OTP is required",
  passwordMin: "Password must be 3 characters at minimum",
  passwordRequired: "Password is required",
  passwordMatch: "Passwords must match",
  confirmPasswordRequired: "Confirm password is required",
  emailNotValid: "Email is not valid",
  emailRequired: "Email is required",
  institutes: "Institutes",
  families: "Families",
  users: "Users",
  subscriptions: "Subscriptions",
  title: "Title",
  description: "Description",
  displayType: "Display Type",
  detailed: "Detailed",
  summarized: "Summarized",
  period: "Period",
  targetUser: "Target User",
  institute: "Institute",
  family: "Family",
  price: "Price",
  actions: "Actions",
  pricingList: "Pricing list",
  dashboard: "Dashboard",
  insights: "Insights",
  pricing: "Pricing",
  activeUsers: "Active Users",
  reports: "Reports",
  copyRights: "Copy Rights",
  contacts: "Contacts",
  settings: "Settings",
  gifts: "Gifts",
  tasks: "Tasks",
  offers: "Offers",
  notifications: "Notifications",
  toAll: "Send To All",
  toMultiple: "Send To Multiple",
  advertisements: "Advertisements",
  createdAt: "Created At",
  phoneNumber: "Phone Number",
  user: "User",
  firstName: "First Name",
  midName: "Middle Name",
  userEmail: "EMAIL",
  noPhoneNumber: "No phone number",
  instituteUsersList: "Institutes users list",
  newUser: "New User",
  home: "Home",
  familiesUsersList: "Families users list",
  search: "Search",
  addInstituteUser: "Add New Institute User",
  phoneRequired: "Phone number is required",
  phoneNotValid: "Phone number is not valid",
  nameRequired: "Name is required",
  save: "Save",
  cancel: "Cancel",
  editUser: "Edit User",
  roleRequired: "Role is required",
  createUserError: "Failed to create a new user",
  updateUserError: "Failed to update a user",
  editinstitutesUser: "Edit Institutes User",
  genderRequired: "Gender is required",
  deleteUser: "Delete User",
  areYouSureWantTo: "Are you sure you want to delete?",
  delete: "Delete",
  deleteUserError: "Failed to delete a user",
  deleteUserSuccess: "User successfully deleted",
  userDetails: "User Details",
  userInformation: "User Information",
  personalDetails: "Personal details and information.",
  b6Code: "B6l code",
  fullName: "Full Name",
  birthDate: "Birth Date",
  noBirthDate: "No birth date available",
  gender: "Gender",
  sendNotification: "Send Notification",
  characterNumber: "Title must be at least 3 characters",
  titleRequired: "Title is required",
  messageRequired: "Message is required",
  sendNotificationTo: "Send notification to",
  arabicTitle: "Arabic Title",
  englishTitle: "English Title",
  arabicBody: "Arabic Content",
  englishBody: "English Content",
  send: "Send",
  sendNotificationError: "Failed to send notification",
  addNewGiftError: "Failed to add new gift",
  sendNotificationSuccess: "Notification successfully send",
  instituteDetails: "Institute Details",
  female: "Female",
  male: "Male",
  select: "Select",
  logout: "Logout",
  newInstitutes: "Newly added institutes",
  newFamilies: "Newly added families",
  newUsers: "Newly added users",
  amountPerMonth: "Amount per month",
  newAdded: "New added",
  editfamiliesUser: "Edit families users",
  name: "Name",
  address: "Address",
  admin: "Admin",
  details: "Details",
  englishName: "English Name",
  arabicName: "Arabic Name",
  id: "ID",
  grades: "Grades",
  gradename: "Grade Name",
  teachers: "Teachers",
  classRooms: "Class Rooms",
  classRoomsDetails: "Class room details",
  profile: "Profile",
  students: "Students",
  subject: "Subject",
  homeWorks: "Homeworks",
  from: "From",
  to: "To",
  status: "Status",
  teacher: "Teacher",
  student: "Student",
  studentNoteDetails: "Student note details",
  institutesList: "Institutes List",
  familiesList: "Families List",
  familyMembers: "Family Members",
  familyName: "Family Name",
  relevance: "Relevance",
  noRelevance: "Relevance not specified",
  defaultSettings: "Default settings",
  defaultGiftsList: "Default Gifts List",
  newGift: "New gift",
  photo: "Photo",
  targetPoints: "Target Points",
  accountType: "Account Type",
  enabled: "Enabled",
  yes: "Yes",
  no: "No",
  uploadImage: "Upload image",
  arabicDescription: "Arabic Description",
  englishDescription: "English Description",
  remove: "Remove",
  deleteGiftError: "Failed to delete gift",
  deleteGiftSuccess: "Gift successfully deleted",
  editGift: "Update gift",
  deleteGift: "Delete gift",
  editGiftError: "Failed to edit gift",
  deleteTaskError: "Failed to delete task",
  deleteTaskSuccess: "Task successfully deleted",
  editTask: "Update task",
  deleteTask: "Delete task",
  editTaskError: "Failed to edit task",
  defaultTasksList: "Default Tasks List",
  newTask: "New task",
  addNewTaskError: "Failed to add new task",
  ads: "Ads",
  url: "URL",
  updateAdsSuccess: "Update Ads Success",
  updateAdsError: "Failed to update ads",
  sendNotificationToAll: "Send notification to all users",
  sendNotificationToMulti: "Send notification to multiple users",
  selectUsersRequired: "Select users required",
  selectUsers: "Select users",
  subscriptionsAndOffers: "Subscriptions & Offers",
  subscriptionsList: "Subscriptions list",
  subscribed: "Subscribed",
  expired: "Expired",
  returnHome: "Return Home",
  sorryThisPageNotFound:
    "Sorry, the page you are looking for could not be found.",
  pageNotFound: "Page Not Found",
  newPricing: "New pricing",
  appleProductIdRequired: "Apple product id is required",
  priceRequired: "price is required",
  periodRequired: "Period is required",
  arabicDescriptionRequired: "Arabic description is required",
  englishDescriptionRequired: "English description is required",
  intervalRequired: "Time interval is required",
  targetUserRequired: "Target users is required",
  summarizedRequired: "Display type is required",
  specialRequired: "Design type is required",
  appleProductId: "Apple productId",
  units: "Units",
  month: "Month",
  year: "Year",
  interval: "Time unit",
  specialDesign: "Special design",
  normalDesign: "Normal design",
  designType: "Design type",
  addPricingError: "Failed to add new price",
  deletePricingError: "Failed to delete pricing",
  deletePricingSuccess: "Pricing successfully deleted",
  deletePricing: "Delete pricing",
  updatePricing: "Update pricing",
  updatePricingError: "Failed to update pricing",
  offersList: "Offers list",
  newOffer: "New offer",
  offerType: "Offer Type",
  discount: "Discount",
  extraUnit: "Extra units",
  deleteOffer: "Delete offer",
  deleteOfferError: "Failed to delete offer",
  deleteOfferSuccess: "Offer deleted successfully",
  typeRequired: "Offer type is required",
  discountRequired: "Discount price is required",
  rangeRequired: "Extra unit quantity is required",
  fromRequired: "From date is required",
  toRequired: "To date is required",
  pricingRequired: "Pricing is required",
  editOffer: "Edit offer",
  addOfferError: "Failed to add new offer",
  updateOfferError: "Failed to update offer",
  telegram: "Telegram",
  whatsApp: "Whatsapp",
  facebook: "Facebook",
  twitter: "Twitter",
  instagram: "Instagram",
  website: "Website url",
  instructions: "Instractions page url",
  updateContactError: "Failed to update contact",
  updateContactSuccess: "Contact updated successfully",
  yearRequired: "Year is required",
  updateRightsError: "Failed to update copy rights",
  updateRightsSuccess: "Copy rights updated successfully",
  subscriptionsReport: "Subscriptions report",
  pricingReport: "Pricing report",
  familiesReport: "Families report",
  activeUsersReport: "Active users report",
  institutesReport: "Institutes report",
  expiredSubscriptions: "Expired subscriptions",
  newSubscriptions: "New subscriptions",
  selectYear: "Select year",
  selectMonth: "Select month",
  selectDayOrMonth: "Select a day or a month to display data!",
  showMonthSubscriptions: "Show subscriptions data for month:",
  showDaySubscriptions: "Show subscriptions data for day:",
  subscriptionDate: "Subscription Date",
  accountName: "Account Name",
  notFound: "Not found",
  familiesPricings: "Families pricings",
  institutesPricings: "Institutes pricings",
  newRegistrations: "New registrations",
  showMonthInstitutes: "Show institutes data for month:",
  showDayInstitutes: "Show institutes data for day:",
  showMonthFamilies: "Show families data for month:",
  showDayFamilies: "Show families data for day:",
  showMonthActiveUsers: "Show active users data for month:",
  showDayActiveUsers: "Show active users data for day:",
};

export default English;
