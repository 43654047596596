import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UserGroupIcon } from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import * as reportsServices from "../../../services/reports.services";
import { logout } from "../../../slices/authSlice";
import ApexCharts from "apexcharts";
import PageLoader from "../../Loaders/PageLoader";

const Families = () => {
  const [pricings, setPricings] = useState(null);
  //   const [subscriptions, setSubscriptions] = useState(null);
  //   const [pricingId, setPricingId] = useState(null);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    const getPricing = async () => {
      await reportsServices
        .getFamiliesPricings()
        .then((response) => {
          let pricesArray = [];
          let subscription = [];
          let familiesPiricingsId = [];
          response.data.prices.forEach((pricing) => {
            pricesArray.push(pricing?.price);
            subscription.push(pricing?.subscriptions);
            familiesPiricingsId.push(pricing?.priceId);
          });
          //   setSubscriptions(subscription);
          setPricings(pricesArray);
          //   setPricingId(familiesPiricingsId);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.responseCode === 401) {
            dispatch(logout());
          }
        });
    };
    getPricing();
    // eslint-disable-next-line
  }, [dispatch]);

  const getFamilyChartOptions = () => {
    var options = {
      series: pricings,
      chart: {
        width: 380,
        type: "donut",
      },
      labels: pricings,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      colors: ["rgb(162, 28, 175)", "rgb(29, 78, 216)", "rgb(190, 18, 60)"],
    };
    return options;
  };

  useEffect(() => {
    if (!loading && pricings) {
      let element = document.getElementById("families_pricing");

      let options = getFamilyChartOptions();
      let chart = new ApexCharts(element, options);
      chart.render();
      return function cleanUp() {
        chart.destroy();
      };
    }
    // eslint-disable-next-line
  }, [loading]);

  return (
    <div>
      <div className="rounded-tl-lg rounded-bl-lg relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500">
        <div className="flex flex-row">
          <span className="text-sky-700 bg-amber-50 rounded-lg inline-flex p-3 ring-4 ring-white">
            <UserGroupIcon className="h-6 w-6" aria-hidden="true" />
          </span>
          <h3 className="text-lg font-medium mt-3 mx-2">
            <Link to={"#"} className="focus:outline-none">
              <span className="absolute inset-0" aria-hidden="true" />
              {t("familiesPricings")}
            </Link>
          </h3>
        </div>
        <div className="mt-6 flex justify-center items-center">
          {loading && <PageLoader />}
          <div id="families_pricing"></div>
        </div>
      </div>
    </div>
  );
};

export default Families;
