import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  Bars3BottomLeftIcon,
  // ChevronDownIcon,
  UserIcon,
  ArrowRightOnRectangleIcon,
  // ArrowLeftOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { logout } from "../../slices/authSlice";

export default function Header({ setSidebarOpen }) {
  const { t, i18n } = useTranslation();
  let dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));

  const logOut = () => {
    dispatch(logout());
  };
  return (
    <>
      <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-900 md:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex flex-1 justify-between px-4 sm:px-6">
          <div className="flex flex-1">
            <form className="flex w-full ltr:md:ml-0 rtl:md:mr-0">
              <label htmlFor="search-field" className="sr-only">
                {t("search")}
              </label>
              <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                <div className="pointer-events-none absolute inset-y-0 ltr:left-0 rtl:right-0 flex items-center">
                  <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search-field"
                  className="block h-full w-full border-transparent py-2 ltr:pl-8 rtl:pr-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
                  placeholder={t("search")}
                  type="search"
                  name="search"
                />
              </div>
            </form>
          </div>

          <div className="ml-4 flex items-center md:ml-6">
            {window.localStorage.getItem("i18nextLng") === "en" && (
              <button
                type="button"
                className="flex justify-center items-center rounded-md bg-white border border-gray-400 p-4 h-6 w-6"
                value={i18n.language}
                onClick={(e) => {
                  i18n.changeLanguage("ar");
                }}
              >
                <span className="text-base text-primary hover:text-secondary">
                  Ar
                </span>
              </button>
            )}

            {window.localStorage.getItem("i18nextLng") === "ar" && (
              <button
                type="button"
                className="flex justify-center items-center rounded-md bg-white border border-gray-400 p-4 h-6 w-6"
                value={i18n.language}
                onClick={(e) => {
                  i18n.changeLanguage("en");
                }}
              >
                <span className="h-6 w-6 text-primary">EN</span>
              </button>
            )}
          </div>
          <div className="ml-4 flex items-center md:ml-6">
            <Menu as="div" className="relative flex-shrink-0">
              <div>
                {window.localStorage.getItem("i18nextLng") === "en" && (
                  <Menu.Button className="flex flex-row justify-center items-center rounded-md bg-white ml-2 hover:text-gray-600">
                    <span className="sr-only">Open user menu</span>
                    <UserIcon className="h-6 w-6 text-gray-500" />
                    <span className="text-base text-gray-500 hover:text-primary">
                      {user?.firstName + " " + user?.midName}
                    </span>
                  </Menu.Button>
                )}
                {window.localStorage.getItem("i18nextLng") === "ar" && (
                  <Menu.Button className="flex flex-row justify-center items-center rounded-md bg-white ml-2 hover:text-gray-600">
                    <span className="sr-only">Open user menu</span>
                    <span className="text-base text-gray-500 hover:text-primary">
                      {user?.firstName + " " + user?.midName}
                    </span>
                    <UserIcon className="h-6 w-6 text-gray-500" />
                  </Menu.Button>
                )}
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute ltr:right-0 rtl:left-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    <button
                      onClick={logOut}
                      className="block px-4 py-2 text-sm text-gray-700 hover:text-gray-500"
                    >
                      <div className="flex">
                        <ArrowRightOnRectangleIcon className="h-5 w-5 text-gray-500 hover:text-primary" />
                        {/* <ArrowLeftOnRectangleIcon className="h-5 w-5 text-gray-500 hover:text-primary" /> */}

                        {t("logout")}
                      </div>
                    </button>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
}
