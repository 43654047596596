import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useNavigate } from "react-router-dom";
import Container from "../../../components/Layout/Container";
import Layout from "../../../components/Layout/Layout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonLoader from "../../../components/Loaders/ButtonLoader";
import * as familiesServices from "../../../services/users/families.services";
import { useDispatch } from "react-redux";
import { logout } from "../../../slices/authSlice";
import ErrorAlert from "../../../components/Alerts/ErrorAlert";
import PageLoader from "../../../components/Loaders/PageLoader";
import { HomeIcon } from "@heroicons/react/24/outline";

const EditFamiliesUser = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(false);

  const { t } = useTranslation();
  const { id } = useParams();

  let navigate = useNavigate();
  let dispatch = useDispatch();

  const createSchema = Yup.object().shape({
    firstName: Yup.string().required(t("nameRequired")),
    midName: Yup.string().required(t("nameRequired")),
    email: Yup.string().email(t("emailNotValid")).required(t("emailRequired")),
    phoneNumber: Yup.string(),
    gender: Yup.string().required(t("genderRequired")),
  });

  const handleSubmit = (values) => {
    setIsSubmit(true);
    familiesServices
      .updateFamilyAdmin({
        ...values,
        id: id,
        role: "USER",
      })
      .then((response) => {
        setIsSubmit(false);
        navigate("/champion/users/families");
      })
      .catch((error) => {
        setIsSubmit(false);
        console.log(
          "🚀 ~ file: EditUser.js:29 ~ InstitutesServices.updateInstituteAdmin ~ error",
          error
        );
        if (error.response.status === 401) {
          dispatch(logout());
        } else {
          setError(true);
        }
      });
  };

  useEffect(() => {
    const getById = async () => {
      await familiesServices
        .getFamilyAdminById(id)
        .then((response) => {
          console.log("🚀 ~ file: EditUser.js:46 ~ .then ~ response", response);
          setUser(response.data.user);
        })
        .catch((error) => {
          console.log("🚀 ~ file: EditUser.js:50 ~ useEffect ~ error", error);
        });
    };
    getById();
  }, [id]);

  const handleCloseMessage = () => {
    setError(false);
  };

  return (
    <Layout>
      {/* Breadcrumb */}
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500">
                {t("users")}
              </p>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to={"/champion/users/families"}
                className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500 hover:text-secondary"
              >
                {t("families")}
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("editfamiliesUser")}
              </p>
            </div>
          </li>
        </ol>
      </nav>

      <Container>
        <h1 className="flex-1 text-2xl font-bold text-gray-900">
          {t("editUser")}
        </h1>
        <div className="mt-4 flex flex-col w-full">
          {error && (
            <div className="my-4">
              <ErrorAlert
                message={t("updateUserError")}
                close={handleCloseMessage}
              />
            </div>
          )}
          {user ? (
            <div className="py-8 px-4 bg-white shadow-lg sm:rounded-lg sm:px-10">
              <Formik
                initialValues={{
                  firstName: user?.firstName,
                  midName: user?.midName,
                  email: user?.email,
                  phoneNumber: user?.phoneNumber,
                  gender: user?.gender,
                }}
                validationSchema={createSchema}
                onSubmit={(values, { resetForm }) => {
                  handleSubmit(values);
                  resetForm({ values: "" });
                }}
              >
                {({ touched, errors }) => (
                  <Form>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="firstName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t("firstName")}
                        </label>
                        <Field
                          type="text"
                          name="firstName"
                          id="firstName"
                          className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                            touched.firstName && errors.firstName
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                        />
                        <ErrorMessage
                          name="firstName"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="midName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t("midName")}
                        </label>
                        <Field
                          type="text"
                          name="midName"
                          id="midName"
                          className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                            touched.midName && errors.midName
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                        />
                        <ErrorMessage
                          name="midName"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t("email")}
                        </label>
                        <Field
                          type="text"
                          name="email"
                          id="email"
                          className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                            touched.email && errors.email
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                        />
                        <ErrorMessage
                          name="email"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="phoneNumber"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t("phoneNumber")}
                        </label>
                        <Field
                          type="text"
                          name="phoneNumber"
                          id="phoneNumber"
                          className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                            touched.phoneNumber && errors.phoneNumber
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                        />
                        {/* <ErrorMessage
                        name="phoneNumber"
                        component="p"
                        className="text-red-800 text-xs"
                      /> */}
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="gender"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t("gender")}
                        </label>
                        <Field
                          as="select"
                          id="gender"
                          name="gender"
                          className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                            touched.gender && errors.gender
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                        >
                          <option value="female">{t("female")}</option>
                          <option value="male">{t("male")}</option>
                        </Field>
                        <ErrorMessage
                          name="gender"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                    </div>
                    <div className="px-4 py-8 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-primary text-base font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                      >
                        {isSubmit ? (
                          <>
                            <ButtonLoader />
                            {t("save")}
                          </>
                        ) : (
                          t("save")
                        )}
                      </button>
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-8 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                        onClick={() => navigate(-1)}
                      >
                        {t("cancel")}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            <PageLoader />
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default EditFamiliesUser;
