import axios from "axios";
import * as headers from "./auth-header";

const apiUrl = process.env.REACT_APP_API_URL;

export function getAllFamilies(page, limit) {
  return axios.get(apiUrl + `family/all/${page}/${limit}`, {
    headers: headers.authHeader(),
  });
}

export function getFamilyById(familyId) {
  return axios.get(apiUrl + `family/${familyId}`, {
    headers: headers.authHeader(),
  });
}
