import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Insights from "../components/Dasboard/Insights";
import * as StatsServices from "../services/statistics.services";
import Layout from "../components/Layout/Layout";
import Container from "../components/Layout/Container";
import InstitutesChart from "../components/Dasboard/StatsCharts/InstitutesChart";
import PageLoader from "../components/Loaders/PageLoader";
import FamiliesChart from "../components/Dasboard/StatsCharts/FamiliesChart";
import SubscriptionsChart from "../components/Dasboard/StatsCharts/SubscriptionsChart";
import { useDispatch } from "react-redux";
import { logout } from "../slices/authSlice";

export default function Home() {
  const [institute, setInstitute] = useState(null);
  const [family, setFamily] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);

  const { t } = useTranslation();
  let dispatch = useDispatch();

  useEffect(() => {
    const GetInstitutesStats = async () => {
      await StatsServices.getInstituteStats()
        .then((response) => {
          setInstitute(response.data.stats);
        })
        .catch((error) => {
          console.log(
            "🚀 ~ file: Home.js:42 ~ GetInstitutesStats ~ error",
            error
          );
          if (error.response.status === 401) {
            dispatch(logout());
          }
        });
    };
    GetInstitutesStats();
  }, [dispatch]);

  useEffect(() => {
    const GetFamiliesStats = async () => {
      await StatsServices.getFamilyStats()
        .then((response) => {
          setFamily(response.data.stats);
        })
        .catch((error) => {
          console.log(
            "🚀 ~ file: Home.js:52 ~ GetFamiliesStats ~ error",
            error
          );
          if (error.response.status === 401) {
            dispatch(logout());
          }
        });
    };
    GetFamiliesStats();
  }, [dispatch]);

  useEffect(() => {
    const GetSubscriptionStats = async () => {
      await StatsServices.getSubscriptionStats()
        .then((response) => {
          setSubscriptions(response.data.stats);
        })
        .catch((error) => {
          console.log(
            "🚀 ~ file: StatisticsFunctions.js:10 ~ GetSubscriptionStats ~ error",
            error
          );
          if (error.response.status === 401) {
            dispatch(logout());
          }
        });
    };
    GetSubscriptionStats();
  }, [dispatch]);

  return (
    <>
      <Layout>
        <Container>
          <Insights />

          <div className="mt-12 flex">
            <h1 className="my-4 flex-1 text-2xl font-bold text-gray-900">
              {t("dashboard")}
            </h1>
          </div>
          <div className="mx-auto max-w-7xl">
            <div className="border shadow-md rounded-lg">
              {subscriptions ? (
                <SubscriptionsChart data={subscriptions} />
              ) : (
                <PageLoader />
              )}
            </div>
            <div className="mt-8 border rounded-lg shadow-md sm:grid sm:grid-cols-2 sm:gap-px">
              {institute ? (
                <InstitutesChart data={institute} />
              ) : (
                <PageLoader />
              )}
              {family ? <FamiliesChart data={family} /> : <PageLoader />}
            </div>
          </div>
        </Container>
      </Layout>
    </>
  );
}
