import React from "react";
import { Link } from "react-router-dom";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import ReactApexChart from "react-apexcharts";
import { format } from "date-fns";

const InstitutesChart = ({ data }) => {
  const { t } = useTranslation();
  const instituteCount = data.map((data) => data.count);
  const instituteMonth = data.map((data) =>
    format(new Date(data.month), "MMM")
  );

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: instituteMonth,
    },
  };
  const series = [
    {
      name: t("newAdded"),
      data: instituteCount,
    },
  ];

  return (
    <div>
      <div className="rounded-tl-lg rounded-bl-lg relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500">
        <div>
          <span className="text-purple-700 bg-rose-50 rounded-lg inline-flex p-3 ring-4 ring-white">
            <BuildingOfficeIcon className="h-6 w-6" aria-hidden="true" />
          </span>
        </div>
        <h3 className="text-lg font-medium mt-3">
          <Link to={"#"} className="focus:outline-none">
            <span className="absolute inset-0" aria-hidden="true" />
            {t("newInstitutes")}
            {" " + data[0].count}
          </Link>
        </h3>
        <div className="mt-8">
          <ReactApexChart options={options} series={series} type="area" />
        </div>
      </div>
    </div>
  );
};

export default InstitutesChart;
