import { useState } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
// import Container from "./Container";
// import Breadcrumb from "../Breadcrumb";

const Layout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div>
      <div className="flex min-h-full">
        <SideBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="flex w-0 flex-1 flex-col ltr:md:pl-40 rtl:md:pr-40">
          <Header setSidebarOpen={setSidebarOpen} />
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
