import { XCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";

export default function ErrorAlert({ message, close }) {
  return (
    <div className="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
      <div className="w-1/2 pointer-events-auto flex items-center justify-between gap-x-6 bg-gray-800 py-2.5 px-6 sm:rounded-xl sm:py-3 sm:pr-3.5 sm:pl-4">
        <div className="text-sm leading-6 text-white">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon className="h-5 w-5 text-white mr-2" />
            </div>
            {message}
          </div>
        </div>
        <button
          type="button"
          onClick={close}
          className="-m-1.5 flex-none p-1.5"
        >
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}
