import axios from "axios";
import * as headers from "../auth-header";

const apiUrl = process.env.REACT_APP_API_URL;

export function createTask(task) {
  let formData = new FormData();
  formData.append("accountType", task.accountType);
  formData.append("arabicDescription", task.arabicDescription);
  formData.append("englishDescription", task.englishDescription);
  formData.append("default", task.default);
  formData.append("image", task.image);
  return axios.post(apiUrl + "config/task", formData, {
    headers: headers.authHeaderWithMultipart(),
  });
}

export function getAllTasks() {
  return axios.get(apiUrl + "config/tasks", {
    headers: headers.authHeader(),
  });
}

export function getTaskById(taskId) {
  return axios.get(apiUrl + `config/task/${taskId}`, {
    headers: headers.authHeader(),
  });
}

export function updateTask(task) {
  let formData = new FormData();
  formData.append("id", task.id);
  formData.append("accountType", task.accountType);
  formData.append("arabicDescription", task.arabicDescription);
  formData.append("englishDescription", task.englishDescription);
  formData.append("default", task.default);
  formData.append("image", task.image);
  return axios.put(apiUrl + "config/task", formData, {
    headers: headers.authHeaderWithMultipart(),
  });
}

export function deleteTask(taskId) {
  return axios.delete(apiUrl + `config/task/${taskId}`, {
    headers: headers.authHeader(),
  });
}
