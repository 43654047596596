import { useEffect, useState } from "react";
import Container from "../../components/Layout/Container";
import Layout from "../../components/Layout/Layout";
import ReportsTab from "../../components/Tabs/ReportsTab";
import { Link } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import PageLoader from "../../components/Loaders/PageLoader";
import ApexCharts from "apexcharts";
import * as reportServices from "../../services/reports.services";
import { useDispatch } from "react-redux";
import { logout } from "../../slices/authSlice";
import Table from "../../components/Reports/Subscriptions/Table";

const Subscriptions = () => {
  const { t } = useTranslation();

  let currentYear = new Date().getFullYear();
  let currentMonth = new Date().getMonth();
  let years = [currentYear, currentYear - 1, currentYear - 2];
  let dispatch = useDispatch();

  const [categories, setCategories] = useState(null);
  const [expired, setExpired] = useState(null);
  const [newSubscriptions, setNewSubscriptions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const monthsEn = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthsAr = [
    "يناير",
    "فبراير",
    "مارس",
    "أبريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];
  const serverMonths = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
  ];
  const serverDays = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];

  const [selectedYear, setSelectedYear] = useState(currentYear);
  // eslint-disable-next-line
  const [months, setMonths] = useState(
    window.localStorage.getItem("i18nextLng") === "en"
      ? [...monthsEn, "All year"]
      : [...monthsAr, "كل السنة"]
  );

  const [selectedMonthLabel, setSelectedMonthLabel] = useState(
    months[currentMonth]
  );
  const [selectedServerMonth, setSelectedServerMonth] = useState(
    serverMonths[currentMonth]
  );
  const [tableShown, setTableShown] = useState(false);
  const [tableYear, setTableYear] = useState(currentYear);
  const [tableMonth, setTableMonth] = useState(serverMonths[currentMonth]);
  const [tableDay, setTableDay] = useState(serverDays[0]);
  // const { monthList, expired, newSubscriptions } =
  //   GetYearlySubscriptions(selectedYear);

  // const { daysList, expiredM, newSubscriptionsM } = GetMonthlySubscriptions(
  // selectedYear,
  // selectedServerMonth
  // );

  useEffect(() => {
    setIsLoading(true);
    if (selectedServerMonth === "13") {
      reportServices
        .getSubscriptionsYearly(selectedYear)
        .then((response) => {
          setCategories(response.data.months);
          setNewSubscriptions(response.data.subscriptions_added);
          setExpired(response.data.subscriptions_finished);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.status === 401) {
            dispatch(logout());
          }
        });
    } else {
      reportServices
        .getSubscriptionsMonthly(selectedYear, selectedServerMonth)
        .then((response) => {
          setCategories(response.data.days);
          setNewSubscriptions(response.data.subscriptions_added);
          setExpired(response.data.subscriptions_finished);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response.status === 401) {
            dispatch(logout());
          }
        });
    }

    // eslint-disable-next-line
  }, [dispatch, selectedYear, selectedServerMonth]);

  const ChartOptions = () => {
    var options = {
      series: [
        {
          name: t("expiredSubscriptions"),
          data: expired,
        },
        {
          name: t("newSubscriptions"),
          data: newSubscriptions,
        },
      ],
      chart: {
        type: "bar",
        height: 400,
        events: {
          dataPointSelection: (e, chart, opts) => {
            if (selectedServerMonth !== "13") {
              setTableDay(serverDays[opts.dataPointIndex]);
              setTableMonth(selectedServerMonth);
              setTableYear(selectedYear);
            } else {
              setTableMonth(serverMonths[opts.dataPointIndex]);
              setTableYear(selectedYear);
            }

            setTableShown(true);
          },
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        colors: ["transparent"],
      },
      xaxis: {
        categories: categories,
      },
      fill: {
        opacity: 3,
      },
      colors: ["#a10606", "#ffcc33"],
    };
    return options;
  };

  const resetMonths = () => {
    setSelectedMonthLabel(months[12]);
    setSelectedServerMonth(serverMonths[12]);
  };

  useEffect(() => {
    if (!isLoading && categories) {
      let element = document.getElementById("subscriptions_chart");

      let options = ChartOptions();
      let chart = new ApexCharts(element, options);
      chart.render();
      return function cleanUp() {
        chart.destroy();
      };
    }
    // eslint-disable-next-line
  }, [categories]);

  return (
    <Layout>
      {/* Breadcrumb */}
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 md:px-8 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500">
                {t("reports")}
              </p>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("subscriptionsReport")}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <ReportsTab />
      <Container>
        <div className="flex">
          <h1 className="flex-1 text-2xl font-bold text-gray-900">
            {t("subscriptionsReport")}
          </h1>
        </div>

        <div className="rounded-lg bg-white p-6 ">
          <div className="mx-auto max-w-7xl">
            <div className="border shadow-md rounded-lg p-6">
              <div className="flex flex-row gap-2">
                <div>
                  <label
                    htmlFor="year"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("selectYear")}
                  </label>
                  <select
                    id="year"
                    name="year"
                    className="mt-2 block w-24 rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-gray-600 sm:text-sm sm:leading-6"
                    value={selectedYear}
                    readOnly
                  >
                    {years.map((year) => {
                      return (
                        <option
                          key={year.toString()}
                          onClick={
                            year === selectedYear
                              ? () => {}
                              : () => {
                                  setSelectedYear(year);
                                  setTableShown(false);
                                  setTableYear(year);
                                  resetMonths();
                                }
                          }
                        >
                          {year}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="month"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("selectMonth")}
                  </label>
                  <select
                    id="month"
                    name="month"
                    className="mt-2 block w-28 rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-gray-600 sm:text-sm sm:leading-6"
                    value={selectedMonthLabel}
                    readOnly
                  >
                    {months.map((month, index) => {
                      if (selectedYear === currentYear) {
                        if (index === 12) {
                          return (
                            <option
                              key={month.toString()}
                              onClick={
                                month === selectedMonthLabel
                                  ? () => {}
                                  : () => {
                                      setSelectedMonthLabel(month);
                                      setSelectedServerMonth(
                                        serverMonths[index]
                                      );
                                      setTableShown(false);
                                    }
                              }
                            >
                              {month}
                            </option>
                          );
                        }
                        if (index <= currentMonth) {
                          return (
                            <option
                              key={month.toString()}
                              onClick={
                                month === selectedMonthLabel
                                  ? () => {}
                                  : () => {
                                      setSelectedMonthLabel(month);
                                      setSelectedServerMonth(
                                        serverMonths[index]
                                      );
                                      setTableShown(false);
                                    }
                              }
                            >
                              {month}
                            </option>
                          );
                        } else {
                          return null;
                        }
                      } else {
                        return (
                          <option
                            key={month.toString()}
                            onClick={
                              month === selectedMonthLabel
                                ? () => {}
                                : () => {
                                    setSelectedMonthLabel(month);
                                    setSelectedServerMonth(serverMonths[index]);
                                    setTableShown(false);
                                  }
                            }
                          >
                            {month}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
              </div>
              {isLoading ? (
                <PageLoader />
              ) : (
                <div id="subscriptions_chart"></div>
              )}

              <div>
                {tableShown ? (
                  <div>
                    <div className="flex mt-4">
                      <h1 className="flex-1 text-xl font-bold text-gray-800">
                        {selectedServerMonth === "13"
                          ? t("showMonthSubscriptions")
                          : t("showDaySubscriptions")}
                        <span className="text-primary m-2">
                          {selectedServerMonth === "13"
                            ? tableMonth
                            : selectedMonthLabel + " " + tableDay}
                        </span>
                      </h1>
                    </div>
                    <Table
                      year={tableYear}
                      month={tableMonth}
                      day={tableDay}
                      monthly={selectedServerMonth === "13" ? true : false}
                    />
                  </div>
                ) : (
                  <div className="flex justify-center items-center border-0 rounded-md text-primary text-sm w-full bg-gray-800 mt-4 p-2">
                    {t("selectDayOrMonth")}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Subscriptions;
