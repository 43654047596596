import { useState, useEffect, useRef } from "react";
import Container from "../../../components/Layout/Container";
import Layout from "../../../components/Layout/Layout";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import {
  HomeIcon,
  PencilSquareIcon,
  TrashIcon,
  PlusIcon,
  UserGroupIcon,
  BuildingOffice2Icon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import SettingsTab from "../../../components/Tabs/SettingsTab";
import { logout } from "../../../slices/authSlice";
import { useDispatch } from "react-redux";
import * as tasksServices from "../../../services/defaultSettings/tasks.services";
import PageLoader from "../../../components/Loaders/PageLoader";
import DeleteModal from "../../../components/modals/DeleteModal";
import ErrorAlert from "../../../components/Alerts/ErrorAlert";
import SuccessAlert from "../../../components/Alerts/SuccessAlert";

const TasksList = () => {
  const [tasks, setTasks] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentIdValue, setCurrentIdValue] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();

  let cancelButtonRef = useRef(null);

  let dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_API_URL;

  const columns = [
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ltr:ml-4 rtl:mr-4">
          {t("photo")}
        </div>
      ),
      selector: (row) => (
        <div className="ltr:ml-4 rtl:mr-4">
          {row?.image === null ? (
            <div>
              <div className="flex justify-center items-center rounded-md bg-gray-300 h-8 w-8 border border-none">
                <span className="text-base font-semibold text-gray-700">
                  {row?.englishDescription.charAt(0).toUpperCase()}
                </span>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex justify-center items-center rounded-full h-8 w-8 border border-none">
                <img
                  src={apiUrl + "task-image/" + row?.image}
                  alt=""
                  className="h-8 w-8"
                />
              </div>
            </div>
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900">
          {t("description")}
        </div>
      ),
      selector: (row) => (
        <div className="flex flex-col">
          <div className="text-sm font-semibold text-gray-900 pt-2">
            {row?.englishDescription}
          </div>
          <div className="text-sm text-gray-500 pb-2">
            {row?.arabicDescription}
          </div>
        </div>
      ),
      sortable: true,
    },

    {
      name: (
        <div className="text-sm font-semibold text-gray-900">
          {t("enabled")}
        </div>
      ),
      selector: (row) => (
        <>
          {row?.default ? (
            <div className="px-3 py-1 rounded-md flex flex-row">{t("yes")}</div>
          ) : (
            <div className="bg-gray-200 text-gray-800 px-3 py-1 rounded-md">
              {t("no")}
            </div>
          )}
        </>
      ),
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900">
          {t("accountType")}
        </div>
      ),
      selector: (row) => (
        <>
          {row?.accountType === "institute" && (
            <div className="px-3 py-1 rounded-md flex flex-row">
              <BuildingOffice2Icon className="h-6 w-6 text-secondary" />
              <span className="mt-1 mx-1">{t("institute")}</span>
            </div>
          )}
          {row?.accountType === "family" && (
            <div className="px-3 py-1 rounded-md flex flex-row">
              <UserGroupIcon className="h-6 w-6 text-secondary" />
              <span className="mt-1 mx-1">{t("families")}</span>
            </div>
          )}
        </>
      ),
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900">
          {t("actions")}
        </div>
      ),
      selector: (row) => (
        <div className="flex space-x-6">
          <Link
            className="inline-flex items-center"
            to={`/champion/defaultsettings/tasks/update/${row?.id}`}
          >
            <PencilSquareIcon className="h-6 w-6 text-primary hover:text-secondary mx-3" />
          </Link>
          <button
            className="inline-flex items-center"
            onClick={() => showDeleteModal(row?.id)}
            ref={cancelButtonRef}
          >
            <TrashIcon className="h-6 w-6 text-red-700 hover:text-red-600" />
          </button>
        </div>
      ),
    },
  ];

  const getGifts = async () => {
    try {
      const response = await tasksServices.getAllTasks();
      if (response.status === 200) {
        setTasks(response.data.tasks);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  useEffect(() => {
    getGifts();
    // eslint-disable-next-line
  }, []);

  const showDeleteModal = (id) => {
    setDeleteModalOpen(true);
    setCurrentIdValue(id);
  };

  const handleDelete = () => {
    setIsSubmit(true);
    tasksServices
      .deleteTask(currentIdValue)
      .then((response) => {
        setIsSubmit(false);
        if (response.status === 200) {
          let newTasks = tasks.filter((task) => {
            return task?.id !== currentIdValue;
          });
          setTasks(newTasks);
          setDeleteModalOpen(false);
          setSuccess(true);
        }
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: TasksList.js:200 ~ handleDelete ~ error:",
          error
        );

        if (error.response.status === 401) {
          dispatch(logout());
        } else {
          setError(true);
          setDeleteModalOpen(false);
        }
      });
  };

  const handleCloseMessage = () => {
    if (error) setError(false);
    if (success) setSuccess(false);
  };

  return (
    <Layout>
      {/* Breadcrumb */}
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 md:px-8 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500">
                {t("defaultSettings")}
              </p>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("tasks")}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <SettingsTab />
      <Container>
        <DeleteModal
          deleteModalOpen={deleteModalOpen}
          setDeleteModalOpen={setDeleteModalOpen}
          cancelButtonRef={cancelButtonRef}
          handleDelete={handleDelete}
          setCurrentIdValue={setCurrentIdValue}
          isSubmit={isSubmit}
          name={t("deleteTask")}
        />

        {error && (
          <ErrorAlert
            message={t("deleteTaskError")}
            close={handleCloseMessage}
          />
        )}
        {success && (
          <SuccessAlert
            message={t("deleteTaskSuccess")}
            close={handleCloseMessage}
          />
        )}

        <div className="flex justify-between items-center">
          <h1 className="flex-1 text-2xl font-bold text-gray-900">
            {t("defaultTasksList")}
          </h1>
          <Link
            to={"/champion/defaultsettings/tasks/new"}
            className="flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary"
          >
            <PlusIcon className="h-5 w-5 text-white" />
            {t("newTask")}
          </Link>
        </div>
        <div className="mt-6 shadow-lg">
          <div className="-my-2 -mx-4 overflow-x-scroll sm:-mx-6 lg:-mx-8">
            <div className="min-w-full py-2 align-middle md:px-6 lg:px-8">
              {tasks ? (
                <DataTable
                  columns={columns}
                  data={tasks}
                  fixedHeader
                  direction={
                    window.localStorage.getItem("i18nextLng") === "en"
                      ? "ltr"
                      : "rtl"
                  }
                />
              ) : (
                <PageLoader />
              )}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default TasksList;
