import React from "react";

const Container = ({ children }) => {
  return (
    <main className="flex-1">
      <div className="py-8 xl:py-10">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">{children}</div>
      </div>
    </main>
  );
};

export default Container;
