import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import Container from "../../components/Layout/Container";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import * as InstitutesServices from "../../services/institutes.services";
import { HomeIcon } from "@heroicons/react/24/outline";
import Institute from "../../components/InstituteDetails/Institute";
import Grades from "../../components/InstituteDetails/Grades";
import { useDispatch } from "react-redux";
import { logout } from "../../slices/authSlice";
import Teachers from "../../components/InstituteDetails/Teachers";

const InstituteDetails = () => {
  const [institute, setInstitute] = useState(null);

  const { t } = useTranslation();
  const { instituteId } = useParams();

  let dispatch = useDispatch();

  useEffect(() => {
    const getInstituteById = async () => {
      await InstitutesServices.getInstituteById(instituteId)
        .then((response) => {
          setInstitute(response.data.institute);
        })
        .catch((error) => {
          console.log("🚀 ~ file: EditUser.js:50 ~ useEffect ~ error", error);
          if (error.response.status === 401) {
            dispatch(logout());
          }
        });
    };
    getInstituteById();
  }, [instituteId, dispatch]);

  return (
    <Layout>
      {/* Breadcrumb */}
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to={"/champion/institutes"}
                className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500 hover:text-secondary"
              >
                {t("institutes")}
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("instituteDetails")}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <Container>
        <div className="flex">
          <h1 className="flex-1 text-2xl font-bold text-gray-900">
            {t("instituteDetails")}
          </h1>
        </div>
        <Institute institute={institute} />
        <Grades />
        <Teachers />
      </Container>
    </Layout>
  );
};

export default InstituteDetails;
