import axios from "axios";
import * as headers from "./auth-header";

const apiUrl = process.env.REACT_APP_API_URL;

export function getAllContacts() {
  return axios.get(apiUrl + "config/contact", {
    headers: headers.authHeader(),
  });
}

export function updateContact(id, contact) {
  return axios.put(apiUrl + "config/contact/" + id, contact, {
    headers: headers.authHeader(),
  });
}
