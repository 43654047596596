import { useState, useEffect } from "react";
import * as StatsServices from "../../services/statistics.services";
import {
  UserIcon,
  BuildingOfficeIcon,
  UserGroupIcon,
  ReceiptPercentIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { logout } from "../../slices/authSlice";
import { useDispatch } from "react-redux";
import PageLoader from "../Loaders/PageLoader";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Insights = () => {
  const [subscriptions, setSubscriptions] = useState(null);
  const [institutes, setInstitutes] = useState(null);
  const [families, setFamilies] = useState(null);
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);

  let dispatch = useDispatch();

  const { t } = useTranslation();

  const GetSubscriptionsCount = async () => {
    setLoading(true);
    await StatsServices.getSubscriptionsCount()
      .then((response) => {
        setSubscriptions(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  const GetInstitutesCount = async () => {
    setLoading(true);
    await StatsServices.getInstitutesCount()
      .then((response) => {
        setInstitutes(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  const GetFamilyCount = async () => {
    setLoading(true);
    await StatsServices.getFamilyCount()
      .then((response) => {
        setFamilies(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  const GetUsersCount = async () => {
    setLoading(true);
    await StatsServices.getUsersCount()
      .then((response) => {
        setUsers(response.data.count);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  useEffect(() => {
    GetSubscriptionsCount();
    GetInstitutesCount();
    GetFamilyCount();
    GetUsersCount();
    // eslint-disable-next-line
  }, [dispatch]);

  const insightData = [
    {
      name: t("institutes"),
      icon: BuildingOfficeIcon,
      counter: institutes,
      bgColor: "bg-rose-500",
    },
    {
      name: t("families"),
      icon: UserGroupIcon,
      counter: families,
      bgColor: "bg-amber-500",
    },
    {
      name: t("users"),
      icon: UserIcon,
      counter: users,
      bgColor: "bg-teal-500",
    },
    {
      name: t("subscriptions"),
      icon: ReceiptPercentIcon,
      counter: subscriptions,
      bgColor: "bg-violet-500",
    },
  ];

  return (
    <div>
      <h1 className="flex-1 text-2xl font-bold text-gray-900">
        {t("insights")}
      </h1>

      <ul
        // role="list"
        className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
      >
        {insightData.map((insight) => (
          <li
            key={insight.name}
            className="col-span-1 flex rounded-md shadow-sm"
          >
            <div
              className={classNames(
                insight.bgColor,
                "flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium ltr:rounded-l-md rtl:rounded-r-md"
              )}
            >
              <insight.icon className="text-gray-100 group-hover:text-white h-8 w-8" />
            </div>
            <div className="flex flex-1 items-center justify-between truncate ltr:rounded-r-md rtl:rounded-l-md border-t rtl:border-l ltr:border-r border-b border-gray-200 bg-white">
              <div className="flex-1 truncate px-4 py-2 text-sm">
                <p className="font-medium text-gray-900 hover:text-gray-600">
                  {insight.name}
                </p>
                {loading ? (
                  <PageLoader />
                ) : (
                  <p className="font-semibold text-2xl text-gray-500">
                    {insight.counter}
                  </p>
                )}
              </div>
              <div className="flex-shrink-0 pr-2">
                <button
                  type="button"
                  className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                >
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Insights;
