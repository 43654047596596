import React from "react";
import { Link } from "react-router-dom";
import { ReceiptPercentIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import ReactApexChart from "react-apexcharts";
// import { format } from "date-fns";

const FamiliesChart = ({ data }) => {
  const { t } = useTranslation();
  const amount = data.map((data) => data.amount);
  const month = data.map((data) => data.month);

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: month,
    },
  };
  const series = [
    {
      name: t("amountPerMonth"),
      data: amount,
    },
  ];

  return (
    <div className="mt-4">
      <div className="rounded-lg relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500">
        <div>
          <span className="text-teal-700 bg-violet-50 rounded-lg inline-flex p-3 ring-4 ring-white">
            <ReceiptPercentIcon className="h-6 w-6" aria-hidden="true" />
          </span>
        </div>
        <h3 className="text-lg font-medium mt-3">
          <Link to={"#"} className="focus:outline-none">
            <span className="absolute inset-0" aria-hidden="true" />
            {t("subscriptions")}
          </Link>
        </h3>
        <div className="mt-8">
          <ReactApexChart
            options={options}
            series={series}
            type="area"
            height={350}
          />
        </div>
      </div>
    </div>
  );
};

export default FamiliesChart;
