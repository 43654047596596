import axios from "axios";
import * as headers from "./auth-header";

const apiUrl = process.env.REACT_APP_API_URL;

const login = (body) => {
  return axios.post(apiUrl + "auth/token", body);
};

const requestResetPassword = (email) => {
  return axios.get(
    apiUrl + `auth/request-forget-password-confirmation-token/email/${email}`
  );
};

const resetPassword = (token, body) => {
  return axios.post(`${apiUrl}auth/reset-password`, body, {
    headers: headers.resetPasswordHeader(token),
  });
};

const logout = () => {
  localStorage.removeItem("user");
};

const authServices = {
  login,
  requestResetPassword,
  resetPassword,
  logout,
};

export default authServices;
