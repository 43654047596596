import React from "react";
import Container from "../../components/Layout/Container";
import Layout from "../../components/Layout/Layout";
import ReportsTab from "../../components/Tabs/ReportsTab";
import { Link } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import Families from "../../components/Reports/Pricing/Families";
import Institute from "../../components/Reports/Pricing/Institute";

const Pricing = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      {/* Breadcrumb */}
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 md:px-8 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500">
                {t("reports")}
              </p>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("pricingReport")}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <ReportsTab />
      <Container>
        <div className="flex">
          <h1 className="flex-1 text-2xl font-bold text-gray-900">
            {t("pricingReport")}
          </h1>
        </div>
        <div className="mx-auto max-w-7xl">
          <div className="mt-8 border rounded-lg shadow-md sm:grid sm:grid-cols-2 sm:gap-px">
            <Families />
            <Institute />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Pricing;
