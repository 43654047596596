import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../components/Layout/Container";
import Layout from "../../../components/Layout/Layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/24/outline";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonLoader from "../../../components/Loaders/ButtonLoader";
import * as offersServices from "../../../services/subscriptions/offers.services";
import { useDispatch } from "react-redux";
import { logout } from "../../../slices/authSlice";
import ErrorAlert from "../../../components/Alerts/ErrorAlert";
import * as PricingServices from "../../../services/subscriptions/pricing.services";
import PageLoader from "../../../components/Loaders/PageLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditOffers = () => {
  const [pricing, setPricing] = useState(null);
  const [offer, setOffer] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  const { id } = useParams();

  let navigate = useNavigate();

  let dispatch = useDispatch();

  const OfferUpdateSchema = Yup.object().shape({
    type: Yup.string().required(t("typeRequired")),
    discount: Yup.string(),
    increaseRangeBy: Yup.string(),
    from: Yup.string().required(t("fromRequired")),
    to: Yup.string().required(t("toRequired")),
    pricingId: Yup.string().required(t("pricingRequired")),
    arabicDescription: Yup.string().required(t("arabicDescriptionRequired")),
    englishDescription: Yup.string().required(t("englishDescriptionRequired")),
  });

  const handleSubmit = (values) => {
    setIsSubmit(true);
    offersServices
      .updateOffer(id, values)
      .then((response) => {
        setIsSubmit(false);
        navigate("/champion/offers");
      })
      .catch((error) => {
        setIsSubmit(false);
        if (error.response.status === 401) {
          dispatch(logout());
        } else {
          setError(true);
        }
      });
  };

  const handleCloseMessage = () => {
    setError(false);
  };

  const getPricing = async () => {
    try {
      const response = await PricingServices.pricingList(1, 1000);
      if (response.status === 200) {
        setPricing(response.data.prices.rows);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  useEffect(() => {
    getPricing();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getById = () => {
      offersServices
        .getOfferById(id)
        .then((response) => {
          setOffer(response.data.offer);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            dispatch(logout());
          } else {
            setError(true);
          }
        });
    };
    getById();
  }, [id, dispatch]);

  return (
    <Layout>
      {/* Breadcrumb */}
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 md:px-8 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500">
                {t("subscriptions")}
              </p>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <Link
                to={"/champion/offers"}
                className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500 hover:text-primary"
              >
                {t("offersList")}
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("editOffer")}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <Container>
        {error && (
          <ErrorAlert
            message={t("editOfferError")}
            close={handleCloseMessage}
          />
        )}
        <h1 className="flex-1 text-2xl font-bold text-gray-900">
          {t("editOffer")}
        </h1>
        {pricing && offer ? (
          <div className="py-8 px-4 bg-white shadow-lg sm:rounded-lg sm:px-10">
            <Formik
              initialValues={{
                type: offer.type ?? "",
                discount: offer.discount ?? "",
                increaseRangeBy: offer.increaseRangeBy ?? "",
                from: new Date(offer.from) ?? new Date(),
                to: new Date(offer.to) ?? new Date(),
                pricingId: offer.pricingId ?? "",
                arabicDescription: offer.arabicDescription ?? "",
                englishDescription: offer.englishDescription ?? "",
              }}
              validationSchema={OfferUpdateSchema}
              onSubmit={(values) => {
                if (values.type === "discount") {
                  values.increaseRangeBy = null;
                  values.discount = parseInt(values.discount);
                } else if (values.type === "range") {
                  values.discount = null;
                  values.increaseRangeBy = parseInt(values.increaseRangeBy);
                }
                values.pricingId = parseInt(values.pricingId);
                handleSubmit(values);
              }}
            >
              {({ touched, errors, values, setFieldValue }) => (
                <Form>
                  <div className="grid md:grid-cols-2 gap-3 sm:grid-cols-1 mt-5">
                    <div>
                      <label
                        htmlFor="type"
                        className=" text-sm font-medium text-gray-700"
                      >
                        {t("offerType")}
                      </label>
                      <Field
                        as="select"
                        name="type"
                        id="type"
                        className={`shadow-sm  w-full sm:text-sm rounded-md ${
                          touched.type && errors.type
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      >
                        <option value="discount">{t("discount")}</option>
                        <option value="range">{t("extraUnit")}</option>
                      </Field>
                      <ErrorMessage
                        name="type"
                        component="span"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    {values.type === "discount" && (
                      <div>
                        <label
                          htmlFor="discount"
                          className=" text-sm font-medium text-gray-700"
                        >
                          {t("discount")}
                        </label>
                        <Field
                          type="text"
                          name="discount"
                          id="discount"
                          className={`shadow-sm  w-full sm:text-sm rounded-md ${
                            touched.discount && errors.discount
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                        />
                        <ErrorMessage
                          name="discount"
                          component="span"
                          className="text-red-800 text-xs"
                        />
                      </div>
                    )}
                    {values.type === "range" && (
                      <div>
                        <label
                          htmlFor="increaseRangeBy"
                          className=" text-sm font-medium text-gray-700"
                        >
                          {t("extraUnit")}
                        </label>
                        <Field
                          type="text"
                          name="increaseRangeBy"
                          id="increaseRangeBy"
                          className={`shadow-sm  w-full sm:text-sm rounded-md ${
                            touched.increaseRangeBy && errors.increaseRangeBy
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                          disabled={isSubmit}
                        />
                        <ErrorMessage
                          name="increaseRangeBy"
                          component="span"
                          className="text-red-800 text-xs"
                        />
                      </div>
                    )}
                  </div>
                  <div className="grid md:grid-cols-3 gap-3 sm:grid-cols-1 mt-5">
                    <div>
                      <label
                        htmlFor="pricingId"
                        className=" text-sm font-medium text-gray-700"
                      >
                        {t("pricing")}
                      </label>
                      <Field
                        as="select"
                        name="pricingId"
                        id="pricingId"
                        className={`shadow-sm  w-full sm:text-sm rounded-md ${
                          touched.pricingId && errors.pricingId
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      >
                        {pricing.map((pricing) => (
                          <option key={pricing.id} value={pricing.id}>
                            {window.localStorage.getItem("i18nextLng") === "en"
                              ? pricing.englishTitle
                              : pricing.arabicTitle}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="pricingId"
                        component="span"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="from"
                        className=" text-sm font-medium text-gray-700"
                      >
                        {t("from")}
                      </label>
                      <DatePicker
                        selected={values.from}
                        dateFormat="MM / dd / yyyy"
                        className={`shadow-sm  w-full sm:text-sm rounded-md ${
                          touched.from && errors.from
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                        name="from"
                        onChange={(date) => setFieldValue("from", date)}
                      />
                      <ErrorMessage
                        name="from"
                        component="span"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="to"
                        className=" text-sm font-medium text-gray-700"
                      >
                        {t("to")}
                      </label>
                      <DatePicker
                        selected={values.to}
                        dateFormat="MM / dd / yyyy"
                        className={`shadow-sm  w-full sm:text-sm rounded-md ${
                          touched.to && errors.to
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                        name="to"
                        onChange={(date) => setFieldValue("to", date)}
                      />
                      <ErrorMessage
                        name="to"
                        component="span"
                        className="text-red-800 text-xs"
                      />
                    </div>
                  </div>
                  <div className="grid md:grid-cols-2 gap-3 sm:grid-cols-1 mt-5">
                    <div>
                      <label
                        htmlFor="arabicDescription"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("arabicDescription")}
                      </label>
                      <Field
                        type="text"
                        as="textarea"
                        name="arabicDescription"
                        id="arabicDescription"
                        className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                          touched.arabicDescription && errors.arabicDescription
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      />
                      <ErrorMessage
                        name="arabicDescription"
                        component="span"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="englishDescription"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("englishDescription")}
                      </label>
                      <Field
                        type="text"
                        as="textarea"
                        name="englishDescription"
                        id="englishDescription"
                        className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                          touched.englishDescription &&
                          errors.englishDescription
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      />
                      <ErrorMessage
                        name="englishDescription"
                        component="span"
                        className="text-red-800 text-xs"
                      />
                    </div>
                  </div>
                  <div className="px-4 pt-8 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-primary text-base font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                    >
                      {isSubmit ? (
                        <>
                          <ButtonLoader />
                          {t("save")}
                        </>
                      ) : (
                        t("save")
                      )}
                    </button>
                    <Link
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-8 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                      to={"/champion/offers"}
                    >
                      {t("cancel")}
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <PageLoader />
        )}
      </Container>
    </Layout>
  );
};

export default EditOffers;
