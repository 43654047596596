import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/24/outline";
import Container from "../../components/Layout/Container";
import Layout from "../../components/Layout/Layout";
import NotificationsTabs from "../../components/Tabs/NotificationsTab";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonLoader from "../../components/Loaders/ButtonLoader";
import * as NotificationsServices from "../../services/notifications.services";
// import * as familiesServices from "../../services/users/families.services";
import { useDispatch } from "react-redux";
import { logout } from "../../slices/authSlice";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import Select from "react-tailwindcss-select";

const NotifyMultiUsers = () => {
  // const [users, setUsers] = useState(null);
  // const [selectedOptions, setSelectedOptions] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();

  let dispatch = useDispatch();

  const NotificationSchema = Yup.object().shape({
    users: Yup.string().required(t("selectUsersRequired")),
    arabicTitle: Yup.string()
      .min(3, t("characterNumber"))
      .required(t("titleRequired")),
    englishTitle: Yup.string()
      .min(3, t("characterNumber"))
      .required(t("titleRequired")),
    arabicBody: Yup.string()
      .min(3, t("characterNumber"))
      .required(t("messageRequired")),
    englishBody: Yup.string()
      .min(3, t("characterNumber"))
      .required(t("messageRequired")),
    url: Yup.string(),
  });

  // const getUsers = () => {
  //   familiesServices
  //     .filterFamiliesAdmin(1, 1000, "")
  //     .then((response) => {
  //       console.log(
  //         "🚀 ~ file: NotifyMultiUsers.js:48 ~ familiesServices.filterFamiliesAdmin ~ response:",
  //         response
  //       );
  //     })
  //     .catch((error) => {
  //       console.log(
  //         "🚀 ~ file: NotifyMultiUsers.js:53 ~ familiesServices.filterFamiliesAdmin ~ error:",
  //         error
  //       );
  //     });
  // };

  // useEffect(() => {
  //   getUsers();
  // }, []);

  const handleSubmit = (values) => {
    setIsSubmit(true);
    NotificationsServices.sendMultipleNotification({
      ...values,
      action: "message",
    })
      .then((response) => {
        setIsSubmit(false);
        setSuccess(true);
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: NotifyAllUsers.js:56 ~ handleSubmit ~ error:",
          error
        );
        setIsSubmit(false);
        if (error.response.status === 401) {
          dispatch(logout());
        } else {
          setIsSubmit(false);
          setError(true);
        }
      });
  };

  const handleCloseMessage = () => {
    if (error) setError(false);
    if (success) setSuccess(false);
  };
  return (
    <Layout>
      {/* Breadcrumb */}
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 md:px-8 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500">
                {t("notifications")}
              </p>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("toMultiple")}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <NotificationsTabs />
      <Container>
        <h1 className="flex-1 text-2xl font-bold text-gray-900">
          {t("sendNotificationToMulti")}
        </h1>
        {error && (
          <ErrorAlert
            message={t("sendNotificationError")}
            close={handleCloseMessage}
          />
        )}
        {success && (
          <SuccessAlert
            message={t("sendNotificationSuccess")}
            close={handleCloseMessage}
          />
        )}
        <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-4">
          <Formik
            initialValues={{
              users: "",
              arabicTitle: "",
              englishTitle: "",
              arabicBody: "",
              englishBody: "",
              url: "",
            }}
            validationSchema={NotificationSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values);
              resetForm({ values: "" });
            }}
          >
            {({ touched, errors }) => (
              <Form>
                <div className="px-6 py-8">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="userId"
                      className="text-sm font-medium text-gray-700"
                    >
                      {t("selectUsers")}
                    </label>
                    <div className="mt-2">
                      <Select
                        primaryColor={"primary"}
                        // value={selectedOptions}
                        // onChange={handleChange}
                        // options={options}
                        name="users"
                        isMultiple
                        isSearchable
                        isClearable
                        classNames={{
                          control: (state) =>
                            state.isFocused
                              ? "border-primary"
                              : "border-grey-300",
                        }}
                      />
                    </div>
                    <ErrorMessage
                      name="users"
                      component="p"
                      className="text-red-800 text-xs"
                    />
                  </div>
                  <div className="grid md:grid-cols-3 gap-3 sm:grid-cols-1 mt-5">
                    <div>
                      <label
                        htmlFor="arabicTitle"
                        className="text-sm font-medium text-gray-700"
                      >
                        {t("arabicTitle")}
                      </label>
                      <Field
                        type="text"
                        name="arabicTitle"
                        id="arabicTitle"
                        className={`shadow-sm w-full sm:text-sm rounded-md ${
                          touched.arabicTitle && errors.arabicTitle
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      />
                      <ErrorMessage
                        name="arabicTitle"
                        component="p"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="englishTitle"
                        className=" text-sm font-medium text-gray-700"
                      >
                        {t("englishTitle")}
                      </label>
                      <Field
                        type="text"
                        name="englishTitle"
                        id="englishTitle"
                        className={`shadow-sm  w-full sm:text-sm rounded-md ${
                          touched.englishTitle && errors.englishTitle
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      />
                      <ErrorMessage
                        name="englishTitle"
                        component="p"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="url"
                        className=" text-sm font-medium text-gray-700"
                      >
                        {t("url")}
                      </label>
                      <Field
                        type="text"
                        name="url"
                        id="url"
                        className={`shadow-sm  w-full sm:text-sm rounded-md ${
                          touched.url && errors.url
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      />
                      <ErrorMessage
                        name="url"
                        component="p"
                        className="text-red-800 text-xs"
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3 mt-3">
                    <label
                      htmlFor="arabicBody"
                      className="block text-base font-medium text-gray-700"
                    >
                      {t("arabicBody")}
                    </label>
                    <Field
                      type="text"
                      as="textarea"
                      name="arabicBody"
                      id="arabicBody"
                      className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                        touched.arabicBody && errors.arabicBody
                          ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                          : "focus:ring-primary focus:border-primary border-gray-300"
                      }`}
                    />
                    <ErrorMessage
                      name="arabicBody"
                      component="p"
                      className="text-red-800 text-xs"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3 mt-3">
                    <label
                      htmlFor="englishBody"
                      className="block text-base font-medium text-gray-700"
                    >
                      {t("englishBody")}
                    </label>
                    <Field
                      type="text"
                      as="textarea"
                      name="englishBody"
                      id="englishBody"
                      className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                        touched.englishBody && errors.englishBody
                          ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                          : "focus:ring-primary focus:border-primary border-gray-300"
                      }`}
                    />
                    <ErrorMessage
                      name="englishBody"
                      component="p"
                      className="text-red-800 text-xs"
                    />
                  </div>
                </div>
                <div className="px-4 pb-4 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-primary text-base font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                  >
                    {isSubmit ? (
                      <>
                        <ButtonLoader />
                        {t("send")}
                      </>
                    ) : (
                      t("send")
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </Layout>
  );
};

export default NotifyMultiUsers;
