import axios from "axios";
import * as headers from "../auth-header";

const apiUrl = process.env.REACT_APP_API_URL;

export function getAllFamiliesAdmin(page, limit) {
  return axios.get(apiUrl + `auth/family-users/${page}/${limit}`, {
    headers: headers.authHeader(),
  });
}

export const checkEmail = async (email) => {
  const result = axios
    .post(apiUrl + "auth/email/taken", { email: email })
    .then((response) => {
      console.log("checkEmail resposne: ", response.data);
      return !response.data;
    });
  return result;
};

export function getFamilyAdminById(id) {
  return axios.get(apiUrl + `auth/user-details/${id}`, {
    headers: headers.authHeader(),
  });
}

export function filterFamiliesAdmin(page, limit, key) {
  return axios.get(
    apiUrl + "auth/family-users/search/" + key + "/" + page + "/" + limit,
    {
      headers: headers.authHeader(),
    }
  );
}

//find user feelings
export function findFamilyAdminFeelings(queryParams) {
  return axios.post(apiUrl + "/feelings/find", { queryParams });
}

//find user assessments
export function findFamilyAdminAssessments(queryParams) {
  return axios.post(apiUrl + "/assessments/find", { queryParams });
}

// UPDATE => PUT: update the user on the server
export function updateFamilyAdmin(user) {
  console.log("updateFamilyAdmin user", user);
  return axios.put(apiUrl + "auth/update-info", user, {
    headers: headers.authHeader(),
  });
}

// DELETE => delete the User from the server
export function deleteFamiliesUser(id) {
  return axios.delete(apiUrl + `auth/user/${id}`, {
    headers: headers.authHeader(),
  });
}
