import * as copyrightsServices from "../../services/copyrights.services";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/24/outline";
import Container from "../../components/Layout/Container";
import Layout from "../../components/Layout/Layout";
import { useDispatch } from "react-redux";
import { logout } from "../../slices/authSlice";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonLoader from "../../components/Loaders/ButtonLoader";
import PageLoader from "../../components/Loaders/PageLoader";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import ErrorAlert from "../../components/Alerts/ErrorAlert";

const CopyRights = () => {
  const [copyRights, setCopyRights] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();

  let dispatch = useDispatch();

  const CopyRightsSchema = Yup.object().shape({
    arabicDescription: Yup.string().required(t("arabicDescriptionRequired")),
    englishDescription: Yup.string().required(t("englishDescriptionRequired")),
    year: Yup.string().required(t("yearRequired")),
  });

  const getCopyRights = async () => {
    try {
      const response = await copyrightsServices.getAllCopys();
      console.log(
        "🚀 ~ file: CopyRights.js:12 ~ getContact ~ response:",
        response
      );
      if (response.status === 200) {
        setCopyRights(response.data.copyright);
      }
    } catch (error) {
      console.log("🚀 ~ file: CopyRights.js:20 ~ getContact ~ error:", error);
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  useEffect(() => {
    getCopyRights();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (values) => {
    setIsSubmit(true);
    copyrightsServices
      .updateCopy(copyRights.id, values)
      .then((response) => {
        setIsSubmit(false);
        setSuccess(true);
      })
      .catch((error) => {
        setIsSubmit(false);
        if (error.response.status === 401) {
          dispatch(logout());
        } else {
          setError(true);
        }
      });
  };

  const handleCloseMessage = () => {
    if (error) setError(false);
    if (success) setSuccess(false);
  };

  return (
    <Layout>
      {/* Breadcrumb */}
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 md:px-8 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("copyRights")}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <Container>
        <h1 className="flex-1 text-2xl font-bold text-gray-900">
          {t("copyRights")}
        </h1>
        {error && (
          <div className="my-4">
            <ErrorAlert
              message={t("updateRightsError")}
              close={handleCloseMessage}
            />
          </div>
        )}
        {success && (
          <SuccessAlert
            message={t("updateRightsSuccess")}
            close={handleCloseMessage}
          />
        )}
        <div className="mt-4 flex flex-col w-full">
          <div className="py-8 px-4 bg-white shadow-lg sm:rounded-lg sm:px-10">
            {copyRights ? (
              <Formik
                enableReinitialize={true}
                initialValues={{
                  arabicDescription: copyRights.arabicDescription ?? "",
                  englishDescription: copyRights.englishDescription ?? "",
                  year: copyRights.year ?? "",
                }}
                validationSchema={CopyRightsSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {({ touched, errors }) => (
                  <Form>
                    <div className="col-span-6 sm:col-span-3 mt-4">
                      <label
                        htmlFor="arabicDescription"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("arabicDescription")}
                      </label>
                      <Field
                        type="text"
                        as="textarea"
                        name="arabicDescription"
                        id="arabicDescription"
                        className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                          touched.arabicDescription && errors.arabicDescription
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      />
                      <ErrorMessage
                        name="arabicDescription"
                        component="p"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3 mt-4">
                      <label
                        htmlFor="englishDescription"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("englishDescription")}
                      </label>
                      <Field
                        type="text"
                        as="textarea"
                        name="englishDescription"
                        id="englishDescription"
                        className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                          touched.englishDescription &&
                          errors.englishDescription
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      />
                      <ErrorMessage
                        name="englishDescription"
                        component="p"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3 mt-4">
                      <label
                        htmlFor="year"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("year")}
                      </label>
                      <Field
                        type="text"
                        name="year"
                        id="year"
                        className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                          touched.year && errors.year
                            ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                            : "focus:ring-primary focus:border-primary border-gray-300"
                        }`}
                        disabled={isSubmit}
                      />
                      <ErrorMessage
                        name="year"
                        component="p"
                        className="text-red-800 text-xs"
                      />
                    </div>
                    <div className="px-4 pt-8 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-primary text-base font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                      >
                        {isSubmit ? (
                          <>
                            <ButtonLoader />
                            {t("save")}
                          </>
                        ) : (
                          t("save")
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <PageLoader />
            )}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default CopyRights;
