import axios from "axios";
import * as headers from "../auth-header";

const apiUrl = process.env.REACT_APP_API_URL;

export const addPricing = (data) => {
  return axios.post(apiUrl + "subscription/pricing/", data, {
    headers: headers.authHeader(),
  });
};

export const pricingList = (page, limit) => {
  return axios.get(apiUrl + `subscription/pricing/${page}/${limit}`, {
    headers: headers.authHeader(),
  });
};

export const pricingDetails = (id) => {
  return axios.get(apiUrl + `subscription/pricing/${id}`, {
    headers: headers.authHeader(),
  });
};

export const updatePrice = (id, data) => {
  return axios.put(apiUrl + `subscription/pricing/${id}`, data, {
    headers: headers.authHeader(),
  });
};

export const deletePrice = (id) => {
  return axios.delete(apiUrl + `subscription/pricing/${id}`, {
    headers: headers.authHeader(),
  });
};
