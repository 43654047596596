import { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import logo from "../../assets/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import authServices from "../../services/auth.services";
import { clearError } from "../../slices/errorSlice";
import { setError } from "../../slices/errorSlice";
import { useTranslation } from "react-i18next";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import ButtonLoader from "../../components/Loaders/ButtonLoader";

export default function RequestResetPassword() {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { t } = useTranslation();
  const { error } = useSelector((state) => state.error);

  const ValidationSchema = Yup.object().shape({
    email: Yup.string().email(t("emailNotValid")).required(t("emailRequired")),
  });

  const handleSubmit = (email) => {
    setLoading(true);
    authServices
      .requestResetPassword(email)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          navigate(`/reset-password/${email}/${response.data.token}`);
        } else {
          dispatch(
            setError({ message: "Something went wrong, try again later" })
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        dispatch(setError({ message: error.response.data.message }));
      });
  };

  const handleCloseMessage = () => {
    dispatch(clearError());
  };

  return (
    <div>
      <div className="h-screen bg-gradient-to-tr from-primary to-gray-shade">
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-28 w-auto"
              src={logo}
              alt="Whos champion"
            />
            <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-white drop-shadow-md">
              {t("resetPassword")}
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white/25 py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <Formik
                initialValues={{ email: "" }}
                validationSchema={ValidationSchema}
                onSubmit={(values) => handleSubmit(values.email)}
              >
                {({ touched, errors }) => (
                  <Form className="space-y-6">
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 ltr:left-0 rtl:right-0 flex items-center ltr:pl-3 rtl:pr-3">
                        <i
                          className="fi fi-rr-envelope h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <Field
                        name="email"
                        id="email"
                        type="email"
                        autoComplete="email"
                        placeholder={t("email")}
                        className={`block w-full rounded-md border border-transparent placeholder-gray-300 ltr:pl-10 rtl:pr-10 shadow-sm sm:text-sm ${
                          touched.email && errors.email
                            ? "border-2 border-red-700 focus:ring-red-800 focus:border-red-800"
                            : "border border-transparent focus:border-secondary focus:ring-secondary"
                        }`}
                      />
                    </div>
                    {touched.email && errors.email && (
                      <label className="text-rose-600 text-sm">
                        {t("enterEmail")}
                      </label>
                    )}

                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary"
                      >
                        {loading ? (
                          <>
                            <ButtonLoader /> {t("confirm")}
                          </>
                        ) : (
                          t("confirm")
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              {error && (
                <div className="mt-6">
                  <ErrorAlert
                    message={error.message}
                    close={handleCloseMessage}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
