import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "../../components/Layout/Container";
import Layout from "../../components/Layout/Layout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import * as InstitutesServices from "../../services/users/institutes.services";
import * as NotificationsServices from "../../services/notifications.services";
import PageLoader from "../../components/Loaders/PageLoader";
import ButtonLoader from "../../components/Loaders/ButtonLoader";
import { logout } from "../../slices/authSlice";
import { useDispatch } from "react-redux";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import { HomeIcon } from "@heroicons/react/24/outline";

const Notification = () => {
  const [user, setUser] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();
  const { id } = useParams();

  let navigate = useNavigate();
  let dispatch = useDispatch();

  useEffect(() => {
    const getById = async () => {
      await InstitutesServices.getInstituteAdminById(id)
        .then((response) => {
          setUser(response.data.user);
        })
        .catch((error) => {
          console.log("🚀 ~ file: EditUser.js:50 ~ useEffect ~ error", error);
        });
    };
    getById();
  }, [id]);

  const NotificationSchema = Yup.object().shape({
    arabicTitle: Yup.string()
      .min(3, t("characterNumber"))
      .required(t("titleRequired")),
    arabicBody: Yup.string()
      .min(3, t("characterNumber"))
      .required(t("messageRequired")),
    englishTitle: Yup.string()
      .min(3, t("characterNumber"))
      .required(t("titleRequired")),
    englishBody: Yup.string()
      .min(3, t("characterNumber"))
      .required(t("messageRequired")),
  });

  const handleSubmit = (values) => {
    setIsSubmit(true);
    NotificationsServices.sendUserNotification(id, {
      ...values,
      action: "message",
    })
      .then((response) => {
        setIsSubmit(false);
        setSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setIsSubmit(false);
        if (error.response.status === 401) {
          dispatch(logout());
        } else {
          setIsSubmit(false);
          setError(true);
        }
      });
  };

  const handleCloseMessage = () => {
    if (error) setError(false);
    if (success) setSuccess(false);
  };

  // TODO need to display this screen based on role

  return (
    <Layout>
      {/* Breadcrumb */}
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500">
                {t("users")}
              </p>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              {user?.role === "ADMIN" ? (
                <Link
                  to={"/champion/users/institutes"}
                  className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500 hover:text-secondary"
                >
                  {t("institutes")}
                </Link>
              ) : (
                <Link
                  to={"/champion/users/families"}
                  className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500 hover:text-secondary"
                >
                  {t("families")}
                </Link>
              )}
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              {user?.role === "ADMIN" ? (
                <Link
                  to={`/champion/users/institutes/${id}`}
                  className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500 hover:text-secondary"
                >
                  {t("userDetails")}
                </Link>
              ) : (
                <Link
                  to={`/champion/users/families/${id}`}
                  className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500 hover:text-secondary"
                >
                  {t("userDetails")}
                </Link>
              )}
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold text-secondary hover:text-primary">
                {t("sendNotification")}
              </p>
            </div>
          </li>
        </ol>
      </nav>

      <Container>
        {user ? (
          <>
            <div className="flex justify-between items-center">
              <h1 className="flex-1 text-2xl font-bold text-gray-900">
                {t("sendNotificationTo") +
                  " " +
                  user.firstName +
                  " " +
                  user.midName}
              </h1>
            </div>
            {error && (
              <ErrorAlert
                message={t("sendNotificationError")}
                close={handleCloseMessage}
              />
            )}
            {success && (
              <SuccessAlert
                message={t("sendNotificationSuccess")}
                close={handleCloseMessage}
              />
            )}
            <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-4">
              <Formik
                initialValues={{
                  arabicTitle: "",
                  arabicBody: "",
                  englishTitle: "",
                  englishBody: "",
                }}
                validationSchema={NotificationSchema}
                onSubmit={(values, { resetForm }) => {
                  handleSubmit(values);
                  resetForm({ values: "" });
                }}
              >
                {({ touched, errors }) => (
                  <Form>
                    <div className="px-6 py-8">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="arabicTitle"
                          className="block text-base font-medium text-gray-700"
                        >
                          {t("arabicTitle")}
                        </label>
                        <Field
                          type="text"
                          name="arabicTitle"
                          id="arabicTitle"
                          className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                            touched.arabicTitle && errors.arabicTitle
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                        />
                        <ErrorMessage
                          name="arabicTitle"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 mt-3">
                        <label
                          htmlFor="arabicBody"
                          className="block text-base font-medium text-gray-700"
                        >
                          {t("arabicBody")}
                        </label>
                        <Field
                          type="text"
                          as="textarea"
                          name="arabicBody"
                          id="arabicBody"
                          className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                            touched.arabicBody && errors.arabicBody
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                        />
                        <ErrorMessage
                          name="arabicBody"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 mt-6">
                        <label
                          htmlFor="englishTitle"
                          className="block text-base font-medium text-gray-700"
                        >
                          {t("englishTitle")}
                        </label>
                        <Field
                          type="text"
                          name="englishTitle"
                          id="englishTitle"
                          className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                            touched.englishTitle && errors.englishTitle
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                        />
                        <ErrorMessage
                          name="englishTitle"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 mt-3">
                        <label
                          htmlFor="englishBody"
                          className="block text-base font-medium text-gray-700"
                        >
                          {t("englishBody")}
                        </label>
                        <Field
                          type="text"
                          as="textarea"
                          name="englishBody"
                          id="englishBody"
                          className={`mt-1 shadow-sm block w-full sm:text-sm rounded-md ${
                            touched.englishBody && errors.englishBody
                              ? "focus:ring-red-800 focus:border-red-800 border-red-800"
                              : "focus:ring-primary focus:border-primary border-gray-300"
                          }`}
                        />
                        <ErrorMessage
                          name="englishBody"
                          component="p"
                          className="text-red-800 text-xs"
                        />
                      </div>
                      <div className="px-4 pt-8 sm:px-6 sm:flex sm:flex-row-reverse mt-">
                        <button
                          type="submit"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-primary text-base font-medium text-white hover:bg-secondary sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                        >
                          {isSubmit ? (
                            <>
                              <ButtonLoader />
                              {t("send")}
                            </>
                          ) : (
                            t("send")
                          )}
                        </button>
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-8 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-75"
                          onClick={() => navigate(-1)}
                        >
                          {t("cancel")}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        ) : (
          <PageLoader />
        )}
      </Container>
    </Layout>
  );
};

export default Notification;
