import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../slices/authSlice";
import * as InstitutesServices from "../../services/institutes.services";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { format } from "date-fns";
import PageLoader from "../Loaders/PageLoader";
import { useParams } from "react-router-dom";

const Teachers = () => {
  const [teachers, setTeachers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);

  const { instituteId } = useParams();
  const { t } = useTranslation();

  let dispatch = useDispatch();

  const columns = [
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ltr:ml-4 rtl:mr-4">
          {t("name")}
        </div>
      ),
      selector: (row) => (
        <div className="flex flex-col ltr:ml-4 rtl:mr-4">
          <div className="text-sm font-semibold text-gray-900 pt-2">
            {row.user?.firstName}
          </div>
          <div className="text-sm text-gray-500 pb-2">{row.user?.midName}</div>
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-sm font-semibold  text-gray-900">{t("email")}</div>
      ),
      selector: (row) => row.user?.email,
    },
    {
      name: (
        <div className="text-sm font-semibold  text-gray-900">
          {t("gender")}
        </div>
      ),
      selector: (row) =>
        row.user?.gender === "Female" ? (
          <div>
            {window.localStorage.getItem("i18nextLng") === "en"
              ? "Female"
              : "انثى"}
          </div>
        ) : (
          <div>
            {window.localStorage.getItem("i18nextLng") === "en"
              ? "Male"
              : "ذكر"}
          </div>
        ),
    },

    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ">
          {t("birthDate")}
        </div>
      ),
      selector: (row) => (
        <>
          {row.user?.birthDate
            ? format(new Date(row.user?.birthDate), "MMM d, y")
            : "not found"}
        </>
      ),
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ">
          {t("createdAt")}
        </div>
      ),
      selector: (row) => (
        <>
          {row.createdAt
            ? format(new Date(row.createdAt), "MMM d, y, HH:mm aa")
            : "not found"}
        </>
      ),
      sortable: true,
    },
  ];

  const getInstituteTeachers = async (page) => {
    await InstitutesServices.getInstituteTeachers(instituteId, page, limit)
      .then((response) => {
        setTeachers(response.data.teachers.rows);
        setCount(response.data.teachers.count);
      })
      .catch((error) => {
        console.log("🚀 ~ file: EditUser.js:50 ~ useEffect ~ error", error);
        if (error.response.status === 401) {
          dispatch(logout());
        }
      });
  };

  useEffect(() => {
    getInstituteTeachers(1);
    // eslint-disable-next-line
  }, [instituteId, dispatch]);

  const handlePageChange = (page) => {
    getInstituteTeachers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await InstitutesServices.getAllInstitutes(
        instituteId,
        page,
        newPerPage
      );
      setTeachers(response.data.grades.rows);
      setLimit(newPerPage);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-8">
      {teachers ? (
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-bold leading-6 text-gray-900">
              {t("teachers")}
            </h3>
          </div>
          <div className="flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                {
                  <DataTable
                    columns={columns}
                    data={teachers}
                    progressPending={loading}
                    progressComponent={<PageLoader />}
                    pagination
                    paginationServer
                    paginationTotalRows={count}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    fixedHeader
                    direction={
                      window.localStorage.getItem("i18nextLng") === "en"
                        ? "ltr"
                        : "rtl"
                    }
                  />
                }
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

export default Teachers;
