import axios from "axios";
import * as headers from "./auth-header";

const apiUrl = process.env.REACT_APP_API_URL;

export function sendNotification(notifiation) {
  return axios.post(apiUrl + "notifier/notifiy-all", notifiation, {
    headers: headers.authHeader(),
  });
}

export function sendMultipleNotification(notifiation) {
  return axios.post(apiUrl + "notifier/notifiy-users", notifiation, {
    headers: headers.authHeader(),
  });
}

export function sendUserNotification(id, body) {
  return axios.post(apiUrl + "notifier/notifiy/" + id, body, {
    headers: headers.authHeader(),
  });
}
