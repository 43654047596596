const Arabic = {
  champion: "من البطل",
  signinToAccount: "سجل الدخول الى حسابك",
  email: "البريد الإلكتروني",
  password: "كلمة المرور",
  forgotPassword: "هل نسيت كلمة المرور؟",
  signin: "تسجيل دخول",
  resetPassword: "إعادة تعيين كلمة المرور",
  enterEmail: "ادخل بريدك الإلكتروني .",
  confirm: "تأكيد",
  updatePassword: "تعديل كلمة المرور",
  code: "ادخل الرمز",
  newPass: "كلمة مرور جديدة",
  confirmPass: "تأكيد كلمة المرور",
  reset: "تعديل",
  otpRequired: "ادخل رمز OTP",
  passwordMin: "يجب ان لا تقل كلمة المرور عن 3 احرف.",
  passwordRequired: "ادخل كلمة المرور.",
  passwordMatch: "يجب ان تطابق كلمة المرور.",
  confirmPasswordRequired: "قم بتأكيد كلمة المرور.",
  emailNotValid: "البريد الإلكتروني غير صالح",
  emailRequired: "ادخل بريدك الإلكتروني .",
  institutes: "المؤسسات",
  families: "العائلات",
  users: "المستخدمين",
  subscriptions: "الإشتراكات",
  title: "العنوان",
  description: "الوصف",
  displayType: "نوع العرض",
  detailed: "مفصل",
  summarized: "ملخص",
  period: "الفتره الزمنية",
  targetUser: "المستخدم المستهدف",
  institute: "مؤسسة",
  family: "عائلة",
  price: "الاسعار",
  actions: "الإجراءات",
  pricingList: "قائمة الأسعار",
  dashboard: "الرئيسية",
  insights: "الرؤى",
  pricing: "التسعيرات",
  activeUsers: "المستخدمين النشطين",
  reports: "التقارير",
  copyRights: "حقوق الملكية",
  contacts: "التواصل",
  settings: "الإعدادات",
  gifts: "الجوائز",
  tasks: "المهام",
  offers: "العروض",
  notifications: "التنبيهات",
  toAll: "إرسال للجميع",
  toMultiple: "إرسال للبعض",
  advertisements: "الإعلانات",
  createdAt: "تاريخ الإضافة",
  phoneNumber: "رقم الهاتق",
  user: "المستخدم",
  firstName: "الإسم الاول",
  midName: "الإسم الثاني",
  userEmail: "البريد الإلكتروني",
  noPhoneNumber: "لم يتم إدخال رقم الهاتف!",
  instituteUsersList: "قائمة مستخدمي المؤسسات",
  newUser: "إضافة مستخدم",
  home: "الرئيسية",
  familiesUsersList: "قائمة مستخدمي العائلات",
  search: "بحث",
  addInstituteUser: "إضافة مستخدم جديد",
  phoneRequired: "الرجاء ادخل رقم الهاتف",
  phoneNotValid: "رقم الهاتف غير صحيح",
  nameRequired: "ادخل الإسم",
  save: "حفظ",
  cancel: "إلغاء",
  editUser: "تعديل بيانات المستخدم",
  roleRequired: "اختر الدور",
  createUserError: "فشل في العملية",
  updateUserError: "فشل في العملية",
  editinstitutesUser: "تحديث بيانات المستخدم",
  genderRequired: "الرجاء تحديد النوع",
  deleteInstituteUser: "حذف مستخدم مؤسسات",
  areYouSureWantTo: "هل انت متأكد من عملية الحذف ؟",
  delete: "حذف",
  deleteUserError: "فشل في العملية",
  deleteUserSuccess: "تم الحذف بنجاح",
  userDetails: "تفاصيل المستخدم",
  userInformation: "معلومات المستخدم",
  personalDetails: "تفاصيل و معلومات المستخدم",
  b6Code: "رمز البطل",
  fullName: "الإسم كامل",
  birthDate: "تاريخ الميلاد",
  noBirthDate: "تاريخ الميلاد غير موجود",
  gender: "النوع",
  sendNotification: "إرسال تنبيه",
  characterNumber: "يجب إدخال 3 احرف على الاقل",
  titleRequired: "ادخل العنوان",
  messageRequired: "ادخل الرسالة",
  arabicTitle: "العنوان بالعربي",
  englishTitle: "العنوان بالإنجليزي",
  arabicBody: "المحتوى بالعربي",
  englishBody: "المحتوى بالإنجليزي",
  sendNotificationTo: "إرسال تنبية إلى",
  send: "إرسال",
  sendNotificationError: "فشل في إرسال التنبيه",
  addNewGiftError: "فشل في العملية",
  sendNotificationSuccess: "تم إرسال التنبيه",
  instituteDetails: "تفاصيل المؤسسة",
  female: "انثى",
  male: "ذكر",
  select: "اختار",
  logout: "تسجيل خروج",
  newInstitutes: "المؤسسات الجديدة",
  newFamilies: "العائلات الجديدة",
  newUsers: "المستخدمين الجدد",
  amountPerMonth: "المبلغ المتحصل خلال شهر",
  newAdded: "الجدد",
  editfamiliesUser: "تحديث بيانات المستخدم",
  name: "الإسم",
  address: "العنوان",
  admin: "المشرف",
  details: "التفاصيل",
  englishName: "الإسم بالإنجليزي",
  arabicName: "الإسم بالعربي",
  id: "رمز التعريف",
  grades: "المراحل الدراسية",
  gradename: "اسم المرحلة الدراسية",
  teachers: "المعلمين",
  classRooms: "الفصول الدراسية",
  classRoomsDetails: "تفاصيل الفصل الدراسي",
  profile: "الصورة",
  students: "الطلاب",
  subject: "المادة",
  homeWorks: "الواجبات المنزلية",
  from: "من",
  to: "إلى",
  status: "الحالة",
  teacher: "الاستاذ",
  student: "الطالب",
  studentNoteDetails: "تفاصيل ملاحظات الطالب",
  institutesList: "قائمة المؤسسات",
  familiesList: "قائمة العائلات",
  familyMembers: "أفراد العائلة",
  familyName: "إسم العائلة",
  relevance: "العلاقة",
  noRelevance: "لم يتم تحديد العلاقة",
  defaultSettings: "الإعدادات الإفتراضية",
  defaultGiftsList: "قائمة الجوائز الافتراضية",
  newGift: "إضافة جائزه",
  photo: "الصورة",
  targetPoints: "رقم الفائز",
  accountType: "نوع الحساب",
  enabled: "مفعل",
  yes: "نعم",
  no: "لا",
  uploadImage: "تحميل الصورة",
  arabicDescription: "الوصف بالعربي",
  englishDescription: "الوصف بالإنجليزي",
  remove: "حذف",
  deleteGiftError: "فشل في العملية",
  deleteGiftSuccess: "تم الحذف بنجاح",
  editGift: "تحديث الجائزة",
  deleteGift: "حذف الجائزة",
  editGiftError: "فشل في عملية التعديل",
  deleteTaskError: "فشل في العملية",
  deleteTaskSuccess: "تم الحذف بنجاح",
  editTask: "تحديث المهمة",
  deleteTask: "حذف المهمة",
  editTaskError: "فشل في عملية التعديل",
  defaultTasksList: "قائمة المهام الافتراضية",
  newTask: "إضافة مهمة",
  addNewTaskError: "فشل في العملية",
  ads: "الإعلانات",
  url: "رابط الإعلان",
  updateAdsSuccess: "تم التعديل بنجاح",
  updateAdsError: "فشل في العملية",
  sendNotificationToAll: "إرسال تنبية لكل المستخدمين",
  sendNotificationToMulti: "إرسال تنبية لبعض المستخدمين",
  selectUsersRequired: "إختار المستخدمين",
  selectUsers: "إختار المستخدمين المستهدفين",
  subscriptionsAndOffers: "الإشتراكات و العروض",
  subscriptionsList: "قائمة الإشتراكات",
  subscribed: "مشترك",
  expired: "غير مشترك",
  returnHome: "الرجوع للصفحة الرئيسية",
  sorryThisPageNotFound: "نأسف هذه الصفحة غير موجودة",
  pageNotFound: "الصفحة غير موجودة",
  newPricing: "إضافة تسعيرة",
  appleProductIdRequired: "رقم معرف ابل مطلوب",
  priceRequired: "يجب إدخال السعر",
  periodRequired: "يجب إدخال الفترة الزمنية",
  arabicDescriptionRequired: "ادخل وصف التسعير بالعربية",
  englishDescriptionRequired: "ادخل وصف التسعير بالإنجليزية",
  intervalRequired: "ادخل الوحدة الزمنية",
  targetUserRequired: "ادخل الفئة المستهدفة",
  summarizedRequired: "ادخل نوع العرض",
  specialRequired: "ادخل نوع التصميم",
  appleProductId: "رقم معرف ابل",
  units: "الوحدات",
  interval: "الوحدة الزمنية",
  specialDesign: "تصميم مميز",
  normalDesign: "تصميم عادي",
  designType: "نوع التصميم",
  addPricingError: "فشل في العملية",
  deletePricingError: "فشل في العملية",
  deletePricingSuccess: "تم الحذف بنجاح",
  deletePricing: "حذف التسعيرة",
  updatePricing: "تحديث التسعيرة",
  updatePricingError: "فشل في العملية",
  month: "شهر",
  year: "سنة",
  offersList: "قائمة العروض",
  newOffer: "إضافة عرض",
  offerType: "نوع العرض",
  discount: "الخصم",
  extraUnit: "وحدات إضافية",
  deleteOffer: "حذف العرض",
  deleteOfferError: "فشل في العملية",
  deleteOfferSuccess: "تم الحذف بنجاح",
  typeRequired: "نوع العرض مطلوب",
  discountRequired: "قيمة التخفيض مطلوية",
  rangeRequired: "قيمة الوحدات الإضافية مطلوبة",
  fromRequired: "تاريخ بداية العرض مطلوب",
  toRequired: "تاريخ نهاية العرض مطلوب",
  pricingRequired: "اختر التسعيرة",
  editOffer: "تحديث العرض",
  addOfferError: "فشل في العملية",
  updateOfferError: "فشل في العملية",
  telegram: "رابط تلقرام",
  whatsApp: "رقم Whatsapp",
  facebook: "فيسبوك",
  twitter: "تويتر",
  instagram: "إنستقرام",
  website: "رابط الموقع",
  instructions: "رابط صفحة التعليمات",
  updateContactError: "فشل في العملية",
  updateContactSuccess: "تم التعديل بنجاح",
  yearRequired: "ادخل شنة النشر",
  updateRightsError: "فشل في العملية",
  updateRightsSuccess: "تم التعديل بنجاح",
  subscriptionsReport: "تقرير الاشتراكات",
  pricingReport: "تقرير التسعيرات",
  familiesReport: "تقريرالعائلات",
  activeUsersReport: "تقرير المستخدمين النشطين",
  institutesReport: "تقرير المؤسسات",
  expiredSubscriptions: "الإشتراكات المنتهية",
  newSubscriptions: "الإشتراكات الجديدة",
  selectYear: "اختر السنة",
  selectMonth: "اختر الشهر",
  selectDayOrMonth: "اختر يوم او شهر لعرض البيانات",
  showMonthSubscriptions: "عرض بيانات الاشتراك في شهر:",
  showDaySubscriptions: "عرض بيانات الاشتراك في يوم:",
  subscriptionDate: "تاريخ الاشتراك",
  accountName: "إسم الحساب",
  notFound: "لا يوجد",
  familiesPricings: "تسعيرات العائلات",
  institutesPricings: "تسعيرات المؤسسات",
  newRegistrations: "المستخدمين الجدد",
  showMonthInstitutes: "عرض بيانات المؤسسات في شهر:",
  showDayInstitutes: "عرض بيانات المؤسسات في يوم:",
  showMonthFamilies: "عرض بيانات العائلات في شهر:",
  showDayFamilies: "هعرض بيانات العائلات في يوم:",
  showMonthActiveUsers: "عرض بيانات المستخدمين النشطين في شهر:",
  showDayActiveUsers: "هعرض بيانات المستخدمين النشطين في يوم:",
};

export default Arabic;
