import { useEffect, useState, useRef } from "react";
import * as offersServices from "../../../services/subscriptions/offers.services";
import { logout } from "../../../slices/authSlice";
import { useDispatch } from "react-redux";
import SubscriptionsTab from "../../../components/Tabs/SubscriptionsTab";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import PageLoader from "../../../components/Loaders/PageLoader";
import Layout from "../../../components/Layout/Layout";
import Container from "../../../components/Layout/Container";
import { Link } from "react-router-dom";
import {
  HomeIcon,
  PencilSquareIcon,
  TrashIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import DeleteModal from "../../../components/modals/DeleteModal";
import ErrorAlert from "../../../components/Alerts/ErrorAlert";
import SuccessAlert from "../../../components/Alerts/SuccessAlert";
import { format } from "date-fns";

const OffersList = () => {
  const [offers, setOffers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentIdValue, setCurrentIdValue] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();

  let dispatch = useDispatch();

  let cancelButtonRef = useRef(null);

  const columns = [
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 capitalize ltr:ml-4 rtl:mr-4">
          {t("offerType")}
        </div>
      ),
      selector: (row) => {
        if (row?.type === "discount") {
          return (
            <div className="bg-primary text-gray-800 px-3 py-1 rounded-md ltr:ml-4 rtl:mr-4">
              {t("discount")}
            </div>
          );
        } else if (row?.type === "range") {
          return (
            <div className="bg-secondary text-gray-800 px-3 py-1 rounded-md ltr:ml-4 rtl:mr-4">
              {t("extraUnit")}
            </div>
          );
        }
      },
      cellExport: (row) => (row.title ? row.title : "not found"),
      sortable: true,
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 capitalize">
          {t("discount")}
        </div>
      ),
      selector: (row) => {
        if (row.type === "discount") {
          return (
            <>
              <span>{row.discount}</span>
              <small>%</small>
            </>
          );
        } else if (row.type === "range") {
          if (row?.pricing?.interval === "month") {
            return (
              <>
                <span className="ltr:ml-1 rtl:mr-1">{row.increaseRangeBy}</span>
                <small>
                  {window.localStorage.getItem("i18nextLng") === "en"
                    ? "month"
                    : "شهر"}
                </small>
              </>
            );
          } else if (row?.pricing?.interval === "year") {
            return (
              <>
                <span className="ltr:ml-1 rtl:mr-1">{row.increaseRangeBy}</span>
                <small>
                  {window.localStorage.getItem("i18nextLng") === "en"
                    ? "year"
                    : "سنة"}
                </small>
              </>
            );
          } else {
            return <span>{row.increaseRangeBy}</span>;
          }
        } else {
          return <span></span>;
        }
      },
      cellExport: (row) => (row.period ? row.period : "not found"),
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ">{t("from")}</div>
      ),
      selector: (row) => (
        <>{row.from ? format(new Date(row.from), "MMM d, y") : "not found"}</>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-900 ">{t("to")}</div>
      ),
      selector: (row) => (
        <>{row.to ? format(new Date(row.to), "MMM d, y") : "not found"}</>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="text-sm font-semibold text-gray-600">
          {t("actions")}
        </div>
      ),
      selector: (row) => (
        <div className="flex space-x-6">
          <Link
            className="inline-flex items-center"
            to={`/champion/offers/update/${row.id}`}
          >
            <PencilSquareIcon className="h-6 w-6 text-primary hover:text-secondary rtl:ml-5" />
          </Link>
          <button
            className="inline-flex items-center"
            onClick={() => showDeleteModal(row?.id)}
            ref={cancelButtonRef}
          >
            <TrashIcon className="h-6 w-6 text-red-700 hover:text-red-600" />
          </button>
        </div>
      ),
      cellExport: (row) => row.null,
    },
  ];

  const getOffers = async (page) => {
    setLoading(true);
    try {
      const response = await offersServices.getAllOffers(page, limit);
      if (response.status === 200) {
        setOffers(response.data.offers.rows);
        setCount(response.data.offers.count);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  const handlePageChange = (page) => {
    getOffers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    try {
      const response = await offersServices.getAllOffers(page, newPerPage);
      setOffers(response.data.offers.rows);
      setLimit(newPerPage);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout());
      }
    }
  };

  useEffect(() => {
    getOffers(1);
    // eslint-disable-next-line
  }, []);

  const showDeleteModal = (id) => {
    setDeleteModalOpen(true);
    setCurrentIdValue(id);
  };

  const handleDelete = () => {
    setIsSubmit(true);
    offersServices
      .deleteOffer(currentIdValue)
      .then((response) => {
        setIsSubmit(false);
        if (response.status === 200) {
          let newPricing = offers.filter((offer) => {
            return offer.id !== currentIdValue;
          });
          setOffers(newPricing);
          setDeleteModalOpen(false);
          setSuccess(true);
        }
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: PricingList.js:246 ~ handleDelete ~ error:",
          error
        );
        if (error.response.status === 401) {
          dispatch(logout());
        } else {
          setError(true);
          setDeleteModalOpen(false);
        }
      });
  };

  const handleCloseMessage = () => {
    if (error) setError(false);
    if (success) setSuccess(false);
  };

  return (
    <Layout>
      {/* Breadcrumb */}
      <nav
        className="flex border-b border-gray-200 bg-white"
        aria-label="Breadcrumb"
      >
        <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 md:px-8 sm:px-6 lg:px-8">
          <li className="flex">
            <div className="flex items-center">
              <Link
                to={"/"}
                className="text-gray-500 hover:text-secondary mx-2"
              >
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">{t("home")}</span>
              </Link>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-gray-500">
                {t("subscriptions")}
              </p>
            </div>
          </li>
          <li className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <p className="ltr:ml-4 rtl:mr-4 text-sm font-semibold rtl:text-xs rtl:font-medium text-secondary hover:text-primary">
                {t("offersList")}
              </p>
            </div>
          </li>
        </ol>
      </nav>
      <SubscriptionsTab />
      <Container>
        {/* Delete modal */}
        <DeleteModal
          deleteModalOpen={deleteModalOpen}
          setDeleteModalOpen={setDeleteModalOpen}
          cancelButtonRef={cancelButtonRef}
          handleDelete={handleDelete}
          setCurrentIdValue={setCurrentIdValue}
          isSubmit={isSubmit}
          name={t("deleteOffer")}
        />

        {error && (
          <ErrorAlert
            message={t("deleteOfferError")}
            close={handleCloseMessage}
          />
        )}
        {success && (
          <SuccessAlert
            message={t("deleteOfferSuccess")}
            close={handleCloseMessage}
          />
        )}
        <div className="flex justify-between items-center">
          <h1 className="flex-1 text-2xl font-bold text-gray-900">
            {t("offersList")}
          </h1>
          <Link
            to={"/champion/offers/new"}
            className="flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary"
          >
            <PlusIcon className="h-5 w-5 text-white" />
            {t("newOffer")}
          </Link>
        </div>
        <div className="mt-6 shadow-lg">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="min-w-full py-2 align-middle md:px-6 lg:px-8">
              {offers ? (
                <DataTable
                  columns={columns}
                  data={offers}
                  progressPending={loading}
                  progressComponent={<PageLoader />}
                  pagination
                  paginationServer
                  paginationTotalRows={count}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  fixedHeader
                  direction={
                    window.localStorage.getItem("i18nextLng") === "en"
                      ? "ltr"
                      : "rtl"
                  }
                />
              ) : (
                <PageLoader />
              )}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default OffersList;
