import axios from "axios";
import * as headers from "./auth-header";

const apiUrl = process.env.REACT_APP_API_URL;

export function getAllCopys() {
  return axios.get(apiUrl + "config/copyright", {
    headers: headers.authHeader(),
  });
}

export function updateCopy(id, copy) {
  return axios.put(apiUrl + "config/copyright/" + id, copy, {
    headers: headers.authHeader(),
  });
}
